import React from "react";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";

// Components
import RadioButton from "../../../../atoms/radioButton";

// Constants
import VIEWS from "../../constants/viewOptions";

// Styles
import styles from "./scheduleView.module.scss";

function ScheduleView({ onViewChange, view }) {
  return (
    <div className={styles.container}>
      <RadioButton options={VIEWS} onChange={onViewChange} value={view}/>
    </div>
  );
}

ScheduleView.propTypes = {
  onViewChange: PropTypes.func,
  view: PropTypes.string,
};

ScheduleView.defaultProps = {
  onViewChange: _noop,
  view: null
};

export default ScheduleView;
