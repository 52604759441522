import React, { useState, useEffect } from "react";
import { SendOutlined, FireFilled, EnvironmentOutlined, CheckCircleOutlined, WarningOutlined } from "../../components/atoms/icon";
import { Button, Carousel } from 'antd';
import {
  isAndroid,
  isIOS,
  isMacOs,
} from "react-device-detect";
import moment from "moment";

// Lodash
import _get from "lodash/get";

// Components
import Logo from "../../assets/images/logo.png";
import Loader from "../../components/molecules/loader";
import Image from "../../components/atoms/image";

// Styles
import DefaultImage from "../../assets/images/user.png";
import styles from "./login.module.scss";

import packagesService from "../../services/packagesService";


function Deals() {

  const [image, setImage] = useState([])
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [androidUrl, setAndroidUrl] = useState("")
  document.title = 'Deals on DUDI';
  document.description = "Best deals for you";

  useEffect(() => {
    // console.log(window.location.search)
    let path = window.location.search
    const params = getQueryStringParams(path)

    if (params.id) {
      packagesService
        .fetchPackageDetails(params.id)
        .then((response) => {
          // console.log(response, 'response')
          if (response.is_active) {
            setData(response)
            setImage(response?.photos[0] || [])
          }

          setLoading(false)
        })
        .catch((e) => {
          // console.log(e.response.data.error, 'eeee')
          setData(null)
          setLoading(false)
        })
    } else {
      setLoading(false)
    }

  }, [])

  const getQueryStringParams = query => {
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
          return params;
        }, {}
        )
      : {}
  };


  const openApp = () => {
    if (isAndroid) {
      let customUrl =
        "intent://dudiapp.com/deeplink" +
        window.location.search +
        "#Intent;scheme=https;package=com.sportseventmanagement;end"
      // intent://dudiapp.com/package?type=package&id=6246c513286d52344c3dc921#Intent;scheme=https;package=com.sportseventmanagement;end
      setAndroidUrl(customUrl)
      onChangeAndroid()
    } else if (isIOS || isMacOs) {
      let customUrl = "dudiapp://dudiapp.com/deeplink" +
        window.location.search
      window.location.replace(customUrl);
    }
  }

  const onChangeAndroid = () => {
    setTimeout(() => {
      document.getElementById('androidhref').click();
      document.getElementsByClassName('androidhref').click();
      window.location.replace(androidUrl)
    }, 500)
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>

        <div className={styles.title}>
          <img className={styles.logo} src={Logo}></img>
        </div>

        {loading
          ? <Loader />
          : !data
            ? <div className={styles.card} style={{ padding: 16 }} >Package not found or has been deleted</div> :
            <div className={`${styles.card}  ${data.hot_deal ? styles.showHot : null}`}>
              <div className={styles.row}>
                <div className={styles.left}>

                  <Image
                    width={"100%"} height={"100%"}
                    className={styles.packageImages}
                    src={image || DefaultImage}
                  />
                  {/* <div className={styles.effect} /> */}

                  {data?.photos.map((item, index) => {
                    index++
                    return (
                      <div className={styles.packageCarousel} onClick={() => setImage(item)}
                        style={{
                          left: 16 * index,
                          backgroundColor: image === item ? '#ff6304' : "#FFF"
                        }}
                      />
                    )
                  })}

                </div>

                <div className={styles.right}>

                  <div className={styles.ImagesOnMobile}>
                    <img
                      className={styles.packageImages}
                      src={image || DefaultImage}
                    />
                    <div className='row'>
                      {data?.photos.map((item, index) => {
                        index++
                        return (
                          <div className={styles.packageCarousel} onClick={() => setImage(item)}
                            style={{
                              left: 16 * index,
                              backgroundColor: image === item ? '#ff6304' : "#000"
                            }}
                          />
                        )
                      })}
                    </div>
                  </div>

                  <div className={styles.titlePackage}>
                    {data.package_name}
                    {data.hot_deal ? <div className={styles.hotDeal}><FireFilled /></div> : null}
                  </div>

                  <div><SendOutlined />  {data.sports?.map((e) => e.name).join(', ')}</div>

                  <div className={styles.packagePrice}>
                    <div className={styles.aed}>
                      AED
                    </div>
                    <div className={styles.price}>
                      {data.price}
                    </div>
                  </div>
                  <div className={styles.exclude}>(Exlcude of VAT)</div>

                  <div className={styles.address}><EnvironmentOutlined />  {data.address}</div>
                  {data.expire_date ? <div className={styles.expire_date}><WarningOutlined /> {moment(data.expire_date).format("DD MMM, YYYY")}</div> : null}

                  <div className={styles.Divider}>
                    <div className="row JCB">
                      <div className="row">
                        <img className={styles.userImage} src={data?.user_id?.profile_pic || DefaultImage} />

                        <div className="column">
                          <div className={styles.fullName}>{data?.user_id?.firstName} {data?.user_id?.lastName}</div>
                          <div className={styles.userName}>{data?.user_id?.username}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className={styles.fullName} style={{ marginTop: 16 }}>Description</div>

                    <p className={styles.description}>{data.description}</p>

                    <a
                      id="androidhref"
                      className="androidhref"
                      href={androidUrl}
                    />

                    <div className={styles.bookBtn}>
                      <Button
                        onClick={() => openApp()}
                        disabled={data.expire_date
                          ? data.expire_date < moment().format()
                          : data.expire_date === null ? false
                            : true}
                        type="primary" icon={<CheckCircleOutlined />} size={'large'}>Book on DUDI app only</Button>
                    </div>
                  </div>

                </div>
              </div>



            </div>
        }

      </div>

    </div>
  );
}
export default Deals;
