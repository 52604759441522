// Lodash
import _property from "lodash/property";

const id = _property("_id");
const address = _property("address");
const businessId = _property("business_id");
const quantity = _property("quantity");
const packageType = _property("package_type");
const description = _property("description");
const packageName = _property("package_name");
const businessName = _property("business_id.user_id.fullname");
const photos = _property("photos");
const price = _property("price");
const old_price = _property("old_price");
const location = _property("location");
const latitude = _property("location.coordinates[0]");
const longitude = _property("location.coordinates[1]");
const createdAt = _property("createdAt");
const updatedAt = _property("updatedAt");
const sports = _property("sports");
const bookingType = _property("booking_type");
const isActive = _property("is_active");
const hotDeal = _property("hot_deal");
const expire_date = _property("expire_date");

export default {
  id,
  address,
  quantity,
  packageType,
  description,
  businessId,
  businessName,
  packageName,
  isActive,
  photos,
  price,
  old_price,
  location,
  createdAt,
  updatedAt,
  sports,
  bookingType,
  latitude,
  longitude,
  hotDeal,
  expire_date,
};
