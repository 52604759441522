import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// lodash
import _map from "lodash/map";
import _find from "lodash/find";

// Components
import PageHeader from "../../components/molecules/pageHeader";
import CalendarView from "../../components/organisms/calendarView/CalendarView";
import { createScheduleOptions } from "../pitches/components/scheduleSelect/helpers/options";
import { Select } from "antd";

// Redux
import { addCalendars } from "./redux/calendarSlice";

// Services
import CalendarService from "../../services/calendarService";

// Reader
import calendarReader from "../../readers/calendar";

// Constants
import { EMPTY_ARRAY } from "../../constants";

// Styles
import styles from "./calendars.module.scss";

function Calendars() {
  const dispatch = useDispatch();

  const [totalCalendars, setTotalCalendars] = useState(0);
  // const [loading, setLoading] = useState(true);
  const [isNewCalendar, setIsNewCalendar] = useState(false);

  const calendars = useSelector((state) => state?.calendars?.items);

  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [selectedCalendarId, setSelectedCalendarId] = useState(null);

  useEffect(() => {
    // setLoading(true);
    CalendarService
      .fetchAllCalendars()
      .then((response) => {
        setTotalCalendars(response.length);
        if (response[0]) {
          dispatch(addCalendars(response));
          setSelectedCalendar(response[0]);
          setSelectedCalendarId(response[0]?._id);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(addCalendars(EMPTY_ARRAY));
      })
      .finally(() => {
        // setLoading(false);
      });
  }, []);


  const handleSelectedCalendar = (sid) => {
    const calendar = _find(
      calendars,
      (calendar) => calendarReader.id(calendar) === sid
    );
    setSelectedCalendarId(sid);
    setSelectedCalendar(calendar);
  };

  const handleClearCalendarSelection = () => {
    setSelectedCalendarId(null);
    setIsNewCalendar(false);
  };

  // const handleActionButtonClick = () => {
  //   setViewOnly(false);
  //   if (actionLabel === "Save") {
  //     // handleSaveAndApplyCalendar();
  //   } else {
  //     setActionLabel("Save");
  //   }
  // };


  const calendarOptions = createScheduleOptions(calendars);
  const renderCalendarOption = ({ label, value }) => {
    return <div
      onClick={() => handleSelectedCalendar(value)}
      className={`${styles.customTab} ${selectedCalendarId == value ? styles.customTabSelected : null}`}
    >{label}</div>
  };

  const handleNewCalendars = () => {
    setSelectedCalendar(null);
    setIsNewCalendar(true);
    setSelectedCalendarId(null);
  };

  return (
    <>
      <div className={styles.pageContainer}>
        <PageHeader
          title="Calendars"
          statistics={[
            {
              title: "Total Calendars",
              value: totalCalendars,
            },
          ]}
          actions={[
            {
              title: "+ New Calendar",
              handler: handleNewCalendars,
            },
          ]}
        />
        <div className="card">
          {/* <h3>Select Calendar</h3> */}

          <div className="row ml-2 mt-2">
            {_map(calendarOptions, renderCalendarOption)}
          </div>

          {selectedCalendar || isNewCalendar ? (
            <CalendarView
              viewOnlyTitle
              {...selectedCalendar}
              selectedCalendarId={selectedCalendarId}
              setCalendarId={(e) => setSelectedCalendarId(e)}
            // actionLabel={actionLabel}
            // onChangeEdit={handleNameChange}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <div>No Calendars Found!</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Calendars;
