import React, { useState } from "react";
import PropTypes from "prop-types";

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";

// Components
import { DeleteOutlined, ProfileOutlined } from "../../../../atoms/icon";
import ScheduleTimeSelect from "../scheduleTimeSelect";
import SchedulePriceList from "../schedulePriceList";

// Constants
import { EMPTY_ARRAY } from "../../../../../constants";

// Styles
import styles from "./scheduleTimeslot.module.scss";

const formatSportPrices = (sportPrices) =>
  _map(sportPrices, ({ id: sport, price, capacity }) => ({
    price,
    id: sport?._id,
    name: sport?.name,
    capacity,
  }));
function ScheduleTimeSlot({
  onDeleteTimeSlot,
  start,
  end,
  onChange,
  className,
  viewOnly,
  sportPrices,
}) {
  const [isPriceDetailsVisible, setPriceDetailsVisibility] = useState(false);

  const handleClosePriceDetails = () => {
    setPriceDetailsVisibility(false);
  };

  const handleShowPriceDetails = () => {
    setPriceDetailsVisibility(true);
  };

  const handleStartTimeChange = (newStartTime) => {
    onChange({
      start: newStartTime,
      end,
      sports: sportPrices,
    });
  };

  const handleEndTimeChange = (newEndTime) => {
    onChange({
      start,
      end: newEndTime,
      sports: sportPrices,
    });
  };

  const handleUpdatePriceDetails = (newSportPrices) => {
    
    onChange({
      start,
      end,
      sport: newSportPrices, // TODO: Need to convert sport to sports
    });
    setPriceDetailsVisibility(false);
  };

  const formattedSportPrices = formatSportPrices(sportPrices);

  return (
    <div className={`${styles.container} ${className}`}>
      <ScheduleTimeSelect
        className={styles.timeSlot}
        value={start}
        onChange={handleStartTimeChange}
        viewOnly={viewOnly}
      />
      <div className={styles.separator}>to</div>
      <ScheduleTimeSelect
        className={styles.timeSlot}
        value={end}
        onChange={handleEndTimeChange}
        viewOnly={viewOnly}
      />
      <ProfileOutlined
        className={styles.deleteIcon}
        onClick={handleShowPriceDetails}
      />
      {!viewOnly && (
        <DeleteOutlined
          className={styles.deleteIcon}
          onClick={onDeleteTimeSlot}
        />
      )}
      {isPriceDetailsVisible && (
        <SchedulePriceList
          visible={isPriceDetailsVisible}
          onCancel={handleClosePriceDetails}
          onOk={handleUpdatePriceDetails}
          viewOnly={viewOnly}
          prices={formattedSportPrices}
        />
      )}
    </div>
  );
}

ScheduleTimeSlot.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  onDeleteTimeSlot: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  viewOnly: PropTypes.bool,
  sportPrices: PropTypes.array,
};

ScheduleTimeSlot.defaultProps = {
  start: null,
  end: null,
  onDeleteTimeSlot: _noop,
  onChange: _noop,
  className: null,
  viewOnly: false,
  sportPrices: EMPTY_ARRAY,
};

export default ScheduleTimeSlot;
