import axios from "axios";

// Constants
import { BASE_URL } from "../constants/services";

// Utils
import getDataFromResponse from "../utils/getDataFromResponse";

const login = ({ userEmail, userPassword }) => {
  const payload = {
    email: userEmail,
    password: userPassword,
  };
  return axios
    .post(`${BASE_URL}/auth/business_login`, payload)
    .then((response) => getDataFromResponse(response));
};

export default {
  login,
};
