// Lodash
import _property from "lodash/property";

const id = _property("_id");
const name = _property("name");
const description = _property("description");
const schedule = _property("schedule");
const scheduleOverrides = _property("scheduleOverrides");
const isActive = _property("is_active");

export default {
  id,
  name,
  description,
  schedule,
  scheduleOverrides,
  isActive,
};
