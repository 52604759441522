// Constants
import { FORM_FIELDS } from "./formfields";
import { EMPTY_ARRAY } from "../../../../../constants";

const DEFAULT_FORM_VALUES = {
  [FORM_FIELDS.ADDRESS]: null,
  [FORM_FIELDS.DESCRIPTION]: null,
  [FORM_FIELDS.HOT_DEAL]: false,
  [FORM_FIELDS.LATITUDE]: 19.07,
  [FORM_FIELDS.LONGITUDE]: 72.87,
  [FORM_FIELDS.NAME]: null,
  [FORM_FIELDS.PACKAGE_TYPE]: "services",
  [FORM_FIELDS.PHOTOS]: null,
  [FORM_FIELDS.PRICE]: 1,
  [FORM_FIELDS.QUANTITY]: 1,
  [FORM_FIELDS.SPORTS]: EMPTY_ARRAY,
  [FORM_FIELDS.STATUS]: 'true',
  [FORM_FIELDS.EXPIRE_DATE]: null,
};

export { DEFAULT_FORM_VALUES };
