import React from "react";
import PropTypes from "prop-types";

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";

// Components
import { Radio } from "antd";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

function RadioButton({ options, onChange, value }) {
  const handleOnChange = (event) => {
    onChange(event.target.value);
  };

  const renderOption = ({ label, value }) => {
    return <Radio.Button value={value}>{label}</Radio.Button>;
  };

  return (
    <Radio.Group buttonStyle="solid" value={value} onChange={handleOnChange}>
      {_map(options, renderOption)}
    </Radio.Group>
  );
}

RadioButton.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

RadioButton.defaultProps = {
  options: EMPTY_ARRAY,
  onChange: _noop,
  value: null,
};

export default RadioButton;
