import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";
import _isEmpty from "lodash/isEmpty";

// Components
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  TextArea,
  Upload,
  UploadOutlined,
  FORM_LAYOUTS,
  BUTTON_TYPES,
} from "../../../../components/atoms";
import { GooglePlacesAutoComplete } from "../../../../components/molecules";
import { getID } from "../../../../utils/globle";
import { Select } from "antd";

// Constants
import { FORM_FIELDS, FORM_WIDTH } from "./constants";
import { EMPTY_OBJECT } from "../../../../constants";

// Styles
import styles from "./packageForm.module.scss";

const renderSportsOption = ({ _id, name } = EMPTY_OBJECT) => {
  return (
    <Select.Option key={`${_id}@${name}`} value={`${_id}@${name}`}>
      {name}
    </Select.Option>
  );
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e && e.fileList;
};

function PackageForm({
  formValues,
  loading,
  onClose,
  onFinish,
  photos,
  sportsList,
  title,
  visible,
  editMode,
}) {
  const [form] = useForm();
  const [removeImage, setremoveImage] = useState([]);
  const [offerPrice, setOfferPrice] = useState(false);
  const [percentage, setpercentage] = useState("0");
  const [price, setPrice] = useState(0);

  useEffect(() => {
    setPrice(formValues?.PRICE)
    if (formValues?.OLD_PRICE) {
      setOfferPrice(true)
      onChangePrice(formValues?.OLD_PRICE, formValues?.PRICE)
    }
    form.setFieldsValue(formValues);
  }, [form, formValues]);

  const handleOnFinish = (values) => {
    values.remove_photo = removeImage
    values.SPORTS = getID(values.SPORTS)
    onFinish(values, form);
  };

  const removeImageF = (photo) => {
    setremoveImage([...removeImage, photo])
  }

  const onChangePrice = (original, val = price) => {
    original = Number(original)
    let offerPrice = ((val / original) * 100).toFixed(1)
    setpercentage(100 - offerPrice)
  }


  const { REACT_APP_URL, NODE_ENV } = process.env
  const devAll = REACT_APP_URL === "dev" || NODE_ENV === "development"

  return (
    <Drawer
      title={title}
      visible={visible}
      onClose={onClose}
      width={FORM_WIDTH}
    >
      <div className={styles.container}>
        <Form
          form={form}
          initialValues={formValues}
          scrollToFirstError
          onFinish={handleOnFinish}
          layout={FORM_LAYOUTS.VERTICAL}
        >
          <Form.Item
            label="Name"
            name={FORM_FIELDS.NAME}
            rules={[{ required: true, message: "Please input name!" }]}
          >
            <Input placeholder="Package name" />
          </Form.Item>
          <Form.Item name={FORM_FIELDS.DESCRIPTION} label="Description">
            <TextArea placeholder="Enter Description" type="textarea" />
          </Form.Item>
          <Form.Item
            name={FORM_FIELDS.SPORTS}
            label="Sports"
            rules={[{ required: true, message: "Please select sport(s)" }]}
          >
            <Select
              mode="multiple"
              placeholder="Select sports played"
              className={styles.select}
            >
              {_map(sportsList, renderSportsOption)}
            </Select>
          </Form.Item>
          <Form.Item label="Address" name={[FORM_FIELDS.LOCATION]}>
            <GooglePlacesAutoComplete
              lat={formValues?.[FORM_FIELDS.LATITUDE]}
              lng={formValues?.[FORM_FIELDS.LONGITUDE]}
              place={formValues?.[FORM_FIELDS.ADDRESS]}
            ></GooglePlacesAutoComplete>
          </Form.Item>
          <Form.Item valuePropName="checked">
            <Checkbox
              style={{ lineHeight: "32px" }}
              onChange={(e) => setOfferPrice(e.target.checked)}
            >
              Offer Price
            </Checkbox>
          </Form.Item>
          <Form.Item
            name={FORM_FIELDS.PRICE}
            label={offerPrice ? "Sale price" : "Price"}
            rules={[{ required: true, message: "Please add price" }]}
          >
            <InputNumber
              placeholder="Package price"
              readOnly={editMode}
              style={{ width: "100%" }}
              min={1}
              onChange={(e) => setPrice(e)}
            />
          </Form.Item>
          {offerPrice && <> <Form.Item
            name={FORM_FIELDS.OLD_PRICE}
            label="Original Price"
            rules={[
              { required: true, message: "Please original price" }
            ]}
          >
            <Input
              min={price}
              // disabled={editMode}
              type="number"
              placeholder="Package original price"
              onChange={(e) => onChangePrice(e.target.value)}
            />
          </Form.Item>
            <div className={styles.PriceMessage}>
              you are providing {percentage}% discount
            </div>
          </>}

          {editMode ? <div className={styles.PriceMessage}>
            To able to change the price please make this "inactive" and create a new package
          </div> : null}

          <Form.Item
            name={FORM_FIELDS.EXPIRE_DATE}
            label="Expire date"
            rules={[{ required: true, message: "Please add expire date" }]}
          >
            <Input placeholder="Package expire date" type="date" />
          </Form.Item>
          <Form.Item
            name={FORM_FIELDS.QUANTITY}
            label="Quantity"
            rules={[{ required: true, message: "Please add quantity" }]}
          >
            <InputNumber
              placeholder="Package Quantity"
              style={{
                width: "100%",
              }}
              min={0}
            />
          </Form.Item>
          <Form.Item name={FORM_FIELDS.PACKAGE_TYPE}>
            <Radio
              // label="Limitation"
              // defaultValue="services"
              // onChange={(e) => setlimitation(e.target.value)}
              items={[
                {
                  name: "Services",
                  value: "services",
                },
                {
                  name: "Goods",
                  value: 'goods',
                },
              ]}
            />
          </Form.Item>
          <Form.Item name={FORM_FIELDS.HOT_DEAL} valuePropName="checked">
            <Checkbox
              style={{
                lineHeight: "32px",
              }}
            >
              Hot Deal
            </Checkbox>
          </Form.Item>
          <div className={styles.images}>
            {_map(photos, (photo) => {
              const findImage = removeImage.find((item) => String(item) === String(photo))
              if (!findImage) return (
                <div className={styles.Imagecontainer}>
                  <img src={photo} className={styles.image} />
                  <div className={styles.Imagemiddle} onClick={() => removeImageF(photo)}>
                    <div className={styles.Imagetext}>X</div>
                  </div>
                </div>
              )
            })}
          </div>
          <Form.Item
            name={FORM_FIELDS.PHOTOS}
            label="Upload Photo"
            // valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              beforeUpload={true}
              listType="picture"
              maxCount="5"
              accept="image/png, .jpeg, .jpg, .webp"
            >
              <Button icon={<UploadOutlined />}>
                {!_isEmpty(photos) ? "Click to replace" : "Click to upload"}
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name={FORM_FIELDS.STATUS}
            label="Status"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select placeholder="Select status">
              <Select.Option value={'true'}>Active</Select.Option>
              <Select.Option value={'false'}>InActive</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.saveButton}
              label={"Save"}
              type={BUTTON_TYPES.PRIMARY}
              htmlType="submit"
              loading={loading}
            />
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
}

PackageForm.propTypes = {
  title: PropTypes.string,
  formValues: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  sportsList: PropTypes.array,
};

PackageForm.defaultProps = {
  formValues: EMPTY_OBJECT,
  visible: false,
  onClose: _noop,
};

export default PackageForm;
