import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// lodash
import _map from "lodash/map";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";

// Components
import PageHeader from "../../components/molecules/pageHeader";
import Schedule from "../../components/organisms/schedule";
import { createScheduleOptions } from "../pitches/components/scheduleSelect/helpers/options";
import Button from "../../components/atoms/button/Button";
import { toast } from "react-toastify";
import { Select } from "antd";

// Services
import scheduleService from "../../services/scheduleService";

// Reader
import scheduleReader from "../../readers/schedule";

// Redux
import { addSchedules, updateSchedule, addSchedule } from "./redux/schedulesSlice";

// Constants
import { EMPTY_ARRAY } from "../../constants";

// Styles
import styles from "./schedules.module.scss";

function Schedules() {
  const navigate = useNavigate();
  const [totalSchedules, setTotalSchedules] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isNewSchedule, setIsNewSchedule] = useState(false);
  const [viewOnly, setViewOnly] = useState(true);
  const [actionLabel, setActionLabel] = useState(null);

  const schedules = useSelector((state) => state?.schedules?.items);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedScheduleId, setSelectedScheduleId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    scheduleService
      .fetchAllSchedules()
      .then((response) => {
        const totalSchedules = response?.length || 0;
        setTotalSchedules(totalSchedules);
        if (response[0]) {
          setSelectedSchedule(response[0]);
          setSelectedScheduleId(response[0]._id);
          setActionLabel("Edit");
          dispatch(addSchedules(response));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(addSchedules(EMPTY_ARRAY));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleScheduleChange = (updatedScheduleDetails) => {
    setSelectedSchedule(updatedScheduleDetails);
  };

  const handleSelectedSchedule = (sid) => {
    setViewOnly(true);
    const schedule = _find(
      schedules,
      (schedule) => scheduleReader.id(schedule) === sid
    );
    setSelectedScheduleId(sid);
    setSelectedSchedule(schedule);
    if (sid) {
      setActionLabel("Edit");
    }
  };

  const handleClearScheduleSelection = () => {
    setSelectedScheduleId(null);
    setIsNewSchedule(false);
  };

  const handleActionButtonClick = () => {
    setViewOnly(false);
    if (actionLabel === "Save") {
      handleSaveAndApplySchedule();
    } else {
      setActionLabel("Save");
    }
  };

  const handleSaveAndApplySchedule = () => {
    setLoading(true);
    const payload = selectedSchedule;
    if (selectedScheduleId) {
      scheduleService
        .updateSchedule(payload, selectedScheduleId)
        .then((response) => {
          toast.success("Schedule Updated Successfully");
          dispatch(updateSchedule(response));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      scheduleService
        .createSchedule(payload)
        .then((response) => {
          toast.success("Schedule Created Successfully");
          dispatch(addSchedule(response));
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const scheduleOptions = createScheduleOptions(schedules);
  const renderScheduleOption = ({ label, value }) => {
    return <Select.Option value={value}>{label}</Select.Option>;
  };

  const handleNewSchedules = () => {
    setSelectedSchedule(null);
    setIsNewSchedule(true);
    setSelectedScheduleId(null);
    setViewOnly(false);
    setActionLabel("Save");
  };

  return (
    <>
      <div className={styles.pageContainer}>
        <PageHeader
          title="Schedules"
          statistics={[
            {
              title: "Total Schedules",
              value: totalSchedules,
            },
          ]}
          actions={[
            {
              title: "+ New Schedule",
              handler: handleNewSchedules,
            },
          ]}
        />
        <div className={styles.pageContent}>
          <div className={styles.selectBox}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h3>Select Schedule</h3>
                <Select
                  placeholder="Select Schedule"
                  onChange={handleSelectedSchedule}
                  onClear={handleClearScheduleSelection}
                  style={{ minWidth: "20rem" }}
                  value={selectedScheduleId}
                >
                  {_map(scheduleOptions, renderScheduleOption)}
                </Select>
              </div>
              <div className={styles.actionButton}>
                <Button
                  onClick={handleActionButtonClick}
                  className={styles.actionButton}
                  loading={loading}
                >
                  {actionLabel}
                </Button>
              </div>
            </div>
          </div>
          {selectedSchedule || isNewSchedule ? (
            <Schedule
              {...selectedSchedule}
              onChange={handleScheduleChange}
              viewOnly={viewOnly}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              <div>No Schedules Found!</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Schedules;
