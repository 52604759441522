import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// Components
import PageHeader from "../../components/molecules/pageHeader";
import Tabs from "../../components/atoms/tabs";
import Image from "../../components/atoms/image";
import Divider from "../../components/atoms/divider";
import { Title, Label } from "../../components/atoms/typography";
import EditProfileForm from "./components/editProfileForm";
import ForgotPasswordForm from "./components/forgotPasswordForm";
import Loader from "../../components/molecules/loader/Loader";
import { toast } from "../../components/atoms/toaster";

import { Row, Col } from "antd";

// Constants
import { EMPTY_OBJECT, EMPTY_ARRAY } from "../../constants";

// Styles
import styles from "./profile.module.scss";

// Redux
import { addUser } from "../../redux";

// Images
import DefaultImage from "../../assets/images/user.png";
import userService from "../../services/userService";
import freelancer from "../../readers/freelancer";

const { TabPane } = Tabs;

function Profile() {
  const dispatch = useDispatch();
  const [selectedProfileDetails, setSelectedProfileDetails] = useState(EMPTY_OBJECT);
  const [isLoading, setisLoading] = useState(true);
  const [formloading, setFormLoading] = useState(false);

  useEffect(() => {
    userService
      .fetchMyProfile()
      .then((response) => {
        dispatch(addUser(response));
        setisLoading(false)
        setSelectedProfileDetails(response);
      })
      .catch((error) => {
        setisLoading(false)
        console.log("Error fetching roles", error);
        setSelectedProfileDetails(EMPTY_ARRAY);
      });
  }, []);


  const onUpdate = (values) => {
    var data = new FormData();
    if (values?.profileImage?.length > 0) {
      data.append("profileImage", values?.profileImage[0].originFileObj)
    } else {
      delete values.profileImage
    }

    data.append("firstName", values.firstName)
    data.append("lastName", values.lastName)
    // data.append("username", values.username)

    setFormLoading(true);
    userService
      .updateProfile(data)
      .then((response) => {
        if (response.success) {
          setSelectedProfileDetails(response.data)
          toast.success("Update Successful!!");
          dispatch(addUser(response.data));
          if (response.data.profile_pic) localStorage.setItem("profile_pic", response.data.profile_pic);
        } else {
          toast.warning("Something went wrong while updating!");
        }
      })
      .catch((error) => {
        setFormLoading(false);
      })
      .finally(() => {
        setFormLoading(false);
      });
  };


  return (
    <>
      <div className={styles.pageContainer}>
        <PageHeader title="Profile" />
        <Row>
          <Col span={16}>
            <div className={styles.pageContent}>
              {isLoading ? <Loader /> :
                <div className={styles.rolesSection}>
                  <Tabs tabPosition={"left"}>
                    <TabPane tab="Edit Profile" key="1">
                      <EditProfileForm profileDetails={selectedProfileDetails} loading={formloading} onUpdate={(res) => onUpdate(res)} />
                    </TabPane>
                    <TabPane tab="Change Password" key="2">
                      <ForgotPasswordForm profileDetails={selectedProfileDetails} />
                    </TabPane>
                  </Tabs>
                </div>
              }
            </div>
          </Col>
          <Col span={1} className={styles.divider}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={7}>
            <div className={styles.profileImageContainer}>
              {isLoading ? <Loader /> : <>
                <div className={styles.profileImage}>
                  <Image
                    width={160}
                    height={160}
                    src={selectedProfileDetails?.profile_pic}
                    fallback={DefaultImage}
                  />
                </div>
                <div className={styles.profileName}>
                  <Title>
                    {freelancer.firstName(selectedProfileDetails)}
                    {freelancer.lastName(selectedProfileDetails)
                      ? freelancer.lastName(selectedProfileDetails)
                      : ""}
                  </Title>
                  <Label>{freelancer.email(selectedProfileDetails)}</Label>
                </div>
              </>
              }
            </div>
          </Col>
        </Row>
        <div className={styles.pageContentWrapper}></div>
      </div>
    </>
  );
}

export default Profile;
