import React from "react";

// Components
import GoogleMaps from "../../../../components/molecules/googleMaps";
import { Title, Label } from "../../../../components/atoms/typography";

// Styles
import styles from "./activityLocation.module.scss";
import moment from "moment";

function PackageLocation({ address }) {
  return (
    <div className={styles.container}>
      <Label className={styles.location}>{moment(address?.custom_event_date).format("DD MMMM, YYYY")}</Label>
      <Label className={styles.location}>{address?.complete_address}</Label>
      <div className={styles.map}>
        <GoogleMaps latitude={Number(address?.latitude)} longitude={Number(address?.longitude)} height={"100%"} />
      </div>
    </div>
  );
}

export default PackageLocation;
