import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import { Layout } from "antd";

// Components
import SideBar from "./components/sideBar";
import Navbar from "../navbar";

// Styles
import styles from "./appSkeleton.module.scss";

const { Header, Sider, Content } = Layout;

function AppSkeleton({ children }) {
  const headerClassName = cx(styles.header, styles.webHeader);

  return (
    <Layout className={styles.container}>
      <Sider className={styles.sideBar}>
        <SideBar />
      </Sider>
      <Layout>
        {/* <Header className={headerClassName}> */}
        <Navbar />
        {/* </Header> */}
        <Content className={styles.content}>{children}</Content>
      </Layout>
    </Layout>
  );
}

AppSkeleton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppSkeleton;
