import moment from "moment";

// Components
import { Button } from 'antd';
import { FolderOpenOutlined } from "../../../../../components/atoms/icon";
import { Payee } from "../../../../../components/molecules";

// Readers
import { transactionReader, packageReader } from "../../../../../readers";

// Constants
import { EMPTY_OBJECT } from "../../../../../constants";

const createColumns = ({ showUser }) => {
  return [
    {
      title: "Transaction ID",
      render: (_, transaction) => transactionReader.transactionId(transaction),
    },
    {
      title: "Package Name",
      render: (_, transaction) => {
        const packageDetails = transactionReader.packageDetails(transaction);
        return packageReader.packageName(packageDetails);
      },
    },
    {
      title: "Purchased By",
      render: (_, transaction) => {
        const userDetails =
          transactionReader.userDetails(transaction) || EMPTY_OBJECT;
        const { email, profile_pic, username } = userDetails;
        return <Payee email={email} name={username} profilePicture={profile_pic} />;
      },
    },
    {
      title: "User",
      render: (_, transaction) => {
        return <Button
          onClick={(event) => {
            event.stopPropagation();
            showUser(transaction?.user_details);
          }}
        >
          <FolderOpenOutlined />
        </Button>
      },
    },
    {
      title: "Purchase Date",
      render: (_, transaction) => {
        const transactionDate = transactionReader.createdAt(transaction);
        const dateMoment = new moment(transactionDate);
        return dateMoment.format("LLL");
      },
    },
    {
      title: "Sale Amount",
      render: (_, transaction) => transactionReader.sellingPrice(transaction),
    },
    {
      title: "Dudi Commission",
      render: (_, transaction) => transactionReader.dudiCommission(transaction),
    },
    {
      title: "Net Amount",
      render: (_, transaction) => transactionReader.netAmount(transaction),
    },
  ];
};

export { createColumns };
