import moment from "moment";

// Lodash
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";

// Constants
import { EMPTY_ARRAY } from "../../../../../../constants";

const isOverrideForDate =
  (dateMoment) =>
  ({ date }) => {
    const dateStartTimeStamp = dateMoment.startOf("day").valueOf();
    return dateStartTimeStamp === date;
  };

const isMatchingWeekDaySchedule =
  (weekDay) =>
  ({ day }) =>
    day === weekDay;

const findTimeSlotsForTheDay = (dateMoment, schedule, scheduleOverrides) => {
  const matchingOverrides = _find(
    scheduleOverrides,
    isOverrideForDate(dateMoment)
  );
  if (!_isEmpty(matchingOverrides)) {
    return matchingOverrides?.timeSlots;
  }

  const weekDay = moment.weekdays(dateMoment.day());
  const matchingWeekDaySchedule = _find(
    schedule,
    isMatchingWeekDaySchedule(weekDay)
  );
  if (
    !_isEmpty(matchingWeekDaySchedule) &&
    !matchingWeekDaySchedule?.disabled
  ) {
    return matchingWeekDaySchedule?.timeSlots;
  }
  return EMPTY_ARRAY;
};

export { findTimeSlotsForTheDay };
