import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";
import _filter from "lodash/filter";
import _size from "lodash/size";

// Components
import Button from "../../../../atoms/button";
import ScheduleDateOverrideModal from "../scheduleDateOverrideModal";
import { DeleteOutlined } from "../../../../atoms/icon";
import { Label } from "../../../../atoms/typography";

// Constants
import { EMPTY_ARRAY } from "../../../../../constants";
import { DEFAULT_OVERRIDE } from "../../constants/overrides";

// Styles
import styles from "./scheduleDateOverrides.module.scss";

function ScheduleDateOverrides({ overrides, onChange, viewOnly, sports }) {
  const [isNewDateOverrideModalVisible, setNewDateOverrideModalVisibility] =
    useState(false);
  const [overrideToBeEdited, setOverrideToBeEdited] =
    useState(DEFAULT_OVERRIDE);

  const handleAddNewOverride = (dateOverride) => {
    setNewDateOverrideModalVisibility(false);
    onChange([dateOverride, ...overrides]);
  };

  const handleOpenDateOverrideModal = () => {
    setNewDateOverrideModalVisibility(true);
  };

  const handleCloseDateOverrideModal = () => {
    setNewDateOverrideModalVisibility(false);
  };

  const handleEditOverride = (index) => () => {
    setOverrideToBeEdited(overrides[index]);
    setNewDateOverrideModalVisibility(true);
  };

  const handleDeleteOverride = (index) => (event) => {
    event.stopPropagation();
    const filteredOverrides = _filter(
      overrides,
      (override, currentIndex) => currentIndex !== index
    );
    onChange(filteredOverrides);
  };

  const renderOverrideTimeSlot =
    (overrideDayStartTimeStamp) =>
      ({ start, end }) => {
        const startTimeMoment = moment(start, "HH:mm");
        const endTimeMoment = moment(end, "HH:mm");

        return (
          <div className={styles.timeSlot}>{`${startTimeMoment.format(
            "LT"
          )} - ${endTimeMoment.format("LT")}`}</div>
        );
      };

  const renderDateOverrideDetails = ({ date, timeSlots }, index) => {
    return (
      <div className={styles.override} onClick={handleEditOverride(index)}>
        <div className={styles.overrideDate}>{moment(date).format("LLLL")}</div>
        <div className={styles.overrideTimeSlots}>
          {_map(timeSlots, renderOverrideTimeSlot(date))}
        </div>
        <div className={styles.overrideActions}>
          <DeleteOutlined
            className={styles.actionIcon}
            onClick={handleDeleteOverride(index)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {!viewOnly && (
          <Button onClick={handleOpenDateOverrideModal}>
            + New Date Override
          </Button>
        )}
      </div>
      {_size(overrides) === 0 && viewOnly && (
        <div className={styles.zeroOverrideLabel}>
          <Label>No Overrides added</Label>
        </div>
      )}
      {(_size(overrides) > 0 || !viewOnly) && (
        <div className={styles.content}>
          {_map(overrides, renderDateOverrideDetails)}
        </div>
      )}
      {isNewDateOverrideModalVisible && (
        <ScheduleDateOverrideModal
          visible={isNewDateOverrideModalVisible}
          onCancel={handleCloseDateOverrideModal}
          onAddOverride={handleAddNewOverride}
          override={overrideToBeEdited}
        />
      )}
    </div>
  );
}

ScheduleDateOverrides.propTypes = {
  overrides: PropTypes.array,
  onChange: PropTypes.func,
  viewOnly: PropTypes.bool,
};

ScheduleDateOverrides.defaultProps = {
  overrides: EMPTY_ARRAY,
  onChange: _noop,
  viewOnly: false,
};

export default ScheduleDateOverrides;
