import React, { useState } from "react";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";

// Components
import Checkbox from "../../../../components/atoms/checkBox";
import Input, { INPUT_TYPES } from "../../../../components/atoms/input";
import Button, { BUTTON_TYPES } from "../../../../components/atoms/button";

// Styles
import styles from "./loginForm.module.scss";

function LoginForm({ onSubmit, loading }) {
  const [userEmail, setUserEmail] = useState();
  const [userPassword, setUserPassword] = useState();

  const handleUpdatePassword = (event) => {
    setUserPassword(event?.target?.value);
  };

  const handleUpdateUserEmail = (event) => {
    setUserEmail(event?.target?.value);
  };

  const handleRememberMeCheckBoxUpdate = () => {};

  const handleSignIn = () => {
    onSubmit({
      userEmail,
      userPassword,
    });
  };

  return (
    <div className={styles.container}>
      <Input
        type={INPUT_TYPES.EMAIL}
        label={"Email address"}
        onChange={handleUpdateUserEmail}
        value={userEmail}
      />
      <Input
        type={INPUT_TYPES.PASSWORD}
        label={"Password"}
        className={styles.input}
        onChange={handleUpdatePassword}
        userPassword={userPassword}
      />
      <div className={styles.formActions}>
        <Checkbox onChange={handleRememberMeCheckBoxUpdate}>
          Remember me
        </Checkbox>
        <Button type={BUTTON_TYPES.LINK} className={styles.forgotPassword}>
          Forgot your password ?
        </Button>
      </div>
      <Button
        type={BUTTON_TYPES.PRIMARY}
        className={styles.button}
        onClick={handleSignIn}
        loading={loading}
      >
        Sign In
      </Button>
    </div>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

LoginForm.defaultProps = {
  onSubmit: _noop,
  loading: false,
};

export default LoginForm;
