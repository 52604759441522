import React from "react";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";

// Components
import ScheduleWeekView from "../scheduleWeekView";
import ScheduleOverrides from "../scheduleOverrides";

// Constants
import { EMPTY_ARRAY } from "../../../../../constants";

// Styles
import styles from "./scheduleListview.module.scss";

function ScheduleListView({ schedule, scheduleOverrides, onChange, viewOnly }) {

  const handleWeeklyScheduleChange = (newWeeklySchedule) => {
    onChange({
      schedule: newWeeklySchedule,
      scheduleOverrides
    });
  };

  const handleScheduleOverridesChange = (newOverrides) => {
    onChange({
      schedule,
      scheduleOverrides: newOverrides,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.weekView}>
        <ScheduleWeekView
          schedule={schedule}
          onChange={handleWeeklyScheduleChange}
          viewOnly={viewOnly}
        />
      </div>
      <div className={styles.overrides}>
        <ScheduleOverrides
          overrides={scheduleOverrides}
          onChange={handleScheduleOverridesChange}
          viewOnly={viewOnly}
        />
      </div>
    </div>
  );
}

ScheduleListView.propTypes = {
  schedule: PropTypes.array,
  scheduleOverrides: PropTypes.array,
  onChange: PropTypes.func,
  viewOnly: PropTypes.bool,
};

ScheduleListView.defaultProps = {
  schedule: EMPTY_ARRAY,
  scheduleOverrides: EMPTY_ARRAY,
  onChange: _noop,
  viewOnly: false,
};

export default ScheduleListView;
