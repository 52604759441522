import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import CreatePitchForm from "../createPitchForm/CreatePitchForm";
import { toast } from "../../../../components/atoms/toaster";
import { getID } from "../../../../utils/globle";

// Reader
import pitchReader from "../../../../readers/pitch";

// Styles
import { EMPTY_OBJECT } from "../../../../constants";
import pitchesService from "../../../../services/pitchesService";

//lodash
import _isEmpty from "lodash/isEmpty";

function PitchFormWrapper({
  editMode,
  visible,
  onClose,
  pitchDetails,
  sportsList,
  venuesOptions,
  reloadPage,
}) {
  const [loading, setLoading] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const pitchID = pitchReader.id(pitchDetails);

  const name = pitchReader.name(pitchDetails);
  const description = pitchReader.description(pitchDetails);
  const sport = pitchReader.sport(pitchDetails);
  const status = pitchReader.isActive(pitchDetails);
  const calendarId = pitchReader.calendarId(pitchDetails);
  const venueId = pitchReader.venueId(pitchDetails);
  const photos = pitchReader.photo(pitchDetails);

  const handleSelectedVenue = (venueID) => {
    setSelectedVenue(venueID);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let title = "Create New Pitch";
  const defaultValues = {
    name: null,
    venue_id: null,
    is_active: null,
    description: null,
    sport: [],
    calendar_id: null,
    photo: null,
  };

  defaultValues.venue_id = venuesOptions[0]?._id;

  if (editMode) {
    title = `Edit Pitch`;
    defaultValues.name = name;
    defaultValues.venue_id = venueId;
    defaultValues.is_active = status;
    defaultValues.description = description;
    defaultValues.sport = sport.map((val) => `${val._id}@${val.name}`);
    defaultValues.calendar_id = calendarId;
    defaultValues.photo = null;
  }

  const onFinish = (values, form) => {
    values.sport = [values.sport]
    setLoading(true);
    values.sport = getID(values.sport, true)
    const finalValues = { ...values };
    finalValues.sport = JSON.stringify(values.sport);

    let query;
    if (editMode) {
      query = {
        mode: "add_image",
      };
    }

    const formData = new FormData();
    for (let key in finalValues) {
      if (key === "photo" && finalValues[key]) {
        for (let p of finalValues[key]) {
          formData.append("photo", p.originFileObj, p.name);
        }
      } else {
        formData.append(key, finalValues[key]);
      }
    }

    if (editMode) {
      pitchesService
        .updatePitch(pitchID, formData, query)
        .then((response) => {
          if (response.success) {
            toast.success("Update Successful!!");
            onClose();
            reloadPage();
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      pitchesService
        .createPitch(formData)
        .then((response) => {
          if (response.success) {
            toast.success("New Pitch Added Successfully!!");
            form.resetFields();
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <CreatePitchForm
      title={title}
      formValues={defaultValues}
      visible={visible}
      onClose={onClose}
      pitchDetails={pitchDetails}
      sportsList={sportsList}
      venuesOptions={venuesOptions}
      onFinish={onFinish}
      loading={loading}
      handleSelectedVenue={handleSelectedVenue}
      photos={photos}
    />
  );
}

PitchFormWrapper.propTypes = {
  editMode: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  pitchDetails: PropTypes.object,
  sportsList: PropTypes.array,
  venuesOptions: PropTypes.array,
};

PitchFormWrapper.defaultProps = {
  pitchDetails: EMPTY_OBJECT,
  editMode: false,
};

export default PitchFormWrapper;
