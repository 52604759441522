const ACTIVE = "ACTIVE";
const INACTIVE = "INACTIVE";
const DELETED = "DELETED";
const PAID = "PAID";
const DUE = "DUE";

export default {
  ACTIVE,
  INACTIVE,
  DELETED,
  PAID,
  DUE
};
