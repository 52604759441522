import React, { useEffect, useState } from "react";
import _map from "lodash/map";

// Styles
import styles from "./viewSlotDetail.module.scss";
import moment from "moment";


function ScheduleWeekView({ data, eventData }) {
  const { description, user_id, photo, sportList, taken, capacity, date_start, time_start, date_end, time_end, } = data
  const { start, end, } = eventData

  const renderSportPrice = ({ sport, price, }) => {
    return (
      <div className={styles.cards}>
        <p className={styles.sportName}>{sport?.name}</p>
        <p className={styles.price}>AED {price}</p>
      </div>
    );
  };

  let percentage = (taken / capacity) * 100

  let timeStart = start ? moment(start).format("hh:mm") : time_start
  let timeEnd = end ? moment(end).format("hh:mm") : time_end

  let dateStart = start ? start : date_start
  let dateEnd = end ? end : date_end

  return (
    <div className={styles.container}>

      <div className={styles.rowJ}>

        <div className={styles.timeBox}>
          <div className={styles.date}>
            {moment(dateStart).format("DD MMM")}
          </div>
          <div className={styles.time}>
            {timeStart}
          </div>
        </div>

        <div>
          to
        </div>

        <div className={styles.timeBox}>
          <div className={styles.date}>
            {moment(dateEnd).format("DD MMM")}
          </div>
          <div className={styles.time}>
            {timeEnd}
          </div>
        </div>
      </div>


      <div className={styles.textLeft}>
        <strong>{taken} / {capacity}</strong> Booked
      </div>
      <div className={styles.progressBar}>
        <div className={styles.progressBarFilled} style={{ width: `${percentage}%` }} />
      </div>

      {/* <div className={styles.divider} /> */}

      <div className={styles.row}>
        {_map(sportList, renderSportPrice)}
      </div>

      {photo && photo != "undefined" &&
        <div className={styles.img}>
          <img
            src={photo}
            height={150}
          // alt="Longtail boat in Thailand"
          />
        </div>}

      <div className={styles.description}>
        {description}
      </div>

    </div>
  );
}


export default ScheduleWeekView;
