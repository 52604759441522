import React from "react";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";
import _find from "lodash/find";

// Components
import { Label } from "../../../../atoms/typography";
import Input from "../../../../atoms/input";

// Helpers
// import { createTimeOptions } from "./helpers/options";

// Styles
import styles from "./scheduleTimeSelect.module.scss";

function ScheduleTimeSelect({ className, onChange, value, viewOnly }) {
  // const options = createTimeOptions();
  // if (viewOnly) {
  //   const timeSlotOption = _find(
  //     options,
  //     ({ value: optionValue }) => value === optionValue
  //   );
  //   return <Label>{timeSlotOption?.label}</Label>;
  // }
  return (
    // <Select
    //   showArrow={false}
    //   className={`${styles.select} ${className}`}
    //   options={options}
    //   onChange={onChange}
    //   value={value}
    //   disabled={viewOnly}
    // />
    <Input
      type="time"
      // label="Start Time"
      className={styles.scheduleName}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      disabled={viewOnly}
    />
  );
}

ScheduleTimeSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number,
  viewOnly: PropTypes.bool,
};

ScheduleTimeSelect.defaultProps = {
  className: null,
  onChange: _noop,
  value: null,
  viewOnly: false,
};

export default ScheduleTimeSelect;
