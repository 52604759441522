import moment from "moment";

// Components
import { FolderOpenOutlined } from "../../../../../components/atoms/icon";
import { Button } from 'antd';
import { Payee } from "../../../../../components/molecules";
import { vat_calu, roundUp } from "../../../../../utils/globle";
import Status, {
  STATUS_TYPES,
} from "../../../../../components/molecules/status";

// Readers
import { transactionReader, packageReader } from "../../../../../readers";

// Constants
import { EMPTY_OBJECT } from "../../../../../constants";

const createColumns = ({ showUser }) => {
  return [
    {
      title: "Transaction ID",
      render: (_, transaction) => transactionReader.transactionId(transaction),
    },
    {
      title: "Type",
      render: (_, transaction) => {
        return packageReader.bookingType(transaction);
      },
    },
    {
      title: "Name",
      render: (_, transaction) => {
        const packageDetails = transactionReader.packageDetails(transaction);
        const EventDetails = transactionReader.EventDetails(transaction);
        const SlotDetails = transactionReader.SlotDetails(transaction);
        if (packageReader.packageName(packageDetails)) {
          return packageReader.packageName(packageDetails);
        } else if (EventDetails) {
          return EventDetails?.name ? EventDetails?.name : "none";
        } else {
          return SlotDetails?.title ? SlotDetails?.title : "none";
        }
      },
    },
    {
      title: "Purchased By",
      render: (_, transaction) => {
        const userDetails = transactionReader.userDetails(transaction) || EMPTY_OBJECT;
        const { email, profile_pic, username } = userDetails;
        return <Payee email={email} name={username} profilePicture={profile_pic} />;
      },
    },
    {
      title: "User",
      render: (_, transaction) => {
        return <Button
          onClick={(event) => {
            event.stopPropagation();
            showUser(transaction?.user_details);
          }}
        >
          <FolderOpenOutlined />
        </Button>
      },
    },
    {
      title: "Purchase Date",
      render: (_, transaction) => {
        const transactionDate = transactionReader.createdAt(transaction);
        const dateMoment = new moment(transactionDate);
        return dateMoment.format("LLL");
      },
    },
    {
      title: "Gross Sale",
      render: (_, transaction) => transactionReader.costPrice(transaction),
    },
    {
      title: "Dudi Commission",
      render: (_, transaction) => transactionReader.dudiCommission(transaction),
    },
    {
      title: "Net Amount",
      render: (_, transaction) => transactionReader.netAmount(transaction),
    },
    {
      title: "VAT",
      render: (_, transaction) => {
        let vatPrice = vat_calu(transaction?.cost_price)
        vatPrice = roundUp(vatPrice)
        return vatPrice;
      },
    },
    {
      title: "Total",
      render: (_, transaction) => transactionReader.sellingPrice(transaction),
    },
    {
      title: "Status",
      render: (_, transaction) => {
        const settled = transactionReader.settled(transaction);
        const status = settled ? STATUS_TYPES.PAID : STATUS_TYPES.DUE;
        return <Status status={status} />;
      },
    },
  ];
};

export { createColumns };
