import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Components
import PageHeader from "../../components/molecules/pageHeader";
import Tag, { TAG_TYPES } from "../../components/atoms/tag";
import Button, { BUTTON_TYPES } from "../../components/atoms/button";
import PitchDetails from "./components/pitchDetails";
import PitchDescription from "./components/pitchDescription";
import { toast } from "../../components/atoms/toaster";
import Loader from "../../components/molecules/loader";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";

// Redux
import { addPitch, setPitchLoading } from "./redux/pitchSlice";
import { addVenues } from "../../redux/slices/venuesSlice";

// Services
import pitchsService from "../../services/pitchesService";

// Reader
import pitchReader from "../../readers/pitch";

// Styles
import styles from "./pitch.module.scss";

// Assets
import defaultUser from "../../assets/images/sport.png";

function Pitch() {
  const dispatch = useDispatch();
  const { pitchId } = useParams();
  const pitchDetails = useSelector((state) => state?.pitch?.pitch?.details);
  const venueDetails = pitchReader.venue(pitchDetails);
  const loading = useSelector((state) => state?.pitch?.pitch?.loading);

  const name = pitchReader.name(pitchDetails);
  const description = pitchReader.description(pitchDetails);
  const sportsPlayed = pitchReader.sport(pitchDetails);
  const status = pitchReader.isActive(pitchDetails);

  const latitude = venueDetails?.latitude ? +venueDetails?.latitude : 0;
  const longitude = venueDetails?.longitude ? +venueDetails?.longitude : 0;

  const images = pitchReader.images(pitchDetails);
  const calendar = pitchReader.calendar(pitchDetails);

  useEffect(() => {
    dispatch(setPitchLoading(true));
    pitchsService
      .fetchPitchDetails(pitchId)
      .then((response) => {
        dispatch(addPitch(response));
      })
      .catch(() => {
        dispatch(addPitch(EMPTY_OBJECT));
        // dispatch(addVenues(EMPTY_OBJECT));
        toast.error("Error fetching pitch details");
      })
      .finally(() => {
        dispatch(setPitchLoading(false));
      });

    return () => {
      dispatch(addPitch(EMPTY_OBJECT));
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <PageHeader
        title={name}
        className="site-page-header"
        tags={[
          <Tag type={status ? TAG_TYPES.SUCCESS : TAG_TYPES.DEFAULT}>
            {status ? "Active" : "In-active"}
          </Tag>,
        ]}
        extra={[
          <Button key="1" type={BUTTON_TYPES.PRIMARY}>
            Deactivate
          </Button>,
        ]}
        avatar={{
          src: defaultUser,
        }}
      >
        <PitchDescription description={description} images={images} />
      </PageHeader>
      <div className={styles.pageContent}>
        <PitchDetails
          name={name}
          lat={latitude}
          lng={longitude}
          address={venueDetails?.address}
          sports={sportsPlayed}
          pitchId={pitchId}
          loading={loading}
          images={images}
          calendar={calendar}
        />
      </div>
    </div>
  );
}

export default Pitch;
