import React, { useState } from "react";
import PropTypes from "prop-types";

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";
import _filter from "lodash/filter";

// Components
import Modal from "../../../../atoms/modal";
import ScheduleSportPrice from "../scheduleSportPrice";
import { PlusOutlined, ClearOutlined } from "../../../../atoms/icon";
// Components
import { Row, Col } from "antd";

// Constants
import { DEFAULT_PRICE_LIST, EMPTY_PRICE_FIELD } from "./constants";
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
} from "../../../../../constants/base.constants";

// Styles
import styles from "./schedulePriceList.module.scss";
import { Label } from "../../../../atoms/typography";
import Column from "antd/lib/table/Column";

function SchedulePriceList({
  onCancel,
  onOk,
  visible,
  prices: pricesFromProps,
  viewOnly,
}) {
  const [prices, setPrices] = useState(pricesFromProps);

  const handleAddNewSport = () => {
    setPrices([...prices, EMPTY_PRICE_FIELD]);
  };

  const handleClearAll = () => {
    setPrices(DEFAULT_PRICE_LIST);
  };

  const handleDeleteItem = (index) => () => {
    const filteredPrices = _filter(
      prices,
      (currentPrice, currentIndex) => index !== currentIndex
    );
    setPrices(filteredPrices);
  };

  const handleSavePrices = () => {
    onOk(prices);
  };

  const handleUpdatePrice = (index) => (newPrice) => {
    prices[index] = newPrice;
    setPrices([...prices]);
  };

  const renderSportPrice = ({ id, name, price, capacity } = EMPTY_OBJECT, index) => {
    return (
      <ScheduleSportPrice
        className={styles.sportPrice}
        onDelete={handleDeleteItem(index)}
        onChange={handleUpdatePrice(index)}
        sportId={id} // TODO fix the data format of this from backend.
        price={price}
        capacity={capacity}
        deleteDisabled={prices.length === 1}
        viewOnly={viewOnly}
        sportName={name}
      />
    );
  };

  const renderEditMode = () => {
    return (
      <div className={styles.container}>
          <div className={styles.priceHeader} >
            <div className={styles.sportLabel}>Sports</div>
            <div className={styles.priceLabel}>Price</div>
            <div className={styles.capacityLabel}>Capacity</div>
            <div className={styles.listActions}>
              <PlusOutlined
                className={styles.actionIcon}
                onClick={handleAddNewSport}
              />
              <ClearOutlined
                className={styles.actionIcon}
                onClick={handleClearAll}
              />
          </div>
          </div>
        <div className={styles.priceList}>{_map(prices, renderSportPrice)}</div>
        
      </div>
    );
  };

  const renderViewMode = () => {
    return (
      <div className={styles.viewContainer}>
        {_map(prices, renderSportPrice)}
      </div>
    );
  };

  return (
    <Modal
      title="Price detalis"
      centered
      visible={visible}
      width={600}
      onOk={handleSavePrices}
      onCancel={onCancel}
      className={styles.modal}
      destroyOnClose
      okText={"Save"}
      {...(viewOnly ? { footer: null } : EMPTY_OBJECT)}
    >
      {viewOnly ? renderViewMode() : renderEditMode()}
    </Modal>
  );
}

SchedulePriceList.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  visible: PropTypes.bool,
  prices: PropTypes.array,
  viewOnly: PropTypes.bool,
};

SchedulePriceList.defaultProps = {
  onOk: _noop,
  onCancel: _noop,
  visible: false,
  prices: EMPTY_ARRAY,
  viewOnly: false,
};

export default SchedulePriceList;
