// Lodash
import _property from "lodash/property";

const createdAt = _property("createdAt");
const email = _property("email");
const firstName = _property("first_name");
const isActive = _property("is_active");
const lastName = _property("last_name");
const profileImageUrl = _property("profile_pic");
const updatedAt = _property("updatedAt");
const role = _property("role.role");
const permissions = _property("permissions");

export default {
  createdAt,
  email,
  firstName,
  isActive,
  lastName,
  profileImageUrl,
  updatedAt,
  role,
  permissions,
};
