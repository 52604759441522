import React from "react";

// Components
import GoogleMaps from "../../../../components/molecules/googleMaps";
import { Title, Label } from "../../../../components/atoms/typography";

// Styles
import styles from "./packageLocation.module.scss";

function PackageLocation({ lat, lng, address }) {
  return (
    <div className={styles.container}>
      <Label className={styles.location}>{address}</Label>
      <div className={styles.map}>
        <GoogleMaps latitude={lat} longitude={lng} height={"100%"} />
      </div>
    </div>
  );
}

export default PackageLocation;
