import React from "react";
import { Tag as AntdTag } from "antd";
import PropTypes from "prop-types";

// Constants
import TAG_TYPES from "./constants/tag.types";

function Tag({ label, type, className, children,  ...restProps }) {
  return (
    <AntdTag color={type} {...restProps} className={className}>
      {label || children}
    </AntdTag>
  );
}

Tag.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};

Tag.defaultProps = {
  type: TAG_TYPES.DEFAULT,
  label: null,
  className: null,
};

export default Tag;
