import axios from "axios";
import queryString from "query-string";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "../constants/pagination";

// Utils
import { toast } from "react-toastify";
import getDataFromResponse from "../utils/getDataFromResponse";

const fetchPitches = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  searchText = "",
  sortField,
  order,
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    search_text: searchText,
    sort_field: sortField,
    order_by: order === "decend" ? "desc" : order === "ascend" ? "asc" : "",
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/pitch`,
    query: payload,
  });
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      console.log(`Error: Failed to fetch Pitches, ${error}`);
    });
};

const createPitch = (payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/pitch`,
  });
  return axios
    .post(url, payload, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const updatePitch = (id, payload = EMPTY_OBJECT, query) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/pitch/${id}`,
    query: query,
  });
  return axios
    .put(url, payload, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const fetchPitchDetails = (id) => {
  const url = `${BASE_URL}/pitch/${id}`;
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response));
};

const fetchPitchBooking = (id, from, to) => {
  const url = `${BASE_URL}/pitch/booking/${id}?from=${from}&to=${to}`;
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response));
};

export default {
  fetchPitches,
  createPitch,
  updatePitch,
  fetchPitchDetails,
  fetchPitchBooking,
};
