import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components
import { Table } from "../../../../components/organisms";
import { Label } from "../../../../components/atoms/typography";
import PopupUserDetail from "../../../../components/custom/PopupUserDetail";

// Services
import { transactionsService } from "../../../../services";

// Helpers
import { createColumns } from "./helpers";

// Redux Actions
import {
  setTransactions,
  setLoading,
} from "../../../../redux/slices/transactionsSlice";

// Constants
import { EMPTY_OBJECT } from "../../../../constants";
import { DEFAULT_PAGINATION_DETAILS } from "./constants";

// Styles
import styles from "./transactions.module.scss";

function Transactions() {
  const dispatch = useDispatch();
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [paginationDetails, setPaginationDetails] = useState(DEFAULT_PAGINATION_DETAILS);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user_id, setUser_id] = useState(null);

  const transactions = useSelector((state) => state?.transactions?.items);
  const loading = useSelector((state) => state?.transactions?.loading);

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    dispatch(setLoading(true));
    transactionsService
      .fetchTransactions({ pageNumber, pageSize })
      .then((transactionDetails = EMPTY_OBJECT) => {
        const transactions = transactionDetails.docs;
        setTotalTransactions(transactionDetails?.totalDocs);
        dispatch(setTransactions(transactions));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, [paginationDetails]);

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const columns = createColumns({
    showUser: (data) => handleShowUser(data),
  });

  const handleShowUser = (data) => {
    setIsModalOpen(true)
    setUser_id(data._id)
  };

  return (
    <div className={styles.container}>
      <Label className={styles.heading}>Booking History</Label>
      <div className={styles.table}>
        <Table
          columns={columns}
          data={transactions}
          loading={loading}
          showSearch={false}
          showReload={false}
          paginationOptions={{
            current: paginationDetails.pageNumber,
            total: totalTransactions,
          }}
          onPaginationChange={handlePaginationChange}
        />
      </div>
      <PopupUserDetail
        show={isModalOpen}
        close={() => setIsModalOpen(false)}
        userId={user_id}
      />
    </div>
  );
}

export default Transactions;
