import React from "react";

// Lodash
import _find from "lodash/find";

// Components
import { Row, Col } from "antd";
import Tag, { TAG_TYPES } from "../../../../components/atoms/tag";
import Tabs from "../../../../components/atoms/tabs";
import { Title, Label } from "../../../../components/atoms/typography";
import Album from "../../../../components/molecules/album";
import Calendar from "../../../../components/organisms/calendarView/CalendarView";
import PitchLocation from "../pitchLocation";

// Styles
import styles from "./pitchDetails.module.scss";

// Lodash
import _map from "lodash/map";
import BookingHistory from "../bookingHistory/BookingHistory";

const { TabPane } = Tabs;

const renderSport = (sport) => {
  return <Tag type={TAG_TYPES.SUCCESS}>{sport?.name}</Tag>;
};
function PitchDetails({
  address,
  sports,
  images,
  lat,
  lng,
  calendar,
  pitchId,
  name,
}) {

  return (
    <div className={styles.container}>
      <Tabs type="card">
        <TabPane tab="About" key="about">
          <Row className={styles.aboutContainer}>
            <Col span={12}>
              <div className={styles.pitchDetails}>
                <div className={styles.pitchDetail}>
                  <Title className={styles.detailTitle}>Sport</Title>
                  <Label className={styles.detailValue}>
                    {_map(sports, renderSport)}
                  </Label>
                </div>
                <div className={styles.pitchDetail}>
                  <Title className={styles.detailTitle}>Location</Title>
                  <Label className={styles.detailValue}>{address}</Label>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <PitchLocation lat={lat} lng={lng} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Calendar" key="calendar">
          <Calendar
            {...calendar}
            viewOnlyTitle
            selectedCalendarId={calendar?._id}
            pitchId={pitchId}
            pitchName={name}
          // actionLabel="bhago"
          />
        </TabPane>
        <TabPane tab="Images" key="images">
          <Album images={images} />
        </TabPane>
        <TabPane tab="Booking History" key="bookingHistory">
          <BookingHistory calendarId={calendar?._id} />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default PitchDetails;
