import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./packageDescription.module.scss";

function PackageDescription({ description }) {
  return (
    <div className={styles.container}>
      <div className={styles.description}>{description}</div>
    </div>
  );
}

PackageDescription.propTypes = {
  description: PropTypes.string,
};

PackageDescription.defaultProps = {
  description: null,
};

export default PackageDescription;
