import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_OBJECT, EMPTY_ARRAY } from "../../../constants";

const INITIAL_STATE = {
  pitch: {
    details: EMPTY_OBJECT,
    loading: false,
  },
  purchaseHistory: {
    items: EMPTY_ARRAY,
    loading: false,
  },
  bookingsSummary: EMPTY_OBJECT
};

const pitchSlice = createSlice({
  name: "currentPitch",
  initialState: INITIAL_STATE,
  reducers: {
    addPitch: (state, action = {}) => {
      const { payload: pitchDetails = EMPTY_OBJECT } = action;
      state.pitch.details = pitchDetails;
      state.pitch.loading = false;
    },
    setPitchLoading: (state, action = {}) => {
      const { payload: loading } = action;
      state.pitch.loading = loading;
    },
    setPurchaseHistory: (state, action = {}) => {
      const { payload: purchaseHistory = EMPTY_ARRAY } = action;
      state.purchaseHistory.items = purchaseHistory;
      state.purchaseHistory.loading = false;
    },
    setCalendarPurchaseHistoryLoading: (state, action = {}) => {
      const { payload: loading } = action;
      state.purchaseHistory.loading = loading;
    },
    setCalendarBookingsSummary: (state, action = {}) => {
      const { payload: bookingsSummary } = action;
      state.bookingsSummary = bookingsSummary;
    },
  },
});

const {
  addPitch,
  setPitchLoading,
  setPurchaseHistory,
  setCalendarPurchaseHistoryLoading,
  setCalendarBookingsSummary,
} = pitchSlice.actions;
const pitchReducer = pitchSlice.reducer;

export {
  addPitch,
  setPitchLoading,
  setPurchaseHistory,
  setCalendarPurchaseHistoryLoading,
  setCalendarBookingsSummary,
  pitchReducer,
};
export default pitchSlice;
