import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components
import { Table } from "../../components/organisms";
import PackageForm from "./components/packageForm";
import { PageHeader } from "../../components/molecules";

// Services
import { packagesService } from "../../services";

// Redux
import { addPackages } from "../../redux";

// Readers
import { listResponseReader, packageReader } from "../../readers";

// Helpers
import {
  createColumns,
  createPageHeaderProps,
  createTablePaginationOptions,
  createPackageCreationPayload,
} from "./helpers";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";
import { DEFAULT_PAGINATION_DETAILS, PAGE_TITLE } from "./constants";

// Styles
import styles from "./packages.module.scss";

function Packages() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const packages = useSelector((state) => state?.packages?.items);
  const sportsList = useSelector((state) => state?.sports?.items);

  const [searchText, setSearchText] = useState();
  const [loading, setLoading] = useState(true);
  const [isPackageCreationInProgress, setPackageCreationStatus] =
    useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isCreatePackageFormVisible, setCreatePackageFormVisibility] =
    useState(false);
  const [totalPackages, setTotalPackages] = useState(0);
  const [selectedPackageDetails, setSelectedPackageDetails] =
    useState(EMPTY_OBJECT);
  const [paginationDetails, setPaginationDetails] = useState(
    DEFAULT_PAGINATION_DETAILS
  );

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    setLoading(true);
    packagesService
      .fetchPackages({
        pageNumber,
        pageSize,
        searchText,
      })
      .then((response) => {
        const packages = listResponseReader.results(response);
        const totalPackages = listResponseReader.totalResults(response);
        setTotalPackages(totalPackages);
        dispatch(addPackages(packages));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paginationDetails]);

  const handleOpenCreatePackageForm = () => {
    setEditMode(false);
    setSelectedPackageDetails(EMPTY_OBJECT);
    setCreatePackageFormVisibility(true);
  };

  const handleOpenEditPackageForm = (packageDetail) => {
    setEditMode(true);
    setSelectedPackageDetails(packageDetail);
    setCreatePackageFormVisibility(true);
  };

  const handleCloseCreatePackageForm = () => {
    setCreatePackageFormVisibility(false);
  };

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({ ...DEFAULT_PAGINATION_DETAILS });
  };

  const columns = createColumns({
    onEdit: handleOpenEditPackageForm,
  });

  const handleRowClick = (packageDetail) => {
    const packageId = packageReader.id(packageDetail);
    navigate(`${packageId}`);
  };

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
  };

  const pageHeaderProps = createPageHeaderProps({
    title: PAGE_TITLE,
    count: totalPackages,
    actionHandler: handleOpenCreatePackageForm,
  });

  const paginationOptions = createTablePaginationOptions(
    paginationDetails.pageNumber,
    totalPackages
  );

  const searchProps = {
    placeholder: "Search Packages",
  };

  return (
    <>
      <div className={styles.pageContainer}>
        <PageHeader {...pageHeaderProps} />
        <div className={styles.pageContent}>
          <Table
            columns={columns}
            data={packages}
            loading={loading}
            paginationOptions={paginationOptions}
            searchProps={searchProps}
            onPaginationChange={handlePaginationChange}
            onReload={handleReload}
            onRowClick={handleRowClick}
            onSearch={handleSearch}
          />
        </div>
      </div>
      <PackageForm
        editMode={editMode}
        onClose={handleCloseCreatePackageForm}
        packageDetails={selectedPackageDetails}
        sportsList={sportsList}
        visible={isCreatePackageFormVisible}
      />
    </>
  );
}

export default Packages;
