// Components
import Status, { STATUS_TYPES } from "../../../components/molecules/status";
import Button, { BUTTON_TYPES } from "../../../components/atoms/button";
import { EditOutlined, DeleteOutlined } from "../../../components/atoms/icon";

// Readers
import pitchReader from "../../../readers/pitch";

// Styles
import styles from "../pitches.module.scss";

const createColumns = ({ onEdit, onDelete }) => {
  return [
    {
      title: "Name",
      width: "200px",
      render: (text, pitchDetails) => {
        return pitchReader.name(pitchDetails);
      },
    },
    {
      title: "Venue Name",
      render: (text, pitchDetails) => {
        return pitchReader.venueName(pitchDetails);
      },
    },
    {
      title: "Status",
      render: (text, pitchDetails) => {
        const status = pitchReader.isActive(pitchDetails)
          ? STATUS_TYPES.ACTIVE
          : STATUS_TYPES.INACTIVE;
        return <Status status={status} />;
      },
    },
    {
      title: "Actions",
      render: (text, pitchDetails) => {
        return (
          <>
            <Button
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                event.stopPropagation();
                onEdit(pitchDetails);
              }}
              className={styles.icon}
            >
              <EditOutlined />
            </Button>
          </>
        );
      },
    },
  ];
};

export { createColumns };
