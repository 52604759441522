import { createSlice } from "@reduxjs/toolkit";

// Lodash
import _findIndex from "lodash/findIndex";

// Readers
import { packageReader } from "../../readers";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
};

const packagesSlice = createSlice({
  name: "packages",
  initialState: INITIAL_STATE,
  reducers: {
    addPackages: (state, action = {}) => {
      const { payload: packages = EMPTY_ARRAY } = action;
      state.items = packages;
    },
    addPackage: (state, action = {}) => {
      const { payload: newPackage = EMPTY_OBJECT } = action;
      state.items = [newPackage, ...state.items];
    },
    updatePackage: (state, action = {}) => {
      const { payload: updatedPackage = EMPTY_OBJECT } = action;
      const packageIndex = _findIndex(
        state.items,
        (item) => packageReader.id(item) === packageReader.id(updatedPackage)
      );
      state.items[packageIndex] = updatedPackage;
    },
  },
});

const { addPackages, addPackage, updatePackage } = packagesSlice.actions;
const packagesReducer = packagesSlice.reducer;

export { addPackages, addPackage, updatePackage, packagesReducer };
export default packagesSlice;
