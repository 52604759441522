import React, { useEffect } from "react";
import _map from "lodash/map";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";

// Components
import { useForm } from "antd/lib/form/Form";
import Form from "../../../../components/atoms/form";
import Drawer from "../../../../components/atoms/drawer";
import Button from "../../../../components/atoms/button";
import { Input, TextArea } from "../../../../components/atoms/input";
import AsyncSelect from "../../../../components/molecules/asyncSelect/AsyncSelect";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Select } from "antd";

// Services
import venuesService from "../../../../services/venuesService";

// Styles
import styles from "./createPitchForm.module.scss";
import { EMPTY_OBJECT } from "../../../../constants";

const renderSportsOptions = (option) => {
  return (
    <Select.Option key={`${option._id}@${option.name}`} value={`${option._id}@${option.name}`}>
      {option.name}
    </Select.Option>
  );
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e && e.fileList;
};
function CreatePitchForm({
  formValues,
  visible,
  onClose,
  title,
  sportsList,
  onFinish,
  loading,
  handleSelectedVenue,
  venuesOptions,
  photos,
}) {
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue(formValues);
  }, [form, formValues]);

  const handleVenueOptions = (d) => {
    return (
      <Select.Option key={d._id} value={d._id}>
        {d.name}
      </Select.Option>
    );
  };

  return (
    <Drawer title={title} visible={visible} onClose={onClose} width={400}>
      <div className={styles.container}>
        <Form
          form={form}
          initialValues={formValues}
          scrollToFirstError
          onFinish={(values) => onFinish(values, form)}
          layout="vertical"
        >
          <Form.Item
            name="venue_id"
            label="Venue"
            rules={[
              {
                required: true,
                message: "Please select user!",
              },
            ]}
          >
            <AsyncSelect
              asyncService={venuesService.fetchVenues}
              type="venue"
              className={styles.select}
              defaultOptions={venuesOptions}
              onSelectVenue={handleSelectedVenue}
              createOptions={handleVenueOptions}
            ></AsyncSelect>
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input name!" }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
          <Form.Item label="Description" name={"description"}>
            <TextArea placeholder="Enter description" />
          </Form.Item>
          <Form.Item
            name="photo"
            label="Upload Photos"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              beforeUpload={true}
              listType="picture"
              maxCount="5"
              accept="image/png, .jpeg, .jpg, .webp"
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="sport"
            label="Sports Played"
            rules={[
              { required: true, message: "Please select sports played!" },
            ]}
          >
            <Select
              // mode="multiple"///////if you open this remove array from onFinish line 69 # values.sport = [values.sport]
              placeholder="Select sports played"
              className={styles.select}
            >
              {_map(sportsList, renderSportsOptions)}
            </Select>
          </Form.Item>
          <Form.Item
            name="is_active"
            label="Status"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select placeholder="Select status" className={styles.select}>
              <Select.Option value={true}>Active</Select.Option>
              <Select.Option value={false}>InActive</Select.Option>
            </Select>
          </Form.Item>
          {/* <Form.Item
            name="calendar_id"
            label="Calendar"
            rules={[{ message: "Please select a calendar!" }]}
          >
            <CalendarSelect />
          </Form.Item> */}
          <Form.Item>
            <Button
              label={"Save"}
              type="primary"
              htmlType="submit"
              loading={loading}
              className={styles.saveButton}
            />
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
}

CreatePitchForm.propTypes = {
  formValues: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  sportsList: PropTypes.array,
};

CreatePitchForm.defaultProps = {
  formValues: EMPTY_OBJECT,
  visible: false,
  onClose: _noop,
};

export default CreatePitchForm;
