import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Utils
import { getDataFromResponse } from "../utils";

// Constants
import { BASE_URL } from "../constants/services";

const fetchTransactions = ({ pageNumber, pageSize }) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/transactions`,
    query: payload,
  });
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch(() => {
      toast.error("Failed to fetch transactions");
    });
};

export default {
  fetchTransactions,
};
