import moment from "moment";

// Components
import Status, { STATUS_TYPES } from "../../../components/molecules/status";
import Button, { BUTTON_TYPES } from "../../../components/atoms/button";
import { EditOutlined, DeleteOutlined } from "../../../components/atoms/icon";
import Tooltip from "../../../components/atoms/tooltip";
import { Tag } from 'antd';

// Readers
import { activityReader } from "../../../readers";

// Styles
import styles from "../activities.module.scss";

const createColumns = ({ onEdit, onDelete }) => {
  return [
    {
      title: "Name",
      width: "160px",
      render: (_, activityDetails) => {
        return activityReader.activityName(activityDetails);
      },
    },
    {
      title: "Address",
      render: (_, activityDetails) => {
        return activityReader.address(activityDetails);
      },
    },
    {
      title: "Price",
      render: (_, activityDetails) => {
        return activityDetails.fees > 0
          ? activityDetails.fees
          : <Tag className="capitalize" color="green">Free</Tag>
      },
    },
    {
      title: "Players",
      render: (_, activityDetails) => {
        return activityReader.no_of_players(activityDetails);
      },
    },
    {
      title: "Team",
      render: (_, activityDetails) => {
        return activityReader.no_of_team(activityDetails);
      },
    },
    {
      title: "Status",
      render: (_, activityDetails) => {
        return activityReader.status(activityDetails);
      },
    },
    {
      title: "Event Date",
      dataIndex: 'firstName',
      sorter: true,
      render: (_, activityDetails) => {
        let eventDate = activityReader.eventDate(activityDetails);
        eventDate = moment(eventDate).format("MMM Do YY");
        return eventDate
      },
    },
    {
      title: "Event Status",
      width: "100px",
      render: (_, activityDetails) => {
        let status = STATUS_TYPES.INACTIVE
        let customText = "Past"
        let eventDate = activityReader.eventDate(activityDetails);
        let check = moment().isSameOrBefore(eventDate) || moment(eventDate).format("DD MM YYYY") == moment().format("DD MM YYYY")
        if (check) {
          status = STATUS_TYPES.ACTIVE
          customText = "Upcoming"
        }

        return <Status customText={customText} status={status} />;
      },
    },
    {
      title: "Actions",
      render: (_, activityDetails) => {
        let eventDate = activityReader.eventDate(activityDetails);
        let check = moment().isSameOrBefore(eventDate) || moment(eventDate).format("DD MM YYYY") == moment().format("DD MM YYYY")
        const accepted = activityDetails.invitations.filter(item => item.status === 'accepted')
        let check2 = true
        if (accepted.length > 0) {
          check2 = false
          check = false
        }

        return (
          <>
            {!check ?
              <Tooltip title={!check2
                ? `Already joined, You are not allow to edit or delete.`
                : `You are not allow to edit or delete past activity.`
              }>
                <span>
                  <Button
                    type={BUTTON_TYPES.LINK}
                    disabled={true}
                    className={styles.btnDisabled}
                  >
                    <EditOutlined />
                  </Button>
                  <Button
                    type={BUTTON_TYPES.LINK}
                    disabled={true}
                    className={`${styles.btnDisabled} ${styles.iconDanger}`}
                  >
                    <DeleteOutlined />
                  </Button>
                </span>
              </Tooltip>
              :
              <>
                <Button
                  type={BUTTON_TYPES.LINK}
                  onClick={(event) => {
                    event.stopPropagation();
                    onEdit(activityDetails);
                  }}
                  className={styles.icon}
                >
                  <EditOutlined />
                </Button>
                <Button
                  type={BUTTON_TYPES.LINK}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDelete(activityDetails);
                  }}
                  className={`${styles.icon} ${styles.iconDanger}`}
                >
                  <DeleteOutlined />
                </Button>
              </>
            }
          </>
        );
      },
    },
  ];
};

export { createColumns };
