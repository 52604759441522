// Lodash
import _property from "lodash/property";

const packages = _property("packages");
const events = _property("events");
const amountDue = _property("amount_due");
const amountReceived = _property("settled_amount");
const packageBookings = _property("packageBookings");
const activityBookings = _property("activityBookings");
const grossSale = _property("total_sale");
const dudiCommission = _property("dudi_commission");
const netProfit = _property("total_profit");

export default {
  packages,
  events,
  packageBookings,
  activityBookings,
  grossSale,
  dudiCommission,
  netProfit,
  amountReceived,
  amountDue
};
