import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

//lodash
import _noop from "lodash/noop";
import _isEmpty from "lodash/isEmpty";

// Components
import PackageForm from "./PackageForm";
import { toast } from "../../../../components/atoms";

// Readers
import { packageReader } from "../../../../readers";

// Services
import { packagesService } from "../../../../services";

// Redux
import { addPackage, updatePackage } from "../../../../redux";

// Helpers
import {
  createInitialFormValues,
  createPackagePayload,
  getFormTitle,
} from "./helpers";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../../constants";

function PackageFormContainer({
  editMode,
  visible,
  onClose,
  packageDetails,
  sportsList,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const initialFormValues = createInitialFormValues(editMode, packageDetails);

  const packageId = packageReader.id(packageDetails);
  const name = packageReader.packageName(packageDetails);
  const photos = packageReader.photos(packageDetails);

  const title = getFormTitle(editMode, name);

  const handleEditPackage = (formValues, form) => {
    const payload = createPackagePayload(formValues);
    packagesService
      .updatePackage(packageId, payload)
      .then((response) => {
        dispatch(updatePackage(response));
        toast.success("Package successfully updated!!");
        onClose()
      })
      .catch((error) => {
        toast.error("Failed to update package");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreatePackage = (formValues, form) => {
    const payload = createPackagePayload(formValues);
    packagesService
      .createPackage(payload)
      .then((response) => {
        dispatch(addPackage(response));
        toast.success("New Package Added Successfully!!");
        form.resetFields();
        onClose()
      })
      .catch((error) => {
        toast.error("Failed to create package");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFormSubmission = (formValues, form) => {
    setLoading(true);
    if (editMode) {
      handleEditPackage(formValues, form);
    } else {
      handleCreatePackage(formValues, form);
    }
  };

  return (
    <PackageForm
      editMode={editMode}
      title={title}
      formValues={initialFormValues}
      visible={visible}
      onClose={onClose}
      sportsList={sportsList}
      onFinish={handleFormSubmission}
      loading={loading}
      photos={photos}
    />
  );
}

PackageFormContainer.propTypes = {
  editMode: PropTypes.bool,
  onClose: PropTypes.func,
  packageDetails: PropTypes.object,
  sportsList: PropTypes.array,
  visible: PropTypes.bool,
};

PackageFormContainer.defaultProps = {
  editMode: false,
  onClose: _noop,
  packageDetails: EMPTY_OBJECT,
  sportsList: EMPTY_ARRAY,
  visible: false,
};

export default PackageFormContainer;
