import React from "react";
import moment from "moment";

// Lodash
import _map from "lodash/map";
import _join from "lodash/join";

// Components
import { Row } from "antd";
import Tabs from "../../../../components/atoms/tabs";
import { Title, Label } from "../../../../components/atoms/typography";
import PackageLocation from "../packageLocation/PackageLocation";
import PurchaseHistory from "../purchaseHistory/PurchaseHistory";
import Album from "../../../../components/molecules/album/Album";

// Readers
import { sportReader } from "../../../../readers";

// Styles
import styles from "./packageDetails.module.scss";

const { TabPane } = Tabs;

const getSportNames = (sports) =>
  _map(sports, (sport) => sportReader.name(sport));

function PackageDetails({
  lat,
  lng,
  address,
  quantity,
  price,
  packageType,
  sports,
  packageId,
  photos,
  expire_date,
  old_price,
}) {
  const handleTabChange = () => { };

  const sportnames = getSportNames(sports);
  const sportsNamesToDisplay = _join(sportnames, ", ");
  return (
    <div className={styles.container}>
      <Tabs onChange={handleTabChange} type="card">
        <TabPane tab="About" key="about">
          <Row className={styles.aboutContainer}>
            <div className={styles.packageDetails}>
              <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Quantity</Title>
                <Label className={styles.detailValue}>{quantity}</Label>
              </div>
              <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>{old_price ? "Sale Price" : "Price"}</Title>
                <Label className={styles.detailValue}>{price} AED</Label>
              </div>
              {old_price ? <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Orignal Price</Title>
                <Label className={styles.detailValue}>{old_price} AED</Label>
              </div> : null}
              <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Services / Goods</Title>
                <Label className={styles.detailValue}>{packageType}</Label>
              </div>
              <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Sports</Title>
                <Label className={styles.detailValue}>
                  {sportsNamesToDisplay}
                </Label>
              </div>
              {expire_date ?
                <div className={styles.packageDetail}>
                  <Title className={styles.detailTitle}>Expire date</Title>
                  <Label className={styles.detailValue}>
                    {moment(expire_date).format("DD MMM, YYYY")}
                  </Label>
                </div>
                : null}
            </div>
          </Row>
        </TabPane>
        <TabPane tab="Purchase History" key="purchaseHistory">
          <PurchaseHistory packageId={packageId} />
        </TabPane>
        <TabPane tab="Location" key="location">
          <PackageLocation lat={lat} lng={lng} address={address} />
        </TabPane>
        <TabPane tab="Images" key="images">
          <Album images={photos} />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default PackageDetails;
