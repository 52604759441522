// Lodash
import _map from "lodash/map";

// Readers
import { sportReader } from "../readers";

const createSportOptions = (sports) =>
  _map(sports, (sport) => ({
    label: sportReader.name(sport),
    value: sportReader.id(sport),
  }));

const getSportName = (id, sports) => {
  let find = sports.find(item => item._id === id)
  return find?.name
};

export { createSportOptions, getSportName };
