import React from "react";
import { Table as AntdTable } from "antd";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../constants/base.constants";
import { DEFAULT_PAGINATION_PROPS } from "./constants/tableProps";

// Components
import TableHeader from "./components/tableHeader";

// Styles
import styles from "./table.module.scss";

function Table({
  columns,
  data,
  onSearch,
  onPaginationChange,
  paginationOptions,
  searchProps,
  onRowClick,
  loading,
  showSearch,
  showPagination,
  showReload,
  onReload,
  onChange,
}) {
  const updatedPaginationOptions = showPagination
    ? {
        ...DEFAULT_PAGINATION_PROPS,
        ...paginationOptions,
        onChange: onPaginationChange,
      }
    : false;

  return (
    <div className={styles.container}>
      <TableHeader
        onSearch={onSearch}
        searchProps={searchProps}
        showSearch={showSearch}
        onReload={onReload}
        showReload={showReload}
      />
      <AntdTable
        columns={columns}
        dataSource={data}
        pagination={updatedPaginationOptions}
        onRow={(record) => ({
          onClick: () => {
            onRowClick(record);
          },
        })}
        loading={loading}
        onChange={onChange}
      />
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onSearch: PropTypes.func,
  paginationOptions: PropTypes.object,
  searchProps: PropTypes.object,
  onPaginationChange: PropTypes.func,
  onRowClick: PropTypes.func,
  loading: PropTypes.bool,
  showSearch: PropTypes.bool,
  showPagination: PropTypes.bool,
  onReload: PropTypes.func,
  showReload: PropTypes.bool,
};

Table.defaultProps = {
  columns: EMPTY_ARRAY,
  data: EMPTY_ARRAY,
  onSearch: _noop,
  paginationOptions: DEFAULT_PAGINATION_PROPS,
  searchProps: EMPTY_OBJECT,
  onPaginationChange: _noop,
  onRowClick: _noop,
  loading: false,
  showSearch: true,
  showPagination: true,
  onReload: _noop,
  showReload: true,
};

export default Table;
