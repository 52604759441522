// Lodash
import _map from "lodash/map";

// Readers
import scheduleReader from "../../../../../readers/schedule";

const createScheduleOption = (schedule) => ({
  label: scheduleReader.name(schedule),
  value: scheduleReader.id(schedule),
});

const createScheduleOptions = (schedules) =>
  _map(schedules, createScheduleOption);

export { createScheduleOptions };
