import { configureStore } from "@reduxjs/toolkit";

// Reducers
import { sportsReducer } from "./slices/sportsSlice";
import { venuesReducer } from "./slices/venuesSlice";
import { packagesReducer } from "./slices/packagesSlice";
import { activitiesReducer } from "./slices/activitiesSlice";
import { packageReducer } from "../pages/package/redux/packageSlice";
import { activityReducer } from "../pages/activities/redux/activitySlice";
import { pitchReducer } from "../pages/pitch/redux/pitchSlice";
import { pitchesReducer } from "../pages/pitches/redux/pitchesSlice";
import { schedulesReducer } from "../pages/schedules/redux/schedulesSlice";
import { promotionReducer } from "../pages/promotion/redux/promotionSlice";
import { calendarsReducer } from "../pages/calendars/redux/calendarSlice";
import { transactionsReducer } from "./slices";
import { salesReducer } from "./slices/salesSlice";
import { userReducer } from "./slices/userSlice";

const store = configureStore({
  reducer: {
    sports: sportsReducer,
    packages: packagesReducer,
    activities: activitiesReducer,
    package: packageReducer,
    activity: activityReducer,
    pitch: pitchReducer,
    pitches: pitchesReducer,
    venues: venuesReducer,
    schedules: schedulesReducer,
    promotion: promotionReducer,
    calendars: calendarsReducer,
    transactions: transactionsReducer,
    sales: salesReducer,
    user: userReducer,
  },
});

export default store;
