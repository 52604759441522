import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Lodash
import _noop from "lodash/noop";
import _map from "lodash/map";
import _filter from "lodash/filter";

// Components
import Modal from "../../../../atoms/modal";
import ScheduleTimeSlot from "../scheduleTimeSlot";
import { PlusOutlined } from "../../../../atoms/icon";
import Calendar from "../../../../molecules/calendar";
import Button, { BUTTON_TYPES } from "../../../../atoms/button";
import { Title } from "../../../../atoms/typography";

// Constants
import { EMPTY_ARRAY } from "../../../../../constants";
import { EMPTY_TIME_SLOT } from "../../constants/timeSlots";
import { DEFAULT_OVERRIDE } from "../../constants/overrides";

// Styles
import styles from "./scheduleDateOverrideModal.module.scss";

function ScheduleDateOverrideModal({
  visible,
  onCancel,
  onAddOverride,
  override,
}) {
  const { date, timeSlots } = override;
  const [overrideTimeSlots, setOverrideTimeSlots] = useState(timeSlots);
  const [selectedDate, setSelectedDate] = useState(moment(date));

  const handleAddOverride = () => {
    onAddOverride({
      date: selectedDate.startOf("day").valueOf(),
      timeSlots: overrideTimeSlots,
    });
  };

  const handleDateChange = (newDateMoment) => {
    setSelectedDate(newDateMoment);
  };

  const handleAddNewSlot = () => {
    setOverrideTimeSlots([...overrideTimeSlots, EMPTY_TIME_SLOT]);
  };

  const handleUpdateTimeSlot = (index) => (updatedTimeSlot) => {
    const newTimeSlots = [...overrideTimeSlots];
    newTimeSlots[index] = updatedTimeSlot;
    setOverrideTimeSlots(newTimeSlots);
  };

  const handleDeleteTimeSlot = (index) => () => {
    const filteredTimeSlots = _filter(
      overrideTimeSlots,
      (timeSlot, timeSlotIndex) => timeSlotIndex !== index
    );
    setOverrideTimeSlots(filteredTimeSlots);
  };

  const renderTimeSlot = ({ start, end }, index) => {
    return (
      <ScheduleTimeSlot
        onDeleteTimeSlot={handleDeleteTimeSlot(index)}
        start={start}
        end={end}
        onChange={handleUpdateTimeSlot(index)}
        className={styles.timeSlot}
      />
    );
  };

  return (
    <Modal
      title="Schedule Override"
      centered
      visible={visible}
      onOk={handleAddOverride}
      onCancel={onCancel}
      className={styles.modal}
      destroyOnClose
    >
      <div className={styles.container}>
        <div className={styles.datePickerContainer}>
          <Title className={styles.title}>
            Select the date you want to add specific timeslots
          </Title>
          <div className={styles.datePicker}>
            <Calendar
              fullscreen={false}
              mode={"month"}
              onChange={handleDateChange}
              value={selectedDate}
            />
          </div>
        </div>

        <div className={styles.timeSlotsContainer}>
          <Title className={styles.title}>
            Add the specific timeslot for the selected date
          </Title>
          <div className={styles.actions}>
            <Button onClick={handleAddNewSlot} type={BUTTON_TYPES.PRIMARY}>
              <PlusOutlined className={styles.actionIcon} />
            </Button>
          </div>
          <div className={styles.timeSlots}>
            {_map(overrideTimeSlots, renderTimeSlot)}
          </div>
        </div>
      </div>
    </Modal>
  );
}

ScheduleDateOverrideModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onAddOverride: PropTypes.func,
  override: PropTypes.object,
};

ScheduleDateOverrideModal.defaultProps = {
  visible: false,
  onCancel: _noop,
  onAddOverride: _noop,
  override: DEFAULT_OVERRIDE,
};

export default ScheduleDateOverrideModal;
