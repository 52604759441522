// Constants
import MODULES from "./modules";

const ROUTES = {
  [MODULES.LOGIN.id]: "/login",
  [MODULES.PITCHES.id]: "/pitches",
  [MODULES.PITCH_DETAILS.id]: "/pitches/:pitchId",
  [MODULES.ACTIVITIES.id]: "/activities",
  [MODULES.ACTIVITY_DETAILS.id]: "/activities/:activityId",
  [MODULES.PACKAGES.id]: "/packages",
  [MODULES.PACKAGE_DETAILS.id]: "/packages/:packageId",
  [MODULES.PROFILE.id]: "/profile",
  [MODULES.SCHEDULES.id]: "/schedules",
  [MODULES.SALES.id]: "/sales",
  [MODULES.POSTS.id]: "/posts",
  [MODULES.CALENDARS.id]: "/calendars",
  [MODULES.PROMOTION.id]: "/promotion",
};

export default ROUTES;
