import React, { useEffect, useState } from "react";
import { Upload } from 'antd';

// Components
import Form from "../../../../components/atoms/form";
import Input from "../../../../components/atoms/input";
import Button from "../../../../components/atoms/button";
import { UploadOutlined, } from "../../../../components/atoms/icon";

// Reader
import freelancerReader from "../../../../readers/freelancer";

// Styles
import styles from "./editProfileForm.module.scss";

function EditProfileForm({ profileDetails, onUpdate, loading }) {
  const [form] = Form.useForm();
  const firstName = freelancerReader.firstName(profileDetails);
  const lastName = freelancerReader.lastName(profileDetails);
  const username = freelancerReader.userName(profileDetails);
  const email = freelancerReader.email(profileDetails);
  const profileImage = freelancerReader.profileImageUrl(profileDetails);
  const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 16,
    },
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues = {
    firstName: firstName || "",
    lastName: lastName || "",
    username: username || "",
    profileImage: "",
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues, profileDetails]);

  useEffect(() => {
    form.resetFields();
  }, [profileDetails]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div className={styles.container}>
      <Form
        {...layout}
        initialValues={defaultValues}
        layout="vertical"
        form={form}
        onFinish={onUpdate}
      >
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: "Please input first name!" }]}
        >
          <Input placeholder="Enter first name" disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
        // rules={[{ required: true, message: "Please input last name!" }]}
        >
          <Input placeholder="Enter last name" disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Username"
          name="username"
        // rules={[{ required: true }]}
        >
          <Input placeholder={username || ""} disabled />
        </Form.Item>
        <Form.Item
          label="Email"
          disabled
          rules={[{ required: true, type: "email" }]}
        >
          <Input placeholder={email || ""} disabled />
        </Form.Item>
        <Form.Item
          name="profileImage"
          label="Upload Photo"
          valuePropName="list"
          getValueFromEvent={normFile}
        // rules={[
        //   {
        //     required: true,
        //     message: 'Profile image is required',
        //   },
        // ]}
        >
          <Upload
            beforeUpload={() => false}
            listType="picture"
            maxCount="1"
            accept="image/png, .jpeg, .jpg, .webp"
          // showUploadList={false}
          >
            <Button
              icon={<UploadOutlined />}
            >
              {profileImage
                ? "Click to replace"
                : "Click to upload"
              }
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
          <Button
            label={"Save"}
            type="primary"
            htmlType="submit"
            loading={loading}
          />
        </Form.Item>
      </Form>
    </div>
  );
}

EditProfileForm.propTypes = {};

EditProfileForm.defaultProps = {};

export default EditProfileForm;
