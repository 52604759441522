// Lodash
import _property from "lodash/property";

const id = _property("id");
const isActive = _property("is_active");
const userName = _property("username");
const firstName = _property("firstName");
const lastName = _property("lastName");
const createdAt = _property("createdAt");
const email = _property("email");
const emailVerified = _property("email_verified");
const fullName = _property("fullname");
const gender = _property("gender");
const profileImageUrl = _property("profile_pic");
const contactNumber = _property("contact_no");
const dateOfBirth = _property("dob");
const role = _property("role");

export default {
  id,
  isActive,
  userName,
  firstName,
  lastName,
  createdAt,
  email,
  emailVerified,
  fullName,
  gender,
  profileImageUrl,
  contactNumber,
  dateOfBirth,
  role,
};
