import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import _map from "lodash/map";

// Services
import CalendarService from "../../../../../services/calendarService";
import { createSportOptions } from "../../../../../helpers";

// Components
import Modal from "../../../../atoms/modal";
import BookingHistory from "../../../../../pages/pitch/components/bookingHistory";
import Button from "../../../../atoms/button/Button";

// Styles
import styles from "./modalShowbookingHistory.module.scss";
import moment from "moment";



function ScheduleWeekView({ isModalVisible, onClose, slotId }) {

  return (
    <div className={styles.container}>

      <Modal
        title={"Booking History"}
        // centered
        className={styles.modal}
        destroyOnClose
        onCancel={onClose}
        width={1200}
        // onOk={viewOnly ? onClose : handleOk}
        visible={isModalVisible}
        // okText={viewOnly ? "okay" : "Save"}
        // cancelText="取消"
        footer={[
          <Button type="secondry" onClick={onClose}>Cancel</Button>,
        ]}
      >
        <BookingHistory
          slotId={slotId}
        />
      </Modal>

    </div>
  );
}


export default ScheduleWeekView;
