const SUNDAY = {
  day: "Sunday",
  label: "SUN",
  timeSlots: [
    {
      start: null,
      end: null,
    },
  ],
  disabled: false,
};

const MONDAY = {
  day: "Monday",
  label: "MON",
  timeSlots: [
    {
      start: null,
      end: null,
    },
  ],
  disabled: false,
};

const TUESDAY = {
  day: "Tuesday",
  label: "TUE",
  timeSlots: [
    {
      start: null,
      end: null,
    },
  ],
  disabled: false,
};

const WEDNESDAY = {
  day: "Wednesday",
  label: "WED",
  timeSlots: [
    {
      start: null,
      end: null,
    },
  ],
  disabled: false,
};

const THURSDAY = {
  day: "Thursday",
  label: "THU",
  timeSlots: [
    {
      start: null,
      end: null,
    },
  ],
  disabled: false,
};

const FRIDAY = {
  day: "Friday",
  label: "FRI",
  timeSlots: [
    {
      start: null,
      end: null,
    },
  ],
  disabled: false,
};

const SATURDAY = {
  day: "Saturday",
  label: "SAT",
  timeSlots: [
    {
      start: null,
      end: null,
    },
  ],
  disabled: false,
};

const DEFAULT_WEEKLY_SCHEDULE = [
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
];

export { DEFAULT_WEEKLY_SCHEDULE };
