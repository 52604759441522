import React from "react";
import PropTypes from "prop-types";

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";
import _filter from "lodash/filter";

// Components
import { Label } from "../../../../atoms/typography";
import CheckBox from "../../../../atoms/checkBox";
import ScheduleTimeSlot from "../scheduleTimeSlot";
import { PlusOutlined, ClearOutlined } from "../../../../atoms/icon";

// Constants
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
} from "../../../../../constants/base.constants";
import { EMPTY_TIME_SLOT } from "../../constants/timeSlots";

// Styles
import styles from "./scheduleWeekDay.module.scss";

function ScheduleWeekDay({ schedule, onChange, viewOnly }) {
  const { label, timeSlots, disabled } = schedule;
  const handleSwitchWeekdayStatus = (event) => {
    onChange({
      ...schedule,
      disabled: !event.target.checked,
    });
  };

  const handleAddNewTimeSlot = () => {
    onChange({
      ...schedule,
      timeSlots: [...timeSlots, EMPTY_TIME_SLOT],
    });
  };

  const handleClearAllTimeSlots = () => {
    onChange({
      ...schedule,
      timeSlots: [EMPTY_TIME_SLOT],
    });
  };

  const handleUpdateTimeSlot = (index) => (newTimeSlot) => {
    const updatedTimeSlots = [...timeSlots];
    updatedTimeSlots[index] = newTimeSlot;
    onChange({
      ...schedule,
      timeSlots: updatedTimeSlots,
    });
  };

  const handleDeleteTimeSlot = (index) => () => {
    const updatedTimeSlots = _filter(
      timeSlots,
      (timeSlot, timeSlotIndex) => timeSlotIndex !== index
    );
    onChange({
      ...schedule,
      timeSlots: updatedTimeSlots,
    });
  };

  const renderTimeSlot = ({ start, end, sport }, index) => {  // TODO: Need to change sport to sports
    return (
      <ScheduleTimeSlot
        onChange={handleUpdateTimeSlot(index)}
        start={start}
        end={end}
        className={styles.timeSlot}
        onDeleteTimeSlot={handleDeleteTimeSlot(index)}
        viewOnly={viewOnly}
        sportPrices={sport}
      />
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.weekDayDetails}>
        <div className={styles.weekDayName}>
          {viewOnly ? (
            <Label>{label}</Label>
          ) : (
            <CheckBox
              onChange={handleSwitchWeekdayStatus}
              checked={!disabled}
              disabled={viewOnly}
            >
              {label}
            </CheckBox>
          )}
        </div>

        <div className={styles.weekDayTimeSlots}>
          {disabled && <>Unavailable</>}
          {!disabled && _map(timeSlots, renderTimeSlot)}
        </div>
      </div>

      {!viewOnly && (
        <div className={styles.weekDayActions}>
          <PlusOutlined
            className={styles.actionIcon}
            onClick={handleAddNewTimeSlot}
          />
          <ClearOutlined
            className={styles.actionIcon}
            onClick={handleClearAllTimeSlots}
          />
        </div>
      )}
    </div>
  );
}

ScheduleWeekDay.propTypes = {
  schedule: PropTypes.object,
  onChange: PropTypes.func,
  viewOnly: PropTypes.bool,
};

ScheduleWeekDay.defaultProps = {
  schedule: EMPTY_OBJECT,
  onChange: _noop,
  viewOnly: false,
};

export default ScheduleWeekDay;
