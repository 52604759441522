import { createSlice } from "@reduxjs/toolkit";

// Lodash
import _findIndex from "lodash/findIndex";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
};

const schedulesSlice = createSlice({
  name: "schedules",
  initialState: INITIAL_STATE,
  reducers: {
    addSchedules: (state, action = {}) => {
      const { payload: schedules = EMPTY_ARRAY } = action;
      state.items = schedules;
    },
    updateSchedule: (state, action = {}) => {
      const { payload = {} } = action;
      const { _id } = payload;

      const matchingScheduleIndex = _findIndex(
        state.items,
        (item) => item._id === _id
      );
      state.items[matchingScheduleIndex] = { ...payload, id: _id };
    },
    addSchedule: (state, action = {}) => {
      const { payload: schedule = EMPTY_OBJECT } = action;
      state.items.push(schedule);
    },
  },
});

const { addSchedules, updateSchedule, addSchedule } = schedulesSlice.actions;
const schedulesReducer = schedulesSlice.reducer;

export { addSchedules, updateSchedule, addSchedule, schedulesReducer };
export default schedulesSlice;
