import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import { Label, Title } from "../../../../components/atoms/typography";
import { MoneyCollectOutlined, GroupOutlined } from "../../../../components/atoms/icon";

// Services
import { salesService } from "../../../../services";

// Redux
import { setSalesSummary } from "../../../../redux/slices/salesSlice";

// Readers
import { salesSummaryReader } from "../../../../readers";

// Constants
import { DEFAULT_STATISTIC_VALUE } from "./constants";

// Styles
import styles from "./salesSummary.module.scss";

function SalesSummary() {
  const dispatch = useDispatch();

  const salesSummary = useSelector((state) => state?.sales?.summary);

  useEffect(() => {
    salesService
      .fetchSalesSummary()
      .then((salesSummaryResponse) => {
        dispatch(setSalesSummary(salesSummaryResponse));
      })
      .finally(() => { });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.detail}>
        <GroupOutlined className={`${styles.icon} ${styles.iconPink}`} />
        <Title>
          {salesSummaryReader.packages(salesSummary) || DEFAULT_STATISTIC_VALUE}
        </Title>
        <Label className={styles.label}>Packages</Label>
      </div>
      <div className={styles.detail}>
        <MoneyCollectOutlined className={`${styles.icon} ${styles.iconDarkGreen}`} />
        <Title>
          {salesSummaryReader.packageBookings(salesSummary) || DEFAULT_STATISTIC_VALUE}
        </Title>
        <Label className={styles.label}>Packages Bookings</Label>
      </div>
      <div className={styles.detail}>
        <GroupOutlined className={`${styles.icon} ${styles.iconPink}`} />
        <Title>
          {salesSummaryReader.events(salesSummary) || DEFAULT_STATISTIC_VALUE}
        </Title>
        <Label className={styles.label}>Activities</Label>
      </div>
      <div className={styles.detail}>
        <MoneyCollectOutlined className={`${styles.icon} ${styles.iconRed}`} />
        <Title>
          {salesSummaryReader.activityBookings(salesSummary) || DEFAULT_STATISTIC_VALUE}
        </Title>
        <Label className={styles.label}>Activity Bookings</Label>
      </div>
      <div className={styles.detail}>
        <MoneyCollectOutlined className={`${styles.icon} ${styles.iconBlue}`} />
        <Title>
          {salesSummaryReader.grossSale(salesSummary)?.toFixed(2) ||
            DEFAULT_STATISTIC_VALUE}
        </Title>
        <Label className={styles.label}>Gross Sales</Label>
      </div>
      <div className={styles.detail}>
        <MoneyCollectOutlined className={`${styles.icon} ${styles.iconYellow}`} />
        <Title>
          {salesSummaryReader.dudiCommission(salesSummary)?.toFixed(2) ||
            DEFAULT_STATISTIC_VALUE}
        </Title>
        <Label className={styles.label}>Dudi Commission</Label>
      </div>
      <div className={styles.detail}>
        <MoneyCollectOutlined className={`${styles.icon} ${styles.iconGreen}`} />
        <Title>
          {salesSummaryReader.netProfit(salesSummary)?.toFixed(2) ||
            DEFAULT_STATISTIC_VALUE}
        </Title>
        <Label className={styles.label}>Net Profit</Label>
      </div>
      <div className={styles.detail}>
        <MoneyCollectOutlined className={`${styles.icon} ${styles.iconGreen}`} />
        <Title>
          {salesSummaryReader.amountReceived(salesSummary)?.toFixed(2) ||
            DEFAULT_STATISTIC_VALUE}
        </Title>
        <Label className={styles.label}>Amount Received</Label>
      </div>
      <div className={styles.detail}>
        <MoneyCollectOutlined className={`${styles.icon} ${styles.iconRed}`} />
        <Title>
          {salesSummaryReader.amountDue(salesSummary)?.toFixed(2) ||
            DEFAULT_STATISTIC_VALUE}
        </Title>
        <Label className={styles.label}>Amount Due</Label>
      </div>
    </div>
  );
}

export default SalesSummary;
