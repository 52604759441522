// Lodash
import _property from "lodash/property";

const id = _property("_id");
const name = _property("name");
const slots = _property("slots");
const userId = _property("userId");
const is_active = _property("is_active");

export default {
  id,
  name,
  slots,
  userId,
  is_active,
};
