import React from "react";
import { Radio as AntdRadio } from "antd";

const Radio = (props) => {
    const { label, className, items = [], ...restProps } = props;
    return (
        <div className={`radioContainer ${className}`}>
            {label ? <div className="label">{label}</div> : null}
            <AntdRadio.Group
                buttonStyle="solid"
                className="radioBtn"
                {...restProps}
            >
                {items.map(e => (
                    <AntdRadio.Button value={e.value}>{e.name}</AntdRadio.Button>
                ))}
            </AntdRadio.Group>
        </div>
    );
};


export default Radio;
export { Radio };