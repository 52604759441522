import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import PageHeader from "../../components/molecules/pageHeader";
import PitchFormWrapper from "./components/pitchFormWrapper";
import Table from "../../components/organisms/table";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../constants/pagination";

// Services
import pitchesService from "../../services/pitchesService";
import sportsService from "../../services/sportsService";
import venuesService from "../../services/venuesService";

// Redux
import { addPitches } from "./redux/pitchesSlice";
import { addVeneusSports } from "../../redux/slices/sportsSlice";

import { addVenues } from "../../redux/slices/venuesSlice";

// Readers
import listResponseReader from "../../readers/listResponse";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";

// Helpers
import { createColumns } from "./helpers/columns";

// Styles
import styles from "./pitches.module.scss";

function Pitches() {
  const navigate = useNavigate();
  const [totalPitches, setTotalPitches] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [selectedPitchDetails, setSelectedPitchDetails] =
    useState(EMPTY_OBJECT);
  const [paginationDetails, setPaginationDetails] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
  });
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  const [createPitchFormVisible, setCreatePitchFormVisibility] =
    useState(false);
  const dispatch = useDispatch();
  const pitches = useSelector((state) => state?.pitches?.items);
  const sportsList = useSelector((state) => state?.sports?.venues);
  const venuesOptions = useSelector((state) => state?.venues?.items);

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    setLoading(true);
    pitchesService
      .fetchPitches({
        pageNumber,
        pageSize,
        searchText,
      })
      .then((response) => {
        const pitches = listResponseReader.results(response);
        const totalPitches = listResponseReader.totalResults(response);
        setTotalPitches(totalPitches);
        dispatch(addPitches(pitches));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addPitches(EMPTY_ARRAY));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paginationDetails]);


  useEffect(() => {
    sportsService
      .fetchVenesSports()
      .then((response) => {
        dispatch(addVeneusSports(response));
      })
  }, []);


  useEffect(() => {
    venuesService
      .fetchVenues({
        pageNumber: 1,
        pageSize: 1000,
      })
      .then((response) => {
        const venues = listResponseReader.results(response);
        dispatch(addVenues(venues));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addVenues([]));
      });
  }, []);

  const handleOpenCreatePitchForm = () => {
    setEditMode(false);
    setSelectedPitchDetails(EMPTY_OBJECT);
    setCreatePitchFormVisibility(true);
  };

  const handleOpenEditPitchForm = (pitchDetail) => {
    setEditMode(true);
    setSelectedPitchDetails(pitchDetail);
    setCreatePitchFormVisibility(true);
  };

  const handleCloseCreatePitchForm = () => {
    setCreatePitchFormVisibility(false);
  };

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const handleDeletePitch = (pitchData) => {
    console.log("Deleting sport", pitchData);
  };

  const columns = createColumns({
    onEdit: handleOpenEditPitchForm,
    onDelete: handleDeletePitch,
  });

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
  };

  const handleRowClick = (pitchDetail) => {
    navigate(`${pitchDetail.id}`);
  };
  return (
    <>
      <div className={styles.pageContainer}>
        <PageHeader
          title="Pitches"
          statistics={[
            {
              title: "Total Pitches",
              value: totalPitches,
            },
          ]}
          actions={[
            {
              title: "+ New Pitch",
              handler: handleOpenCreatePitchForm,
            },
          ]}
        />
        <div className={styles.pageContent}>
          <Table
            data={pitches}
            columns={columns}
            searchProps={{
              placeholder: "Search Pitches",
            }}
            onPaginationChange={handlePaginationChange}
            onSearch={handleSearch}
            paginationOptions={{
              current: paginationDetails.pageNumber,
              total: totalPitches,
            }}
            loading={loading}
            onReload={handleReload}
            onRowClick={handleRowClick}
          />
        </div>
      </div>
      <PitchFormWrapper
        editMode={editMode}
        visible={createPitchFormVisible}
        onClose={handleCloseCreatePitchForm}
        pitchDetails={selectedPitchDetails}
        sportsList={sportsList}
        venuesOptions={venuesOptions}
        reloadPage={handleReload}
      />
    </>
  );
}

export default Pitches;
