import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Divider } from 'antd';
import moment from "moment";

// Lodash
import _noop from "lodash/noop";

// Components
import Heading from "../../../components/custom/Heading";
import Radio from "../../../components/atoms/radio";
import Form from "../../../components/atoms/form";
import Input from "../../../components/atoms/input";
import InputNumber from "../../../components/atoms/input/InputNumber";
import Button from "../../../components/atoms/button";
import Select from "../../../components/atoms/select";
import SelectFetch from "../../../components/atoms/selectWithFetch";
import { toast } from "../../../components/atoms/toaster";

// Styles
import styles from "./createPromotionForm.module.scss";

// Services
import promotionService from "../../../services/promotionService";
import { fetchUserList, fetchActivityList, fetchPitchList, fetchPackageList } from "../helpers/fetchDatalist";


function CreatePromotionForm({
  promotionDetails,
  isEditMode,
  onReload,
  onCloseForm,
}) {
  const [form] = Form.useForm();
  const [title, settitle] = useState("Create New Promotion");
  const [loading, setLoading] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [limitation, setlimitation] = useState(false);
  const [valueStatus, setValueStatus] = useState("%");
  const [promotionApplies, setPromotionApplies] = useState(false);
  const [bookingTypes, setBookingTypes] = useState([]);
  const [packageStatus, setPackageStatus] = useState(false);
  const [pitchStatus, setPitchStatus] = useState(false);
  const [activityStatus, setActivityStatus] = useState(false);
  const [targetUserStatus, setTargetUserStatus] = useState(false);
  const [targetVendorStatus, setTargetVendorStatus] = useState(false);

  const defaultValues = {
    code: "",
    name: "",
    expiredAt: "",
    expiredTime: "",
    limitation: false,
    limit: "",
    valueType: "%",
    value: "",
    bookingType: [],
    userList: [],
    vendorList: [],
    userMultipleUsage: true,
    is_active: true,
    allUserList: false,
    // allVendors: false,
    allPackage: false,
    allPitches: false,
    allActivities: false,
  };

  useEffect(() => {
    settitle("Create New Promotion")
    if (isEditMode) {
      settitle("Edit Promotion - " + promotionDetails.code)

      defaultValues.code = promotionDetails.code
      defaultValues.name = promotionDetails.name
      defaultValues.expiredAt = moment(promotionDetails.expiredAt).format("YYYY-MM-DD")
      defaultValues.expiredTime = moment(promotionDetails.expiredAt).format("HH:mm")
      defaultValues.limitation = promotionDetails.limitation
      defaultValues.limit = promotionDetails.limit
      defaultValues.valueType = promotionDetails.valueType
      defaultValues.value = promotionDetails.value
      defaultValues.bookingType = promotionDetails.bookingType
      defaultValues.userList = promotionDetails.userList
      defaultValues.userMultipleUsage = promotionDetails.userMultipleUsage
      defaultValues.is_active = promotionDetails.is_active
      // defaultValues.vendorList = promotionDetails?.vendorList.map((user) => ({
      //   label: `${user.firstName} ${user?.lastName ? user.lastName : null} (${user.username})`,
      //   value: user._id,
      // }))
      defaultValues.package_id = promotionDetails?.package_id.map((item) => ({
        label: `${item.package_name}`,
        value: item._id,
      }))
      defaultValues.pitch_id = promotionDetails?.pitch_id.map((item) => ({
        label: `${item.name}`,
        value: item._id,
      }))
      defaultValues.activity_id = promotionDetails?.activity_id.map((item) => ({
        label: `${item.name}`,
        value: item._id,
      }))
      defaultValues.userList = promotionDetails?.userList.map((user) => ({
        label: `${user.firstName} ${user?.lastName ? user.lastName : null} (${user.username})`,
        value: user._id,
      }))

      defaultValues.allUserList = promotionDetails?.userList?.length > 0 ? true : false
      // defaultValues.allVendors = promotionDetails?.vendorList?.length > 0 ? true : false
      defaultValues.allPackage = promotionDetails?.package_id?.length > 0 ? true : false
      defaultValues.allPitches = promotionDetails?.pitch_id?.length > 0 ? true : false
      defaultValues.allActivities = promotionDetails?.activity_id?.length > 0 ? true : false

      setlimitation(promotionDetails.limitation)
      setValueStatus(promotionDetails.valueType)
      setBookingTypes(promotionDetails?.bookingType)
      setPitchStatus(promotionDetails?.pitch_id?.length > 0)
      setPackageStatus(promotionDetails?.package_id?.length > 0)
      setTargetUserStatus(promotionDetails?.userList?.length > 0)
      setActivityStatus(promotionDetails?.activity_id?.length > 0)
      setPromotionApplies(promotionDetails?.bookingType?.length > 0)
      setTargetVendorStatus(promotionDetails?.vendorList?.length > 0)
    }

    form.setFieldsValue(defaultValues);
  }, [form, promotionDetails]);

  const getValues = (array = []) => {
    let temp = []
    array.forEach(item => temp.push(item.value))
    return temp
  }

  const onFinish = (values) => {
    // console.log(values, 'xxx')

    if (!promotionApplies) values.bookingType = ["pitch", "activity", "package"]
    if (values.expiredTime) values.expiredAt = new Date(values.expiredAt + " " + values.expiredTime)
    else values.expiredAt = new Date(values.expiredAt)
    delete values.expiredTime

    setLoading(true);
    if (isEditMode) {
      values.promotion_id = promotionDetails._id
      if (!limitation) values.limit = 0

      ///////////////////////////////////////////////////////////////
      if (values?.allUserList) {
        if (values?.userList?.length > 0) {
          if (values?.userList[0]?.label) {
            values.userList = getValues(values.userList)
          }
        }
      }
      else values.userList = []
      ///////////////////////////////////////////////////////////////
      // if (values?.allVendors) {
      //   if (values?.vendorList?.length > 0) {
      //     if (values?.vendorList[0]?.label) {
      //       values.vendorList = getValues(values.vendorList)
      //     }
      //   }
      // }
      // else values.vendorList = []
      ///////////////////////////////////////////////////////////////
      if (values?.allPackage) {
        if (values?.package_id?.length > 0) {
          if (values?.package_id[0]?.label) {
            values.package_id = getValues(values.package_id)
          }
        }
      }
      else values.package_id = []
      ///////////////////////////////////////////////////////////////
      if (values?.allPitches) {
        if (values?.pitch_id?.length > 0) {
          if (values?.pitch_id[0]?.label) {
            values.pitch_id = getValues(values.pitch_id)
          }
        }
      }
      else values.pitch_id = []
      ///////////////////////////////////////////////////////////////
      if (values?.allActivities) {
        if (values?.activity_id?.length > 0) {
          if (values?.activity_id[0]?.label) {
            values.activity_id = getValues(values.activity_id)
          }
        }
      }
      else values.activity_id = []
      ///////////////////////////////////////////////////////////////

      promotionService
        .updatePromotion(values)
        .then((response) => {
          if (response.success) {
            toast.success("Update Successful!!");
            onReload();
            onCloseForm();
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      promotionService
        .createPromotion(values)
        .then((response) => {
          if (response.success) {
            toast.success("New Promotion Added Successfully!!");
            form.resetFields();
            onReload();
            onCloseForm();
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const checkCode = (text) => {
    var regx = new RegExp(/^[a-z0-9.-_#@%]+([\w._#-@%]{2,})+$/);
    if (regx.test(text))
      promotionService
        .checkCode({ code: text })
        .then(({ success, data }) => {
          if (success) {
            if (data.exist) toast.warning("Code is already in use!")
            else toast.success("Code is available")
          }
          else toast.warning("Something went wrong to check Code!")
        })
        .catch((error) => {
          console.log(error);
        })
  }

  return (
    <div className="container">
      <div className="card">
        <Heading
          title={title}
          sub="Enter Your Promotion Details."
        />
        <Form
          initialValues={defaultValues}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >

          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <div>
                <Form.Item
                  name="code"
                  onBlur={(value) => checkCode(value.target.value)}
                  rules={[{
                    required: true,
                    min: 4,
                    max: 9,
                    pattern: new RegExp(/^[a-z0-9.-_#@%]+([\w._#-@%]{2,})+$/),
                    message: `CODE can only have: Alphabet, Numbers and Specail (._-#@%)`,
                  }]}>
                  <Input
                    label="Code"
                    placeholder="Enter promotion code"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div>
                <Form.Item name="name" rules={[{
                  required: true,
                  min: 10,
                  max: 100,
                }]}>
                  <Input
                    label="Desciption"
                    placeholder="Enter name"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div className="row">
                <Form.Item
                  name="expiredAt"
                  rules={[{ required: true, message: "Please select the expire date" }]}
                >
                  <Input label="Expire date" placeholder="Enter promotion expire date" type="date" min={moment().format("YYYY-MM-DD")} />
                </Form.Item>
                <Form.Item
                  name="expiredTime"
                >
                  <Input label="." placeholder="Enter promotion expire date" type="time" />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <div>
                <Form.Item name="limitation">
                  <Radio
                    label="Limitation"
                    defaultValue={false}
                    onChange={(e) => setlimitation(e.target.value)}
                    items={[
                      {
                        name: "Unlimited",
                        value: false,
                      },
                      {
                        name: "Limited",
                        value: true,
                      },
                    ]}
                  />
                </Form.Item>
              </div>
            </Col>
            {limitation && <Col className="gutter-row" span={8}>
              <div>
                <Form.Item name="limit">
                  <Input
                    label="Usage limitation"
                    placeholder="Enter promotion usage limitation"
                    type="number"
                  />
                </Form.Item>
              </div>
            </Col>}
          </Row>

          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <div>
                <Form.Item name="valueType">
                  <Radio
                    label="Discount type"
                    defaultValue="%"
                    onChange={(e) => setValueStatus(e.target.value)}
                    disabled={isEditMode}
                    items={[
                      {
                        name: "Percentage",
                        value: "%",
                      },
                      {
                        name: "Price",
                        value: "AED",
                      },
                    ]}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div>
                <Form.Item name="value" rules={[
                  { required: true, message: "Please input how much discount is provide" },
                  {
                    required: true,
                    type: 'number',
                    min: 1,
                    max: 35,
                    message: "You are not allowed to give discount more than 35% otherwise contact with dudi admin"
                  },
                ]}>
                  <InputNumber
                    label="Amount"
                    placeholder="Enter amount"
                    disabled={isEditMode}
                    addonAfter={valueStatus}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Divider />

          {/* <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item name="allVendors">
                <Radio
                  label="Targeted vendors"
                  defaultValue={targetVendorStatus}
                  value={targetVendorStatus}
                  onChange={(e) => [setTargetVendorStatus(e.target.value), setVendorList([])]}
                  items={[
                    {
                      name: "All vendors",
                      value: false,
                    },
                    {
                      name: "Specific vendors",
                      value: true,
                    }
                  ]}
                />
              </Form.Item>
            </Col>
            {targetVendorStatus && <Col className="gutter-row" span={8}>
              <div>
                <Form.Item
                  name="vendorList"
                  rules={[{ required: true, message: "Please select the vendor" }]}
                >
                  <SelectFetch
                    label="Bussiness user name list"
                    mode="multiple"
                    placeholder="Select users"
                    className={styles.select}
                    fetchOptions={fetchVendorList}
                    onChange={(newValue) => setVendorList(newValue)}
                  // value={"value"}
                  />
                </Form.Item>
              </div>
            </Col>}
          </Row> */}

          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <div>
                <Radio
                  label="Promotion applies for"
                  defaultValue={false}
                  value={promotionApplies}
                  onChange={(e) => [setPromotionApplies(e.target.value), setBookingTypes(
                    e.target.value
                      ? bookingTypes
                      : []
                  )]}
                  items={[
                    {
                      name: "All Bookings",
                      value: false,
                    },
                    {
                      name: "Specific Bookings",
                      value: true,
                    }
                  ]}
                />
              </div>
            </Col>
            {promotionApplies && <Col className="gutter-row" span={8}>
              <div>
                <Form.Item
                  name="bookingType"
                  rules={[{ required: true, message: "Please select booking type!" }]}
                >
                  <Select
                    label="Select specific type"
                    mode="multiple"
                    placeholder="Select booking type"
                    className={styles.select}
                    onChange={(e) => setBookingTypes(e)}
                    options={[{
                      label: "Pitches",
                      value: "pitch"
                    }, {
                      label: "Activities",
                      value: "activity"
                    }, {
                      label: "Packages",
                      value: "package"
                    }]}
                  />
                </Form.Item>
              </div>
            </Col>}
          </Row>

          {bookingTypes.includes("package") && <Row gutter={16}>
            <Col className="gutter-row" span={8} />
            <Col className="gutter-row" span={8}>
              <div>
                <Form.Item name="allPackage">
                  <Radio
                    label="Packages"
                    defaultValue={packageStatus}
                    onChange={(e) => setPackageStatus(e.target.value)}
                    items={[
                      {
                        name: "All packages",
                        value: false,
                      },
                      {
                        name: "Specific packages",
                        value: true,
                      }
                    ]}
                  />
                </Form.Item>
              </div>
            </Col>
            {packageStatus && <Col className="gutter-row" span={8}>
              <div>
                <Form.Item
                  name="package_id"
                  rules={[{ required: true, message: "Please select package!" }]}
                >
                  <SelectFetch
                    label="Package name"
                    mode="multiple"
                    placeholder="Select package"
                    className={styles.select}
                    fetchOptions={(e) => fetchPackageList(e, vendorList)}
                  // value={"value"}
                  />
                </Form.Item>
              </div>
            </Col>}
          </Row>}

          {bookingTypes.includes("pitch") && <Row gutter={16}>
            <Col className="gutter-row" span={8} />
            <Col className="gutter-row" span={8}>
              <div>
                <Form.Item name="allPitches">
                  <Radio
                    label="Pitch"
                    defaultValue={pitchStatus}
                    onChange={(e) => setPitchStatus(e.target.value)}
                    items={[
                      {
                        name: "All Pitches",
                        value: false,
                      },
                      {
                        name: "Specific Pitches",
                        value: true,
                      }
                    ]}
                  />
                </Form.Item>
              </div>
            </Col>
            {pitchStatus && <Col className="gutter-row" span={8}>
              <div>
                <Form.Item
                  name="pitch_id"
                  rules={[
                    { required: true, message: "Please select pitch!" }
                  ]}
                >
                  <SelectFetch
                    label="Pitch name"
                    mode="multiple"
                    placeholder="Select Pitch"
                    className={styles.select}
                    fetchOptions={(e) => fetchPitchList(e, vendorList)}
                  // value={"value"}
                  />
                </Form.Item>
              </div>
            </Col>}
          </Row>}

          {bookingTypes.includes("activity") && <Row gutter={16}>
            <Col className="gutter-row" span={8} />
            <Col className="gutter-row" span={8}>
              <Form.Item name="allActivities">
                <Radio
                  label="Activity"
                  defaultValue={activityStatus}
                  onChange={(e) => setActivityStatus(e.target.value)}
                  items={[
                    {
                      name: "All Activities",
                      value: false,
                    },
                    {
                      name: "Specific Activities",
                      value: true,
                    }
                  ]}
                />
              </Form.Item>
            </Col>
            {activityStatus && <Col className="gutter-row" span={8}>
              <Form.Item
                name="activity_id"
                rules={[{ required: true, message: "Please select activity!" }]}
              >
                <SelectFetch
                  label="Activity name"
                  mode="multiple"
                  placeholder="Select activity"
                  className={styles.select}
                  fetchOptions={(e) => fetchActivityList(e, vendorList)}
                // value={"value"}
                />
              </Form.Item>
            </Col>}
          </Row>}

          <Divider />

          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <Form.Item name="allUserList">
                <Radio
                  label="Targeted users"
                  defaultValue={targetUserStatus}
                  value={targetUserStatus}
                  onChange={(e) => setTargetUserStatus(e.target.value)}
                  items={[
                    {
                      name: "All users",
                      value: false,
                    },
                    {
                      name: "Specific users",
                      value: true,
                    }
                  ]}
                />
              </Form.Item>
            </Col>
            {targetUserStatus && <Col className="gutter-row" span={8}>
              <div>
                <Form.Item
                  name="userList"
                  rules={[{ required: true, message: "Please select the user" }]}
                >
                  <SelectFetch
                    label="Username list"
                    mode="multiple"
                    placeholder="Search user list by email"
                    className={styles.select}
                    fetchOptions={fetchUserList}
                  // value={"value"}
                  // onChange={(newValue) => {
                  //   console.log(newValue, 'eeeee');
                  // }}
                  />
                </Form.Item>
              </div>
            </Col>}
          </Row>


          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <div>
                <Form.Item
                  name="userMultipleUsage"
                  rules={[{ required: true, message: "Please select Multiple usage!" }]}
                >
                  <Radio
                    label="Multiple usage by same user"
                    defaultValue={false}
                    items={[
                      {
                        name: "Yes",
                        value: true,
                      },
                      {
                        name: "No",
                        value: false,
                      },
                    ]}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <div>
                <Form.Item
                  name="is_active"
                  rules={[{ required: true, message: "Please select status!" }]}
                >
                  <Radio
                    label="Status"
                    defaultValue={true}
                    items={[
                      {
                        name: "Active",
                        value: true,
                      },
                      {
                        name: "Inactive",
                        value: false,
                      }
                    ]}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Form.Item>
            <Button
              className={styles.saveButton}
              label={"Save"}
              type="primary"
              htmlType="submit"
              loading={loading}
            />
          </Form.Item>

        </Form>
      </div>
    </div >
  );
}

CreatePromotionForm.propTypes = {
  visible: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onClose: PropTypes.func,
};

CreatePromotionForm.defaultProps = {
  visible: false,
  isEditMode: false,
  onClose: _noop,
};

export default CreatePromotionForm;
