// Lodash
import _noop from "lodash/noop";

// Components
import { FilterOutlined } from "../../../components/atoms/icon";

// Constants
import { PAGE_TITLE } from "../constants";
import { EMPTY_OBJECT } from "../../../constants";

  // TODO: To Enable filters once the filters are finalised
const createPageHeaderProps = ({
  actionHandler = _noop,
  actionDisabled = false,
} = EMPTY_OBJECT) => {
  return {
    title: PAGE_TITLE,
    actions: [
      // {
      //   title: "Filters",
      //   handler: actionHandler,
      //   disabled: actionDisabled,
      //   renderer: () => <><FilterOutlined /> Filters</>,
      // },
    ],
  };
};

export { createPageHeaderProps };
