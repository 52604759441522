// Lodash
import _reduce from "lodash/reduce";
import _isEmpty from "lodash/isEmpty";

// Constants
import { EMPTY_OBJECT } from "../../../../../constants";
import { FORM_FIELDS } from "../constants";

const getStringifiedLocation = (location) => {
  const coordinates = [location?.coordinates?.lat, location?.coordinates?.lng];
  return JSON.stringify({
    coordinates,
    address: location?.address,
  });
};

const appendPayloadToFormData = (formData, value, key) => {
  if (key === "photos" && !_isEmpty(value)) {
    for (let photo of value) {
      if (photo.originFileObj && photo.name) {
        formData.append("newphotos", photo.originFileObj, photo.name);
      }
    }
    formData.append(key, value);
  } else {
    if (value) formData.append(key, value);
  }

  return formData;
};

const convertToFormData = (payload) => {
  const formData = new FormData();
  return _reduce(payload, appendPayloadToFormData, formData);
};

const createPackagePayload = (formValues = EMPTY_OBJECT) => {
  const location = formValues[FORM_FIELDS.LOCATION];
  const stringifiedLocation = getStringifiedLocation(location);
  const payload = {
    package_name: formValues[FORM_FIELDS.NAME],
    description: formValues[FORM_FIELDS.DESCRIPTION],
    sports: JSON.stringify(formValues[FORM_FIELDS.SPORTS]),
    address: location?.address,
    location: stringifiedLocation,
    hot_deal: formValues[FORM_FIELDS.HOT_DEAL],
    price: formValues[FORM_FIELDS.PRICE],
    old_price: formValues[FORM_FIELDS.OLD_PRICE],
    quantity: formValues[FORM_FIELDS.QUANTITY],
    package_type: formValues[FORM_FIELDS.PACKAGE_TYPE],
    is_active: formValues[FORM_FIELDS.STATUS],
    photos: formValues[FORM_FIELDS.PHOTOS],
    expire_date: formValues[FORM_FIELDS.EXPIRE_DATE],
    remove_photo: formValues?.remove_photo,
  };
  return convertToFormData(payload);
};

export { createPackagePayload };
