import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";

// Components
import Drawer from "../../../components/atoms/drawer";
import Divider from "../../../components/atoms/divider";
// import Tag from "../../../components/atoms/tag";
import { Title, Label } from "../../../components/atoms/typography";
import Status, { STATUS_TYPES } from "../../../components/molecules/status";
import { Tag } from 'antd';

// Constants
import { EMPTY_OBJECT } from "../../../constants";

// Styles
import styles from "./promotionDetails.module.scss";

function PromotionDetails({ visible, onClose, promotionDetails }) {

  const {
    createdBy,
    name,
    code,
    expiredAt,
    limitation,
    limit,
    valueType,
    value,
    bookingType = [],
    package_id,
    pitch_id,
    activity_id,
    userList,
    vendorList,
    userMultipleUsage,
    is_active,
    used,
    company_id,
  } = promotionDetails

  const status = is_active
    ? STATUS_TYPES.ACTIVE
    : STATUS_TYPES.INACTIVE;

  return (
    <Drawer
      title="Promotion Details"
      visible={visible}
      onClose={onClose}
      width={420}
    >
      <div className={styles.container}>
        <div className={styles.profileName}>
          <Title>{code}</Title> <Status status={status} />
          <Label>{value} {valueType}</Label>
        </div>

        <Divider />

        <div className="column">
          {name ?
            <div className="detailBox">
              <Title className="detailTitle">Desciption</Title>
              <Label>{name}</Label>
            </div>
            : null}


          <div className="detailBox">
            <Title className="detailTitle">Used</Title>
            <Label>{JSON.stringify(used)}</Label>
          </div>

          <div className="detailBox">
            <Title className="detailTitle">Limit</Title>
            <Label>{limitation ? limit : "No limit"}</Label>
          </div>

          <div className="detailBox">
            <Title className="detailTitle">User Multiple Usage</Title>
            <Label>{userMultipleUsage ? "Yes" : "No"}</Label>
          </div>

          <div className="detailBox">
            <Title className="detailTitle">Expired At</Title>
            <Label>{moment(expiredAt).format("DD MMM YYYY")}</Label>
          </div>

          {createdBy ?
            <div className="detailBox">
              <Title className="detailTitle">Created By</Title>
              <Label>{createdBy.username}</Label>
            </div>
            : null}


          <div className="detailBox">
            <Divider orientation="left"><Title className="detailTitle">Booking Type</Title></Divider>
            {bookingType.length > 0 ?
              _map(bookingType, (user) =>
                <Tag className="capitalize" color="magenta">{user}</Tag>
              )
              : <Tag color="green">All</Tag>
            }
          </div>

          {userList?.length > 0 ?
            <div className="detailBox">
              <Divider orientation="left"><Title className="detailTitle">User ({userList?.length})</Title></Divider>
              {_map(userList, (user) =>
                <Tag color="red">{user.username}</Tag>
              )}
            </div>
            : null}

          {package_id?.length > 0 ?
            <div className="detailBox">
              <Divider orientation="left"><Title className="detailTitle">Package ({package_id?.length})</Title></Divider>
              {_map(package_id, (item) =>
                <Tag color="orange">{item.package_name}</Tag>
              )}
            </div>
            : null}

          {pitch_id?.length > 0 ?
            <div className="detailBox">
              <Divider orientation="left"><Title className="detailTitle">Pitches ({pitch_id?.length})</Title></Divider>
              {_map(pitch_id, (item) =>
                <Tag color="gold">{item.name}</Tag>
              )}
            </div>
            : null}

          {activity_id?.length > 0 ?
            <div className="detailBox">
              <Divider orientation="left"><Title className="detailTitle">Activities ({activity_id?.length})</Title></Divider>
              {_map(activity_id, (item) =>
                <Tag color="lime">{item.name}</Tag>
              )}
            </div>
            : null}


          {/* <div className="detailBox">
            <Title className="detailTitle">Added By</Title>
            <Label>{addedBy}</Label>
          </div> */}
        </div>
      </div>
    </Drawer>
  );
}

PromotionDetails.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  promotionDetails: PropTypes.object,
};

PromotionDetails.defaultProps = {
  visible: false,
  onClose: _noop,
  promotionDetails: EMPTY_OBJECT,
};

export default PromotionDetails;
