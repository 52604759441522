import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../constants";

const INITIAL_STATE = {
  package: {
    details: EMPTY_OBJECT,
    loading: false,
  },
  purchaseHistory: {
    items: EMPTY_ARRAY,
    loading: false,
  },
  bookingsSummary: EMPTY_OBJECT
};

const packageSlice = createSlice({
  name: "currentPackage",
  initialState: INITIAL_STATE,
  reducers: {
    addPackage: (state, action = {}) => {
      const { payload: packageDetails = EMPTY_OBJECT } = action;
      state.package.details = packageDetails;
      state.package.loading = false;
    },
    setPurchaseHistory: (state, action = {}) => {
      const { payload: purchaseHistory = EMPTY_ARRAY } = action;
      state.purchaseHistory.items = purchaseHistory;
      state.purchaseHistory.loading = false;
    },
    setPackageLoading: (state, action = {}) => {
      const { payload: loading } = action;
      state.package.loading = loading;
    },
    setPackagePurchaseHistoryLoading: (state, action = {}) => {
      const { payload: loading } = action;
      state.purchaseHistory.loading = loading;
    },
    setPackageBookingsSummary: (state, action = {}) => {
      const { payload: bookingsSummary } = action;
      state.bookingsSummary = bookingsSummary;
    },
  },
});

const {
  addPackage,
  setPurchaseHistory,
  setPackageLoading,
  setPackagePurchaseHistoryLoading,
  setPackageBookingsSummary,
} = packageSlice.actions;
const packageReducer = packageSlice.reducer;

export {
  addPackage,
  setPurchaseHistory,
  setPackageLoading,
  setPackagePurchaseHistoryLoading,
  setPackageBookingsSummary,
  packageReducer,
};
export default packageSlice;
