import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Lodash
import _head from "lodash/head";

// Components
import PageHeader from "../../components/molecules/pageHeader";
import Tag, { TAG_TYPES } from "../../components/atoms/tag";
import PackageDetails from "./components/packageDetails";
import PackageDescription from "./components/packageDescription";
import { toast } from "../../components/atoms/toaster";
import Loader from "../../components/molecules/loader";
import Image from "../../components/atoms/image";

// Constants
import { EMPTY_OBJECT } from "../../constants";

// Redux
import { addPackage, setPackageLoading } from "./redux/packageSlice";

// Services
import packagesService from "../../services/packagesService";

// Reader
import packageReader from "../../readers/package";

// Helpers
import { filterValidPhotos } from "./helpers/photos";

// Styles
import styles from "./package.module.scss";

// Assets
import defaultUser from "../../assets/images/sport.png";

function Package() {
  const dispatch = useDispatch();
  const { packageId } = useParams();
  const packageDetails = useSelector(
    (state) => state?.package?.package?.details
  );
  const loading = useSelector((state) => state?.package?.package?.loading);

  const name = packageReader.packageName(packageDetails);
  const address = packageReader.address(packageDetails);
  const latitude = packageReader.latitude(packageDetails);
  const longitude = packageReader.longitude(packageDetails);
  const price = packageReader.price(packageDetails);
  const old_price = packageReader.old_price(packageDetails);
  const quantity = packageReader.quantity(packageDetails);
  const packageType = packageReader.packageType(packageDetails);
  const description = packageReader.description(packageDetails);
  const sportsPlayed = packageReader.sports(packageDetails);
  const status = packageReader.isActive(packageDetails);
  const hotDeal = packageReader.hotDeal(packageDetails);
  const expire_date = packageReader.expire_date(packageDetails);
  const photos = filterValidPhotos(packageReader.photos(packageDetails));

  useEffect(() => {
    dispatch(setPackageLoading(true));
    packagesService
      .fetchPackageDetails(packageId)
      .then((response) => {
        dispatch(addPackage(response));
      })
      .catch(() => {
        dispatch(addPackage(EMPTY_OBJECT));
        toast.error("Error fetching package details");
      });

    return () => {
      dispatch(addPackage(EMPTY_OBJECT));
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  const bannerImage = _head(photos);

  return (
    <div className={styles.container}>
      <PageHeader
        title={name}
        className="site-page-header"
        tags={[
          <Tag type={status ? TAG_TYPES.SUCCESS : TAG_TYPES.DEFAULT}>
            {status ? "Active" : "In-active"}
          </Tag>,
          hotDeal && (
            <Tag key="2" color={"red"}>
              Hot Deal
            </Tag>
          ),
        ]}
        // extra={[
        //   <Button key="1" type={BUTTON_TYPES.PRIMARY}>
        //     Deactivate
        //   </Button>,
        // ]}
        avatar={{
          src: defaultUser,
        }}
      >
        <Image src={bannerImage} width={"100%"} height={"40rem"}/>
        <PackageDescription description={description} />
      </PageHeader>
      <div className={styles.pageContent}>
        <PackageDetails
          lat={latitude}
          lng={longitude}
          address={address}
          quantity={quantity}
          price={price}
          old_price={old_price}
          packageType={packageType}
          sports={sportsPlayed}
          packageId={packageId}
          loading={loading}
          photos={photos}
          expire_date={expire_date}
        />
      </div>
    </div>
  );
}

export default Package;
