import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components
import ActivityFormWrapper from "./components/activityFormWrapper/ActivityFormWrapper";
import { confirm } from "../../components/atoms/modal";
import { Table } from "../../components/organisms";
import { PageHeader } from "../../components/molecules";
import { ExclamationCircleOutlined } from "../../components/atoms/icon";

// Services
import { activitiesService } from "../../services";

// Redux
import { addActivities } from "../../redux";

// Readers
import { listResponseReader, activityReader } from "../../readers";

// Helpers
import {
  createColumns,
  createTablePaginationOptions,
} from "./helpers";
import { toast } from "react-toastify";

// Constants
// import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";
import { DEFAULT_PAGINATION_DETAILS } from "./constants";

// Styles
import styles from "./activities.module.scss";

function Activities() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activities = useSelector((state) => state?.activities?.items);
  const sportsList = useSelector((state) => state?.sports?.items);

  const [EditMode, setEditMode] = useState(false);
  const [selectedActivityDetails, setSelectedActivityDetails] = useState({});
  const [activityFormVisibility, setActivityFormVisibility] = useState(false);
  const [searchText, setSearchText] = useState();
  const [loading, setLoading] = useState(true);
  const [sortField, setsortField] = useState(undefined);
  const [order, setorder] = useState(undefined);
  const [totalActivities, setTotalActivities] = useState(0);
  const [paginationDetails, setPaginationDetails] = useState(DEFAULT_PAGINATION_DETAILS);

  const getActivitiesData = (pageNumber, pageSize, sortField, order) => {
    setLoading(true);
    activitiesService
      .fetchActivities({
        pageNumber,
        pageSize,
        searchText,
        sortField,
        order,
      })
      .then((response) => {
        const activities = listResponseReader.results(response);
        const totalActivities = listResponseReader.totalResults(response);
        setTotalActivities(totalActivities);
        dispatch(addActivities(activities));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addActivities([]));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    getActivitiesData(pageNumber, pageSize, sortField, order);
  }, [paginationDetails]);


  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({ ...DEFAULT_PAGINATION_DETAILS });
  };

  const handleDeleteActivity = ({ _id, name }) => {
    confirm({
      title: "Are you sure you want to delete this activity?",
      icon: <ExclamationCircleOutlined />,
      content: name,
      onOk() {
        activitiesService
          .deleteActivity(_id)
          .then((response) => {
            let temp = activities.filter(item => item._id != _id)
            dispatch(addActivities(temp));
            setTotalActivities(totalActivities - 1);
            toast.success("Successfully deleted");
          })
      },
      onCancel() {
        console.log("Cancel");
      },
    });


  };

  const handleOpenEditActivityForm = (activityDetail) => {
    setSelectedActivityDetails(activityDetail)
    setEditMode(true);
    setActivityFormVisibility(true);
  };

  const columns = createColumns({
    onEdit: handleOpenEditActivityForm,
    onDelete: handleDeleteActivity,
  });

  const handleNewActivity = () => {
    setEditMode(false);
    setSelectedActivityDetails({});
    setActivityFormVisibility(true);
  };

  const handleCloseForm = () => {
    setEditMode(false);
    setSelectedActivityDetails({});
    setActivityFormVisibility(false);
  };

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
  };

  const handleRowClick = (activityDetail) => {
    const activityId = activityReader.id(activityDetail);
    navigate(`${activityId}`);
  };

  const paginationOptions = createTablePaginationOptions(
    paginationDetails.pageNumber,
    totalActivities
  );

  const searchProps = {
    placeholder: "Search Activities",
  };

  function onChange(pagination, filters, sorter, extra) {
    const field = sorter.order ? sorter.field : undefined
    const order = sorter.order
    setorder(order)
    setsortField(field)
    const { pageSize } = paginationDetails;

    getActivitiesData(
      pagination.current,
      pageSize,
      field,
      order
    );
  }

  return (
    <>
      <div className={styles.pageContainer}>
        <PageHeader
          title="Activities"
          statistics={[
            {
              title: "Total Activities",
              value: totalActivities,
            },
          ]}
          actions={[
            {
              title: "+ New Activity",
              handler: handleNewActivity,
            },
          ]}
        />

        <div className={styles.pageContent}>
          <Table
            columns={columns}
            data={activities}
            loading={loading}
            paginationOptions={paginationOptions}
            searchProps={searchProps}
            onPaginationChange={handlePaginationChange}
            onReload={handleReload}
            onRowClick={handleRowClick}
            onSearch={handleSearch}
            onChange={onChange}
          />
        </div>
      </div>

      <ActivityFormWrapper
        editMode={EditMode}
        onReload={handleReload}
        onClose={handleCloseForm}
        details={selectedActivityDetails}
        sportsList={sportsList}
        visible={activityFormVisibility}
      />
    </>
  );
}

export default Activities;
