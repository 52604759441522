import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
};

const pitchesSlice = createSlice({
  name: "pitches",
  initialState: INITIAL_STATE,
  reducers: {
    addPitches: (state, action = {}) => {
      const { payload: pitches = EMPTY_ARRAY } = action;
      state.items = pitches;
    },
  },
});

const { addPitches } = pitchesSlice.actions;
const pitchesReducer = pitchesSlice.reducer;

export { addPitches, pitchesReducer };
export default pitchesSlice;
