import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../constants";

const INITIAL_STATE = {
  activity: {
    details: EMPTY_OBJECT,
    loading: false,
  },
  bookingsSummary: EMPTY_OBJECT
};

const activitySlice = createSlice({
  name: "currentActivity",
  initialState: INITIAL_STATE,
  reducers: {
    addActivity: (state, action = {}) => {
      const { payload: activityDetails = EMPTY_OBJECT } = action;
      state.activity.details = activityDetails;
      state.activity.loading = false;
    },
    setActivityLoading: (state, action = {}) => {
      const { payload: loading } = action;
      state.activity.loading = loading;
    },
  },
});

const {
  addActivity,
  setActivityLoading,
} = activitySlice.actions;
const activityReducer = activitySlice.reducer;

export {
  addActivity,
  setActivityLoading,
  activityReducer,
};
export default activitySlice;
