import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { BASE_URL } from "../constants/services";

// Utils
import getDataFromResponse from "../utils/getDataFromResponse";

const header = {
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
  },
}

const fetchAllCalendars = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/calendar`,
  });
  return axios
    .get(url, header)
    .then((response) => getDataFromResponse(response))
    .catch((e) => {
      toast.error(e.response.data?.error || e.response.data?.message);
    });
};

const createCalendar = (payload) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/calendar/create`,
  });
  return axios
    .post(url, payload, header)
    .then((response) => getDataFromResponse(response))
    .catch((e) => {
      toast.error(e.response.data?.error || e.response.data?.message);
    });
};

const updateCalendars = (payload) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/calendar/update`,
  });
  return axios
    .put(url, payload, header)
    .then((response) => getDataFromResponse(response))
    .catch((e) => {
      toast.error(e.response.data?.error || e.response.data?.message);
    });
};

const deleteCalendar = (payload) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/calendar/${payload}`,
  });
  return axios
    .delete(url, header)
    .then((response) => getDataFromResponse(response))
    .catch((e) => {
      toast.error(e.response.data?.error || e.response.data?.message);
    });
};

const createSlot = (payload) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/calendar/slot/add`,
  });
  return axios
    .post(url, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((e) => {
      toast.error(e.response.data?.error || e.response.data?.message);
    });
};

const updateSlot = (payload) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/calendar/slot/update`,
  });
  return axios
    .put(url, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((e) => {
      toast.error(e.response.data?.error || e.response.data?.message);
    });
};

const fetchAllSlots = (calendar_id, date_start, date_end) => {
  const payload = {
    calendar_id,
    date_start,
    date_end,
  };

  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/calendar/slot/all/`,
    query: payload,
  });
  return axios
    .get(url, header)
    .then((response) => getDataFromResponse(response))
    .catch((e) => {
      toast.error(e.response.data?.error || e.response.data?.message);
    });
};

const reservedSlot = (payload) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/calendar/slot/reserved`,
  });
  return axios
    .put(url, payload, header)
    .then((response) => getDataFromResponse(response))
    .catch((e) => {
      toast.error(e.response.data?.error || e.response.data?.message);
    });
};

const deleteSlot = (payload) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/calendar/slot/delete`,
  });
  return axios
    .post(url, payload, header)
    .then((response) => getDataFromResponse(response))
    .catch((e) => {
      toast.error(e.response.data?.error || e.response.data?.message);
    });
};

const fetchCalendarPurchaseHistory = (calendar_id, slot_id, search_text) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/transactions`,
    query: {
      calendar_id,
      slot_id,
      search_text,
    },
  });
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((e) => {
      toast.error(e.response.data?.error || e.response.data?.message);
    });
};

export default {
  fetchAllCalendars,
  createCalendar,
  updateCalendars,
  deleteCalendar,
  createSlot,
  updateSlot,
  fetchAllSlots,
  deleteSlot,
  fetchCalendarPurchaseHistory,
  reservedSlot,
};
