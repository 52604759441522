// Components
import { Payee } from "../../../../../components/molecules";

// Constants
import { EMPTY_OBJECT } from "../../../../../constants";

const createColumns = () => {
  return [
    {
      title: "User Details",
      render: (_, data) => {
        const userDetails = data.recevier_id || EMPTY_OBJECT;
        const { email, profile_pic, firstName, lastName, username } = userDetails;
        const name = `${firstName} ${lastName}`;
        return <Payee email={email} name={name} profilePicture={profile_pic} />;
      },
    },
    {
      title: "Status",
      render: (_, data) => data.status,
    },
  ];
};

export { createColumns };
