import React from "react";
import { Select as AntdSelect } from "antd";

const Select = ({ label, className, ...restProps }) => {
    return (
        <div className={`selectContainer ${className}`}>
            {label ? <div className="label">{label}</div> : null}
            <AntdSelect
                {...restProps}
                className="selectBox"
            />
        </div>
    );
};

export default Select;
export { Select };