import React from "react";
import PropTypes from "prop-types";

// Lodash
import _head from "lodash/head";

// Components
import Image from "../../../../components/atoms/image";

// Constants
import { EMPTY_ARRAY } from "../../../../constants";

// Styles
import styles from "./pitchDescription.module.scss";

function PitchDescription({ description, images }) {
  const bannerImage = _head(images);
  return (
    <div className={styles.container}>
      <div className={styles.description}>{description}</div>
      <div className={styles.bannerImage}>
        <Image src={bannerImage} />
      </div>
    </div>
  );
}

PitchDescription.propTypes = {
  description: PropTypes.string,
  images: PropTypes.array,
};

PitchDescription.defaultProps = {
  description: null,
  images: EMPTY_ARRAY,
};

export default PitchDescription;
