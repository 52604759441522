import React from "react";
import PropTypes from "prop-types";
import DefaultUserImage from "../../../assets/images/user.png";


// Styles
import styles from "./payee.module.scss";

function Payee({ email, profilePicture, name }) {
  return (
    <div className={styles.container}>
      <div className={styles.profileName}>
        <img src={profilePicture || DefaultUserImage} className={styles.profileImage} alt={name} />
      </div>
      <div className={styles.profileDetails}>
        <div className={styles.name}>{name}</div>
        <div className={styles.email}>{email}</div>
      </div>
    </div>
  );
}

Payee.propTypes = {
  email: PropTypes.string,
  profilePicture: PropTypes.string,
  name: PropTypes.string,
};

Payee.defaultProps = {
  email: null,
  profilePicture: null,
  name: null,
};

export default Payee;
