import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

// Components
import AppSkeleton from "../components/organisms/appSkeleton";
import PitchesPage from "../pages/pitches";
import ActivitiesPage from "../pages/activities";
import ActivityDetailsPage from "../pages/activity";
import PitchDetailsPage from "../pages/pitch";
import PackagesPage from "../pages/packages";
import PackageDetailsPage from "../pages/package";
import SchedulesPage from "../pages/schedules";
import PromotionPage from "../pages/promotion";
import CalendarsPage from "../pages/calendars";
import ProfilePage from "../pages/profile";
import PostsPage from "../pages/posts";
import SalesPage from "../pages/sales";

// Constants
import MODULES from "../constants/modules";
import ROUTES from "../constants/routes";

function SecureRoutes() {
  const navigate = useNavigate();
  const signedIn = localStorage.getItem("signedIn");

  useEffect(() => {
    if (signedIn !== "true") {
      navigate("/login");
      return;
    }
  }, [signedIn]);

  if (signedIn !== "true") {
    return null;
  }

  // const { REACT_APP_URL, NODE_ENV } = process.env
  // const devAll = REACT_APP_URL === "dev" || NODE_ENV === "development"
  return (
    <AppSkeleton>
      <Routes>
        <Route path={ROUTES[MODULES.PITCHES.id]} element={<PitchesPage />} />
        <Route path={ROUTES[MODULES.PACKAGES.id]} element={<PackagesPage />} />
        <Route
          path={ROUTES[MODULES.PACKAGE_DETAILS.id]}
          element={<PackageDetailsPage />}
        />
        <Route
          path={ROUTES[MODULES.PITCH_DETAILS.id]}
          element={<PitchDetailsPage />}
        />
        <Route
          path={ROUTES[MODULES.ACTIVITIES.id]}
          element={<ActivitiesPage />}
        />
        <Route
          path={ROUTES[MODULES.ACTIVITY_DETAILS.id]}
          element={<ActivityDetailsPage />}
        />
        <Route
          path={ROUTES[MODULES.SCHEDULES.id]}
          element={<SchedulesPage />}
        />
        <Route
          path={ROUTES[MODULES.CALENDARS.id]}
          element={<CalendarsPage />}
        />
        <Route path={ROUTES[MODULES.SALES.id]} element={<SalesPage />} />
        <Route
          path="*"
          element={<Navigate to={ROUTES[MODULES.SALES.id]} replace />}
        />
        <Route path={ROUTES[MODULES.PROFILE.id]} element={<ProfilePage />} />
        <Route path={ROUTES[MODULES.POSTS.id]} element={<PostsPage />} />
        <Route path={ROUTES[MODULES.PROMOTION.id]} element={<PromotionPage />} />
      </Routes>
    </AppSkeleton>
  );
}

export default SecureRoutes;
