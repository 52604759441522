import axios from "axios";
import queryString from "query-string";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
  MAX_PAGE_SIZE,
} from "../constants/pagination";

// Utils
import getDataFromResponse from "../utils/getDataFromResponse";

const headers = {
  headers: {
    authorization: `Bearer ${window.localStorage.getItem("token")}`,
  }
}

const fetchSports = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  searchText = "",
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    search_text: searchText,
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/sports`,
    query: payload,
  });
  return axios
    .get(url, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      console.log(`Error: Failed to fetch Sports, ${error}`);
    });
};

const fetchAllSports = () => {
  const url = queryString.stringifyUrl({ url: `${BASE_URL}/sports/all`, });
  return axios
    .get(url)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      console.log(`Error: Failed to fetch Sports, ${error}`);
    });
};

const createSport = (payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/sports/create`,
  });
  return axios
    .post(url, payload)
    .then((response) => response)
    .catch((error) => {
      console.log(`Error: Failed to create Sport, ${error}`);
    });
};

const updateSport = (id, payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/sports/${id}`,
  });
  return axios
    .put(url, payload)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      console.log(`Error: Failed to create Sport, ${error}`);
    });
};

const fetchVenesSports = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/sports/fromVenues`
  });
  return axios
    .post(url, null, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      console.log(`Error: Failed to fetch Sports,`, error.response.data);
    });
};

export default {
  fetchSports,
  fetchAllSports,
  createSport,
  updateSport,
  fetchVenesSports,
};
