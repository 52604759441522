import axios from "axios";
import queryString from "query-string";

// Constants
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "../constants/pagination";
import { EMPTY_OBJECT } from "../constants/base.constants";

// Utils
import getDataFromResponse from "../utils/getDataFromResponse";

const fetchSchedules = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  searchText = "",
  sortField,
  order,
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    search_text: searchText,
    sort_field: sortField,
    order_by: order === "decend" ? "desc" : order === "ascend" ? "asc" : "",
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/schedule`,
    query: payload,
  });
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      console.log(`Error: Failed to fetch Schedules, ${error}`);
    });
};

// This will fetch the schedules of the user only
const fetchAllSchedules = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/schedule`,
  });
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response));
};

const createSchedule = (payload) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/schedule`,
  });
  return axios
    .post(url, payload, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response));
};

const updateSchedule = (payload, id) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/schedule/${id}`,
  });
  return axios
    .put(url, payload, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response));
};

export default {
  fetchSchedules,
  updateSchedule,
  fetchAllSchedules,
  createSchedule,
};
