import React from "react";
import PropTypes from "prop-types";

// Components
import { ConfigProvider, Row, Col } from "antd";
import Table from "../../../../components/organisms/table";
import { Title, Label } from "../../../../components/atoms/typography";

// Helpers
import { createColumns } from "./helpers/columns";

// Styles
import styles from "./invitations.module.scss";

// Assets
import transactionImg from "../../../../assets/images/user.png";

function Empty({ description }) {
  return (
    <div className={styles.container}>
      <img src={transactionImg} alt={description} height={120} width={120} />
      <p>{description}</p>
    </div>
  );
}

function PurchaseHistory({ data }) {

  const columns = createColumns();

  return (
    <div className={styles.container}>
      <ConfigProvider
        renderEmpty={() => <Empty description="No user found" />}
      >
        <Row className={styles.purchaseAnalytics}>
          <Col className={styles.packageDetail}>
            <Title className={styles.detailTitle}>Total Invitations</Title>
            <Label className={styles.detailValue}>
              {data?.length || "0"}
            </Label>
          </Col>
          <Col className={styles.packageDetail}>
            <Title className={styles.detailTitle}>Total Pending</Title>
            <Label className={styles.detailValue}>
              {data.filter(item => item.status === 'pending').length || "0"}
            </Label>
          </Col>
          <Col className={styles.packageDetail}>
            <Title className={styles.detailTitle}>Total Accepted</Title>
            <Label className={styles.detailValue}>
              {data.filter(item => item.status === 'accepted').length || "0"}
            </Label>
          </Col>
          <Col className={styles.packageDetail} />
          <Col className={styles.packageDetail} />
        </Row>
        <Table
          loading={false}
          showReload={false}
          showSearch={false}
          showPagination={false}
          data={data}
          columns={columns}
        />
      </ConfigProvider>
    </div>
  );
}

PurchaseHistory.propTypes = {
  activityId: PropTypes.string.isRequired,
};

PurchaseHistory.defaultProps = {};

export default PurchaseHistory;
