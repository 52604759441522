import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components
import CreatePostForm from "./components/createPostForm";
import { PageHeader } from "../../components/molecules";
import Image from "../../components/atoms/image";
import {
  HeartOutlined,
  CommentOutlined,
} from "../../components/atoms";
import Loader from "../../components/molecules/loader";
import { Row, Col, Card, Button, Modal } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { toast } from "../../components/atoms/toaster";

// Lodash
import _findIndex from "lodash/findIndex";

// Services
import postsService from "../../services/postsService";


// Constants
import { EMPTY_OBJECT, EMPTY_ARRAY } from "../../constants";

// Styles
import styles from "./posts.module.scss";

const { Meta } = Card;
const { confirm } = Modal;


function Posts() {
  const dispatch = useDispatch();
  const sportsList = useSelector((state) => state?.sports?.items);

  const [selectedPostDetails, setSelectedPostDetails] = useState(EMPTY_OBJECT);
  const [editMode, setEditMode] = useState(false);
  const [createPostFormVisible, setCreatePostFormVisibility] = useState(false);
  const [allPosts, setAllPosts] = useState(EMPTY_ARRAY);
  const [searchText, setSearchText] = useState("");
  const [pageLimit, setpageLimit] = useState(20);
  const [pageNumber, setpageNumber] = useState(1);
  const [nextPage, setnextPage] = useState(2);
  const [hasNextPage, sethasNextPage] = useState(false);
  const [totalDocs, settotalDocs] = useState(0);
  const [loading, setloading] = useState(true);
  const [deleteLoading, setdeleteLoading] = useState(true);


  useEffect(() => {
    setAllPosts(EMPTY_ARRAY)
    getPostsData(pageLimit, pageNumber)
  }, []);


  const onNextPage = () => {
    getPostsData(pageLimit, nextPage)
  };

  const getPostsData = (pageLimit, pageNumber, sortField, order) => {
    setloading(true)
    postsService
      .fetchPosts({
        pageNumber,
        pageLimit,
        searchText,
        sortField,
        order,
      })
      .then((response) => {
        setAllPosts([...allPosts, ...response.docs,]);
        setnextPage(response.nextPage)
        sethasNextPage(response.hasNextPage)
        settotalDocs(response.totalDocs)
        setloading(false)
      })
      .catch((error) => {
        console.log(error, 'error')
        setAllPosts(EMPTY_ARRAY);
        sethasNextPage(false)
        setloading(false)
      })
  }

  const handleOpenCreatePostForm = () => {
    setEditMode(false);
    setSelectedPostDetails(EMPTY_OBJECT);
    setCreatePostFormVisibility(true);
  };
  const handleEditPostForm = (item) => {
    setEditMode(true);
    setSelectedPostDetails(item);
    setCreatePostFormVisibility(true);
  };

  const updatePost = (post) => {
    let temp = [...allPosts]
    const postIndex = _findIndex(allPosts, (item) => item._id === post._id)
    temp[postIndex] = post;
    setAllPosts(temp)
  };


  const deletePost = (post, index) => {
    setdeleteLoading(
      { [post._id]: true }
    )

    confirm({
      title: 'Are you sure delete this post?',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        postsService
          .deletePost(post?._id)
          .then((response) => {
            if (response.success) {
              let temp = [...allPosts]
              const posts = temp.filter((item) => item._id !== post._id)
              setAllPosts(posts)
              settotalDocs(totalDocs - 1)
              toast.dark("Deleted Successful!!");
            } else {
              toast.warning("Something went wrong while updating!");
            }
            setdeleteLoading({ [post._id]: false })
          })
          .catch((error) => {
            setdeleteLoading({ [post._id]: false })
            console.log(error);
          })
      },
      onCancel() {
        setdeleteLoading({ [post._id]: false })
      },
    });

  };


  return (
    <div className={styles.pageContainer}>
      <PageHeader
        title="Posts"
        statistics={[
          {
            title: "Total Posts",
            value: totalDocs,
          },
        ]}
        actions={[
          {
            title: "+ New Posts",
            handler: handleOpenCreatePostForm,
          },
        ]}
      />

      {loading ? <Loader /> : <Row>
        {allPosts.map(item => (
          <Col key={item._id} xs={24} sm={12} md={12} lg={8} xl={6}>
            <div className={styles.card}>

              <div className={
                item?.role === "user"
                  ? styles.userRole
                  : styles.freelancerRole
              }>
                {item?.role}
              </div>

              <Card
                hoverable
                cover={
                  item?.file && < Image
                    width={'100%'}
                    height={250}
                    src={item?.file}
                    // fallback={DefaultUserImage}
                    style={{ objectFit: 'cover' }}
                  />
                }
                actions={[
                  <EditOutlined key="edit" onClick={() => handleEditPostForm(item)} />,
                  deleteLoading[item._id] ? <div style={{ marginTop: 12 }}><Loader /> </div> : <DeleteOutlined key="ellipsis" onClick={() => deletePost(item)} />,
                ]}
              >
                {item.likes?.length > 0 ?
                  <div className={styles.likes}>
                    <HeartOutlined />{" "}
                    {item.likes.length}
                  </div> : null}
                {item.comments.length > 0 ?
                  <div className={styles.comments}>
                    <CommentOutlined />{" "}
                    {item.comments.length}
                  </div> : null}

                {item.status === "reported" ?
                  <div className={styles.statusReported}>
                    Reported
                  </div>
                  : item.status === "deleted" ?
                    <div className={styles.statusDeleted}>
                      Deleted
                    </div>
                    : null}
                <Meta
                  title={item.sports_id?.name}
                  description={item?.description}
                />
              </Card>
            </div>
          </Col>
        ))}
      </Row>}

      <center>
        {hasNextPage &&
          <Button
            type="primary"
            onClick={() => onNextPage()}
            className={styles.showMoreButton}
          >
            Load More
          </Button>
        }
      </center>

      <CreatePostForm
        editMode={editMode}
        visible={createPostFormVisible}
        onClose={() => setCreatePostFormVisibility(false)}
        postDetails={selectedPostDetails}
        sportsList={sportsList}
        addNew={(post) => setAllPosts([post, ...allPosts])}
        updatePost={(post) => updatePost(post)}
      />
    </div >
  );
}

export default Posts;
