import React from "react";
import { Tooltip as AntTooltip } from 'antd';


const Tooltip = ({ disabled, children, ...restProps }) => {
  if (disabled)
    return children
  else
    return (
      <AntTooltip {...restProps}>
        {children}
      </AntTooltip>
    );
};

export default Tooltip;
