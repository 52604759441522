import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// Lodash
import _noop from "lodash/noop";

// Components
import RadioButton from "../../../../atoms/radioButton";
import Button from "../../../../atoms/button/Button";
import ScheduleView from "../scheduleView";
// import ScheduleTimeZoneSelect from "../scheduleTimeZoneSelect";
import InputSelectView from "../../../../molecules/inputSelectView";

// Styles
import styles from "./scheduleHeader.module.scss";

// Helpers
import { createSportOptions, getSportName } from "../../../../../helpers";

function ScheduleHeader({
  onViewChange,
  view,
  onNameChange,
  onStartTimeChange,
  onEndTimeChange,
  onAction,
  name,
  startTime,
  endTime,
  viewOnly,
}) {

  useEffect(() => {
    setstartTime(startTime || "10:00")
    setEndTime(endTime || "21:00")

    onStartTimeChange(startTime || "10:00")
    onEndTimeChange(endTime || "21:00")

    setslots("60")
    setSport("")
    setPrice("")
    setCapacity("")
  }, [viewOnly])

  const sports = useSelector((state) => state?.sports?.venues);
  const sportOptions = createSportOptions(sports);

  const [startTimes, setstartTime] = useState("")
  const [endTimes, setEndTime] = useState("")

  const [startTimes1, setstartTime1] = useState("")
  const [endTimes1, setEndTime1] = useState("")

  const [slots, setslots] = useState("60")
  const [Sport, setSport] = useState("")
  const [Price, setPrice] = useState("")
  const [Capacity, setCapacity] = useState("")

  const [VSport, setVSport] = useState("")
  const [VPrice, setVPrice] = useState("")
  const [VCapacity, setVCapacity] = useState("")

  const [statusendTimes, setstatusEndTime] = useState("")
  const [statusendTimes1, setstatusEndTime1] = useState("")
  const [statusStartTimes1, setstatusStartTime1] = useState("")
  const [shift, setshift] = useState(false)
  const [btnEnable, setbtnEnable] = useState(true)

  const handleNameChange = (value) => {
    const updatedName = value;
    onNameChange(updatedName);
  };

  const handleStartTime = (value) => {
    setstartTime(value)
    setEndTime(value)
    onStartTimeChange(value)
    setstatusEndTime("")
  }

  const handleEndTime = (value) => {
    setEndTime(value)
    const s = new Date('2022-01-01 ' + startTimes);
    const e = new Date('2022-01-01 ' + value);
    if (s < e) {
      onEndTimeChange(value)
      setstatusEndTime("success")
      if (!shift) setbtnEnable(true)
      // else setbtnEnable(false)
    }
    else {
      setbtnEnable(false)
      setstatusEndTime('error')
    }
  }

  const handleStartTime1 = (value) => {
    setstartTime1(value)

    const s = new Date('2022-01-01 ' + endTimes);
    const e = new Date('2022-01-01 ' + value);
    if (s < e) {
      setEndTime1(value)
      // onStartTimeChange(value)
      setstatusStartTime1("success")
    } else {
      setstatusStartTime1("error")
    }


  }

  const handleEndTime1 = (value) => {
    setEndTime1(value)
    const s = new Date('2022-01-01 ' + startTimes1);
    const e = new Date('2022-01-01 ' + value);
    if (s < e) {
      // onEndTimeChange(value)
      setstatusEndTime1("success")
      setbtnEnable(true)
    }
    else {
      setbtnEnable(false)
      setstatusEndTime1('error')
    }
  }

  const handleActionButtonClick = () => {
    const DefaultSports = [{
      id: {
        _id: Sport,
        name: getSportName(Sport, sports)
      },
      price: Price,
      capacity: Capacity,
    }]

    if (!Sport && !Price && !Capacity)
      onAction(startTimes, endTimes, slots, startTimes1, endTimes1)
    else if (
      Sport && !Price && !Capacity ||
      Sport && Price && !Capacity ||
      Sport && !Price && Capacity ||
      !Sport && !Price && Capacity ||
      !Sport && Price && !Capacity ||
      !Sport && Price && Capacity
    ) {
      if (!Sport) setVSport("error")
      if (!Price) setVPrice("error")
      if (!Capacity) setVCapacity("error")
    }
    else {
      onAction(startTimes, endTimes, slots, startTimes1, endTimes1, DefaultSports)
    }
  }

  const onChangeShift = (item) => {
    setshift(item)
    // setbtnEnable(!item)

    if (!item) {
      setstartTime("10:00")
      setEndTime("21:00")

      setstatusStartTime1("")
      setstatusEndTime1("")
    } else {
      setstartTime("09:00")
      setEndTime("14:00")
      setstartTime1("17:00")
      setEndTime1("22:00")
    }
  }


  return (
    <>
      <div className={styles.container}>

        <InputSelectView
          title={name}
          viewOnly={viewOnly}
          label="Schedule Name"
          onChange={(e) => handleNameChange(e)}
          value={name}
        />

        {!viewOnly &&
          <RadioButton
            options={[
              { label: 'Single shift', value: false },
              { label: 'Double shift', value: true },
            ]}
            onChange={onChangeShift}
            value={shift}
          />}


        {/* <ScheduleTimeZoneSelect /> */}
        <ScheduleView onViewChange={onViewChange} view={view} />
      </div>
      <div className={styles.container1}>
        <InputSelectView
          title={startTime}
          viewOnly={viewOnly}
          type="time"
          label="Start Time"
          onChange={(e) => handleStartTime(e)}
          value={startTimes}
        />
        <InputSelectView
          title={endTime}
          viewOnly={viewOnly}
          type="time"
          label="End Time"
          onChange={(e) => handleEndTime(e)}
          value={endTimes}
          inputStatus={statusendTimes}
        />

        <InputSelectView
          viewOnly={viewOnly}
          type="number"
          label="Default Slot (min)"
          onChange={(e) => setslots(e)}
          value={slots}
        />

        {!viewOnly && (
          <Button
            disabled={!btnEnable}
            onClick={handleActionButtonClick}
            className={styles.actionButton}
          >
            Genrate Schedule
          </Button>
        )}
      </div>

      {shift &&
        <div className={styles.container1}>
          <InputSelectView
            title={startTime}
            viewOnly={viewOnly}
            type="time"
            label="Start Time"
            onChange={(e) => handleStartTime1(e)}
            value={startTimes1}
            inputStatus={statusStartTimes1}
          />
          <InputSelectView
            title={endTime}
            viewOnly={viewOnly}
            type="time"
            label="End Time"
            onChange={(e) => handleEndTime1(e)}
            value={endTimes1}
            inputStatus={statusendTimes1}
          />
        </div>}

      <div className={styles.container1}>
        <InputSelectView
          select={true}
          option={sportOptions}
          viewOnly={viewOnly}
          label="Default Sports"
          onChange={(e) => [setSport(e), setVSport(e ? "success" : "error")]}
          value={Sport}
          inputStatus={VSport}
        />

        <InputSelectView
          viewOnly={viewOnly}
          type="number"
          label="Default Price"
          onChange={(e) => [setPrice(e), setVPrice(e ? "success" : "error")]}
          value={Price}
          inputStatus={VPrice}
        />

        <InputSelectView
          viewOnly={viewOnly}
          type="number"
          label="Default Capacity"
          onChange={(e) => [setCapacity(e), setVCapacity(e ? "success" : "error")]}
          value={Capacity}
          inputStatus={VCapacity}
        />
      </div>
    </>
  );
}

ScheduleHeader.propTypes = {
  onViewChange: PropTypes.func,
  view: PropTypes.string.isRequired,
  onNameChange: PropTypes.func,
  handleEndTimeChange: PropTypes.func,
  handleStartTimeChange: PropTypes.func,
  onAction: PropTypes.func,
  name: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  viewOnly: PropTypes.bool,
};

ScheduleHeader.defaultProps = {
  onViewChange: _noop,
  onNameChange: _noop,
  handleEndTimeChange: _noop,
  handleStartTimeChange: _noop,
  onAction: _noop,
  viewOnly: false,
};

export default ScheduleHeader;
