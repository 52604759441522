import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import Form from "../../../../components/atoms/form";
import { Password } from "../../../../components/atoms/input";
import Button from "../../../../components/atoms/button";
import { toast } from "../../../../components/atoms/toaster";


// Reader
import freelancerReader from "../../../../readers/freelancer";

// user Service
import userService from "../../../../services/userService";

// Styles
import styles from "./forgotPasswordForm.module.scss";

function ForgotPasswordForm({ profileDetails }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const onFinish = (values) => {
    setLoading(true);
    userService
      .updatePassword(values)
      .then((response) => {
        if (response.success) {
          toast.success("Update Successful!!");
          form.resetFields();
        } else {
          toast.warning("Something went wrong while updating!");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <Form
        form={form}
        {...layout}
        initialValues={{
          password: "",
          new_password: "",
          confirm: "",
        }}
        layout="vertical"
        onFinish={onFinish}
      >

        <Form.Item
          name="current_password"
          label="Current Password"
          rules={[
            {
              required: true,
              message: "Please input your current password!",
            },
          ]}
          hasFeedback
        >
          <Password />
        </Form.Item>
        <Form.Item
          name="new_password"
          label="New Password"
          rules={[
            {
              required: true,
              message: "Please input your new password!",
            },
          ]}
          hasFeedback
        >
          <Password />
        </Form.Item>

        <Form.Item
          name="confirm_password"
          label="Confirm New Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your new password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Password />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
          <Button
            label={"Save"}
            type="primary"
            htmlType="submit"
            loading={loading}
          />
        </Form.Item>
      </Form>
    </div>
  );
}

ForgotPasswordForm.propTypes = {
  sportDetails: PropTypes.object,
};

ForgotPasswordForm.defaultProps = {};

export default ForgotPasswordForm;
