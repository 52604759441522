import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Lodash
import _head from "lodash/head";

// Components
import PageHeader from "../../components/molecules/pageHeader";
import Tag, { TAG_TYPES } from "../../components/atoms/tag";
import ActivityDetails from "./components/activityDetails";
import { toast } from "../../components/atoms/toaster";
import Loader from "../../components/molecules/loader";

// Constants
import { EMPTY_OBJECT } from "../../constants";

// Services
import activitiesService from "../../services/activitiesService";

// Styles
import styles from "./activity.module.scss";

// Assets
import defaultUser from "../../assets/images/sport.png";

function Activity() {
  const { activityId } = useParams();
  const [activity, setActivity] = useState(EMPTY_OBJECT)
  const [activityPayment, setActivityPayment] = useState(EMPTY_OBJECT)
  const [ActivityLoading, setActivityLoading] = useState(true)

  useEffect(() => {
    activitiesService
      .fetchActivityDetails(activityId)
      .then((response) => {
        setActivity(response.event)
        setActivityPayment(response.payment)
        setActivityLoading(false)
      })
      .catch(() => {
        setActivity(EMPTY_OBJECT)
        setActivityLoading(false)
        toast.error("Error fetching activity details");
      });
  }, []);

  if (ActivityLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <PageHeader
        title={activity?.name}
        className="site-page-header"
        tags={[
          <Tag type={activity.is_active ? TAG_TYPES.SUCCESS : TAG_TYPES.DEFAULT}>
            {activity.is_active ? "Active" : "In-active"}
          </Tag>,
        ]}
        // extra={[
        //   <Button key="1" type={BUTTON_TYPES.PRIMARY}>
        //     Draft
        //   </Button>,
        // ]}
        avatar={{
          src: defaultUser,
        }}
      >
      </PageHeader>
      <div className={styles.pageContent}>
        <ActivityDetails
          data={activity}
          payment={activityPayment}
          activityId={activityId}
          loading={ActivityLoading}
        />
      </div>
    </div>
  );
}

export default Activity;
