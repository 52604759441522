import React from "react";

// Components
import GoogleMaps from "../../../../components/molecules/googleMaps";

// Styles
import styles from "./pitchLocation.module.scss";

function PackageLocation({ lat, lng }) {
  return (
    <div className={styles.container}>
      <GoogleMaps latitude={lat} longitude={lng} />
    </div>
  );
}

export default PackageLocation;
