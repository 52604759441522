import React from "react";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";

// Components
import { Title } from "../../../../../components/atoms/typography";
import Tabs from "../../../../../components/atoms/tabs";
import ScheduleDateOverrides from "../scheduleDateOverrides";

// Constants
import { EMPTY_ARRAY } from "../../../../../constants";

// Styles
import styles from "./scheduleOverrides.module.scss";

const { TabPane } = Tabs;

function ScheduleOverrides({ overrides, onChange, viewOnly }) {
  const handleUpdateDateOverrides = (dateOverrides) => {
    onChange(dateOverrides);
  };

  return (
    <div className={styles.container}>
      <Title className={styles.title}>Add weekly, date overrides</Title>
      <div className={styles.content}>
        <Tabs defaultActiveKey="dateOverrides" centered>
          <TabPane tab="Date Overrides" key="dateOverrides">
            <ScheduleDateOverrides
              overrides={overrides}
              onChange={handleUpdateDateOverrides}
              viewOnly={viewOnly}
            />
          </TabPane>
          <TabPane tab="Week Overrides" key="weekOverrides" disabled></TabPane>
        </Tabs>
      </div>
    </div>
  );
}

ScheduleOverrides.propTypes = {
  overrides: PropTypes.array,
  onChange: PropTypes.func,
  viewOnly: PropTypes.bool,
};

ScheduleOverrides.defaultProps = {
  overrides: EMPTY_ARRAY,
  onChange: _noop,
  viewOnly: false,
};

export default ScheduleOverrides;
