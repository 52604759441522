import React, { useEffect, useState } from "react";
import _map from "lodash/map";
import PropTypes from "prop-types";
import moment from "moment";

// Lodash
import _noop from "lodash/noop";

// Components
import { useForm } from "antd/lib/form/Form";
import Radio from "../../../../components/atoms/radio";
import Form from "../../../../components/atoms/form";
import Drawer from "../../../../components/atoms/drawer";
import Button from "../../../../components/atoms/button";
import { Input, TextArea } from "../../../../components/atoms/input";
import { GooglePlacesAutoComplete } from "../../../../components/molecules";
import Image from "../../../../components/atoms/image";
import DefaultImage from "../../../../assets/images/user.png";
import { Select, Checkbox } from "antd";


// Styles
import styles from "./createActivityForm.module.scss";
import { EMPTY_OBJECT } from "../../../../constants";

const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const renderSportsOptions = (option) => {
  return (
    <Select.Option key={`${option._id}@${option.name}`} value={`${option._id}@${option.name}`}>
      {option.name}
    </Select.Option>
  );
};

const renderFriendListOptions = ({ username, _id, profile_pic, firstName, lastName }) => {
  return (
    <Select.Option
      key={`${_id}@${username}`}
      value={`${_id}@${username}`}
    >
      <div className="demo-option-label-item">
        <Image
          width={15}
          height={15}
          src={profile_pic}
          fallback={DefaultImage}
        />
        {" "}
        {` ${firstName} ${lastName} - ${username}`}
      </div>
    </Select.Option>


  );
};

function CreateActivityForm({
  formValues,
  visible,
  onClose,
  title,
  sportsList,
  friendList,
  onFinish,
  loading,
  editMode,
  handleSelectedVenue,
  venuesOptions,
  photos,
}) {
  const [form] = useForm();
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [repeatedActivity, setRepeatedActivity] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const [dateStart, setDateStart] = useState("")

  const [VstartDate, setVstartDate] = useState("")
  const [VDateEnd, setVDateEnd] = useState("")
  const [btnEnable, setbtnEnable] = useState(true)

  const [addressValidation, setAddressValidation] = useState(false)

  useEffect(() => {
    form.setFieldsValue(formValues);
    setPaymentStatus(formValues?.fees > 0 ? true : false)
  }, [form, formValues]);

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onChangeDays = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const handleStartDate = (value) => {
    setDateStart(value)

    if (new Date(value) < new Date()) {
      setbtnEnable(false)
      setVstartDate('error')
    } else {
      setVstartDate('success')
      setVDateEnd("")
    }
  }

  const handleDateEnd = (value) => {

    let newDate = new Date()
    let futureDate = newDate.setFullYear(newDate.getFullYear() + 1);

    if (new Date(value) < new Date(dateStart)) {
      setVDateEnd('error')
      setbtnEnable(false)
    }
    else if (new Date(value) > futureDate) {
      setVDateEnd('error')
      setbtnEnable(false)
    }
    else {
      setbtnEnable(true)
      setVDateEnd('success')
    }
  }

  const submit = (data, form) => {
    data.repeated = repeatedActivity
    data.week = checkedList
    if (data?.location?.coordinates?.lat) {
      setAddressValidation(false)
      onFinish(data, form)
    }
    else setAddressValidation(true)
  };

  // const handleVenueOptions = (d) => {
  //   return (
  //     <Select.Option key={d._id} value={d._id}>
  //       {d.name}
  //     </Select.Option>
  //   );
  // };

  return (
    <Drawer title={title} visible={visible} onClose={onClose} width={400}>
      <div className={styles.container}>
        <Form
          form={form}
          initialValues={formValues}
          scrollToFirstError
          onFinish={(values) => submit(values, form)}
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input name!" }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
          <Form.Item
            name="sports_id"
            label="Sports Played"
            rules={[
              { required: true, message: "Please select sports played!" },
            ]}
          >
            <Select
              showSearch
              placeholder="Select sports played"
              className={styles.select}
            >
              {_map(sportsList, renderSportsOptions)}
            </Select>
          </Form.Item>
          <Form.Item
            label="gender"
            name="gender"
            rules={[{ required: true, message: "Please input gender!" }]}
          >
            <Select placeholder="Select gender" className={styles.select}>
              <Select.Option value="female">Female</Select.Option>
              <Select.Option value="male">Male</Select.Option>
              <Select.Option value="mixed">Mixed</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Description" name={"description"}>
            <TextArea placeholder="Enter description" />
          </Form.Item>
          <Form.Item
            label="Address"
            name="location"
            rules={[{ required: true, message: "Please enter the address" }]}
          >
            <GooglePlacesAutoComplete
              lat={formValues?.latitude}
              lng={formValues?.longitude}
              place={formValues?.complete_address}
            ></GooglePlacesAutoComplete>
          </Form.Item>
          <Form.Item
            name="repeated"
          >
            <Radio
              disabled={editMode}
              onChange={(e) => setRepeatedActivity(e.target.value)}
              items={[
                { name: "Single", value: false, },
                { name: "Repeated", value: true, },
              ]}
            />
          </Form.Item>
          {repeatedActivity ? <>
            <Form.Item
              label="Generation start date"
              name="custom_start_date"
              rules={[{ required: true, message: "Please select the activity start date" }]}
            >
              <Input
                type="date"
                min={moment().format("YYYY-MM-DD")}
                inputStatus={VstartDate}
                onChange={(e) => handleStartDate(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Generation end date"
              name="custom_end_date"
              rules={[{ required: true, message: "Please select the activity end date" }]}
            >
              <Input
                type="date"
                min={moment().format("YYYY-MM-DD")}
                inputStatus={VDateEnd}
                onChange={(e) => handleDateEnd(e.target.value)}
              />
            </Form.Item>

            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
              All Days
            </Checkbox>
            <br />
            <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChangeDays} />
            <br />
            <br />
          </>
            : <>
              <Form.Item
                label="Activity date"
                name="custom_event_date"
                rules={[{ required: true, message: "Please select the Activity date" }]}
              >
                <Input type="date" min={moment().format("YYYY-MM-DD")} />
              </Form.Item>
            </>
          }
          <Form.Item
            label="Activity start time"
            name="custom_start_time"
            rules={[{ required: true, message: "Please select the Activity start time" }]}
          >
            <Input placeholder="Enter promotion Activity start time" type="time" />
          </Form.Item>
          <Form.Item
            label="Activity end time"
            name="custom_end_time"
            rules={[{ required: true, message: "Please select the Activity end time" }]}
          >
            <Input placeholder="Enter promotion Activity end time" type="time" />
          </Form.Item>
          {!editMode &&
            <Form.Item
              name="invitations"
              label="Invitations"
            // rules={[
            //   { required: true, message: "Please select invitations!" },
            // ]}
            >
              <Select
                disabled={repeatedActivity}
                mode="multiple"
                placeholder="Select invitations"
                className={styles.select}
              >
                {_map(friendList, renderFriendListOptions)}
              </Select>
            </Form.Item>
          }
          <Form.Item
            label="No of players"
            name="no_of_players"
            rules={[{ required: true, message: "Please input no_of_players!" }]}
          >
            <Input type="number" placeholder="Enter no of players" />
          </Form.Item>
          <Form.Item
            label="No of team"
            name="no_of_team"
            rules={[{ required: true, message: "Please input no of team!" }]}
          >
            <Radio
              items={[
                { name: "1", value: 1, },
                { name: "2", value: 2, },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="status"
            rules={[{ required: true, message: "Please select" }]}
          >
            <Radio
              items={[
                { name: "Private", value: 'private', },
                { name: "Public", value: 'public', },
              ]}
            />
          </Form.Item>
          <Form.Item
            // label="No of team"
            name="paymentStatus"
          // rules={[{ required: true, message: "Please input no of team!" }]}
          >
            <Radio
              value={paymentStatus}
              onChange={(e) => setPaymentStatus(e.target.value)}
              items={[
                { name: "Free", value: false },
                { name: "Paid", value: true },
              ]}
            />
          </Form.Item>
          {paymentStatus &&
            <Form.Item
              label="Fees"
              name="fees"
              rules={[{ required: true, message: "Please input fees!" }]}
            >
              <Input type="number" min={2} placeholder="Enter fees" />
            </Form.Item>
          }
          {/* <Form.Item
            name="photo"
            label="Upload Photos"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              beforeUpload={true}
              listType="picture"
              maxCount="5"
              accept="image/png, .jpeg, .jpg, .webp"
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item> */}
          {/* <Form.Item
            name="is_active"
            label="Status"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select placeholder="Select status" className={styles.select}>
              <Select.Option value={true}>Active</Select.Option>
              <Select.Option value={false}>InActive</Select.Option>
            </Select>
          </Form.Item> */}
          {/* <Form.Item
            name="calendar_id"
            label="Calendar"
            rules={[{ message: "Please select a calendar!" }]}
          >
            <CalendarSelect />
          </Form.Item> */}
          {addressValidation ? "Address is required" : null}
          <Form.Item>
            <Button
              disabled={!btnEnable}
              label={editMode ? "Update" : "Save"}
              type="primary"
              htmlType="submit"
              loading={loading}
              className={styles.saveButton}
            />
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
}

CreateActivityForm.propTypes = {
  formValues: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  sportsList: PropTypes.array,
};

CreateActivityForm.defaultProps = {
  formValues: EMPTY_OBJECT,
  visible: false,
  onClose: _noop,
};

export default CreateActivityForm;
