import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { BASE_URL } from "../constants/services";

// Utils
import { getDataFromResponse } from "../utils";

const fetchSalesSummary = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/transactions/overview`,
  });

  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch(() => {
      toast.error("Failed to fetch sales summary");
    });
};

export default {
  fetchSalesSummary,
};
