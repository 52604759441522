// Lodash
import _property from "lodash/property";

const id = _property("_id");
const name = _property("name");
const description = _property("description");
const sport = _property("sport");
const venue = _property("venue_id");
const venueName = _property("venue_id.name");
const venueId = _property("venue_id._id");
const isActive = _property("is_active");
const photo = _property("image");
const calendar = _property("calendar_id");
const calendarId = _property("calendar_id._id");
const calendarName = _property("calendar_id.name");
const images = _property("image");

export default {
  id,
  name,
  description,
  sport,
  venue,
  venueName,
  isActive,
  calendar,
  calendarId,
  calendarName,
  venueId,
  photo,
  images,
};
