import React from "react";
import PropTypes from "prop-types";

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";

// Components
import { PageHeader as AntdPageHeader, Statistic, Row } from "antd";
import Button, { BUTTON_TYPES } from "../../atoms/button";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

function renderStatisticDetails({ title, value, styles }) {
  return <Statistic title={title} value={value} style={styles} key={title} />;
}
function renderAction({ title, handler = _noop, disabled, renderer = _noop }) {
  return (
    <Button
      type={BUTTON_TYPES.PRIMARY}
      onClick={handler}
      key={title}
      disabled={disabled}
    >
      {renderer() || title}
    </Button>
  );
}

function PageHeader({
  title,
  subTitle,
  statistics,
  actions,
  children,
  ...restProps
}) {
  return (
    <AntdPageHeader
      onBack={() => window.history.back()}
      title={title}
      subTitle={subTitle}
      extra={_map(actions, renderAction)}
      {...restProps}
    >
      <Row>{_map(statistics, renderStatisticDetails)}</Row>
      <Row>{children}</Row>
    </AntdPageHeader>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  statistics: PropTypes.array,
};

PageHeader.defaultProps = {
  subTitle: "",
  statistics: EMPTY_ARRAY,
};

export default PageHeader;
