import { createSlice } from "@reduxjs/toolkit";

// Lodash
import _findIndex from "lodash/findIndex";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
};

const calendarsSlice = createSlice({
  name: "calendars",
  initialState: INITIAL_STATE,
  reducers: {
    addCalendars: (state, action = {}) => {
      const { payload: calendars = EMPTY_ARRAY } = action;
      state.items = calendars;
    },
    deleteCalendar: (state, action = {}) => {
      const { payload: id = EMPTY_ARRAY } = action;
      let temp = state.items.filter(e => e._id !== id)
      state.items = temp;
    },
    updateCalendar: (state, action = {}) => {
      const { payload = {} } = action;
      const { _id } = payload;

      const matchingCalendarIndex = _findIndex(
        state.items,
        (item) => item._id === _id
      );
      state.items[matchingCalendarIndex] = { ...payload, id: _id };
    },
    addCalendar: (state, action = {}) => {
      const { payload: calendar = EMPTY_OBJECT } = action;
      state.items.push(calendar);
    },
  },
});

const { addCalendars, deleteCalendar, updateCalendar, addCalendar } = calendarsSlice.actions;
const calendarsReducer = calendarsSlice.reducer;

export { addCalendars, deleteCalendar, updateCalendar, addCalendar, calendarsReducer };
export default calendarsSlice;
