// Constants
import STATUS_TYPES from "./statusTypes";

const STATUS_TYPE_VS_LABEL = {
  [STATUS_TYPES.ACTIVE]: "Active",
  [STATUS_TYPES.INACTIVE]: "InActive",
  [STATUS_TYPES.DELETED]: "Deleted",
  [STATUS_TYPES.PAID]: "Paid",
  [STATUS_TYPES.DUE]: "Due"
};

export { STATUS_TYPE_VS_LABEL };
