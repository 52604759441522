import React from "react";
import PropTypes from "prop-types";

// Lodash
import _map from "lodash/map";
import _noop from "lodash/noop";

// Components
import ScheduleWeekDay from "../scheduleWeekDay";

// Constants
import { EMPTY_ARRAY } from "../../../../../constants";
import { DEFAULT_WEEKLY_SCHEDULE } from "../../constants/weeklySchedule";

// Styles
import styles from "./scheduleWeekView.module.scss";

function ScheduleWeekView({ schedule, onChange, viewOnly }) {
  const handleUpdateWeekDayTimeSlots = (index) => (newWeekDaySchedule) => {
    const updatedWeeklySchedule = [...schedule];
    updatedWeeklySchedule[index] = newWeekDaySchedule;
    onChange(updatedWeeklySchedule);
  };

  const renderWeekDaySlots = (daySchedule, index) => {
    return (
      <ScheduleWeekDay
        schedule={daySchedule}
        onChange={handleUpdateWeekDayTimeSlots(index)}
        viewOnly={viewOnly}
      />
    );
  };

  return (
    <div className={styles.container}>
      {_map(schedule, renderWeekDaySlots)}
    </div>
  );
}

ScheduleWeekView.propTypes = {
  schedule: PropTypes.array,
  onChange: PropTypes.func,
  viewOnly: PropTypes.bool,
};

ScheduleWeekView.defaultProps = {
  schedule: DEFAULT_WEEKLY_SCHEDULE,
  onChange: _noop,
  viewOnly: false,
};

export default ScheduleWeekView;
