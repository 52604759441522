import React from "react";

// Components
import Input from "../../atoms/input";
import { Title, Label } from "../../atoms/typography";
import { getSportName } from "../../../helpers";
import { Input as Input1, Select } from 'antd';

// styles
import styles from "./inputSelectView.module.scss";

const { TextArea } = Input1;

function InputSelectView({
  viewOnly,
  value,
  type = "",
  label,
  inputStatus,
  onChange,
  title = null,

  select = false,
  option = [],
  array = [],
  hint,
  textArea,
  placeholder,
}) {

  if (select) {
    title = title ? title : getSportName(value, array)
    return (
      <>
        {viewOnly
          ? title
            ? <div className={styles.timeDiv}>{label}<Title className={styles.scheduleNameViewOnly}>{title}</Title></div>
            : null
          :
          <div className={styles.timeDiv}>
            <Label>{label}</Label><br />
            <Select
              showArrow={false}
              className={`${styles.select}
                ${inputStatus === "error" ? styles.errorInput
                  : inputStatus === "warning" ? styles.warningInput
                    : inputStatus === "success" ? styles.successInput
                      : null
                }`}
              value={value}
              options={option}
              onChange={onChange}
              placeholder={placeholder}
              disabled={viewOnly}
            />
          </div>
        }
      </>
    )
  }
  else if (textArea) {
    return (
      <>
        {viewOnly
          ? title
            ? <div className={styles.timeDiv}>{label}<Title className={styles.scheduleNameViewOnly}>{title}</Title></div>
            : null
          : <div className={styles.scheduleTextArea}>
            <Label>{label}</Label><br />
            <TextArea
              rows={4}
              type={type}
              value={value}
              label={label}
              disabled={viewOnly}
              placeholder={placeholder}
              inputStatus={inputStatus}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        }
      </>
    );
  }
  else return (
    <>
      {viewOnly
        ? title
          ? <div className={styles.timeDiv}>{label}<Title className={styles.scheduleNameViewOnly}>{title}</Title></div>
          : null
        :
        <div className={styles.inputBox}>
          <Input
            type={type}
            value={value}
            label={label}
            disabled={viewOnly}
            placeholder={placeholder}
            inputStatus={inputStatus}
            className={styles.scheduleName}
            onChange={(e) => onChange(e.target.value)}
          />
          {hint
            ? <span className={styles.hint}>{hint}</span>
            : null
          }
        </div>
      }
    </>
  );
}


export default InputSelectView;
