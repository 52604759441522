import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";

// Components
import PromotionDetails from "./promotionDetails";
import CreatePromotionForm from "./createPromotionForm";
import PageHeader from "../../components/molecules/pageHeader";
import Table from "../../components/organisms/table";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../constants/pagination";

// Services
import promotionService from "../../services/promotionService";

// Redux
import { addPromotions } from "./redux/promotionSlice";

// Readers
import listResponseReader from "../../readers/listResponse";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";

// Helpers
import { createColumns } from "./helpers/columns";

// Styles
import styles from "./promotion.module.scss";

function Promotion() {
  const [totalPromotions, setTotalPromotions] = useState(0);
  const [selectedPromotionDetails, setSelectedPromotionDetails] = useState(EMPTY_OBJECT);

  const [paginationDetails, setPaginationDetails] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
  });

  const [searchText, setSearchText] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sortField, setsortField] = useState(undefined);
  const [order, setorder] = useState(undefined);

  const [showForm, setShowForm] = useState(false);

  const [promotionDetailsVisible, setPromotionDetailsVisibility] = useState(false);
  const dispatch = useDispatch();
  const promotions = useSelector((state) => state?.promotion?.items);
  const getPromotionsData = (pageNumber, pageSize, sortField, order) => {

    promotionService
      .fetchPromotions({
        pageNumber,
        pageSize,
        searchText,
        sortField,
        order,
      })
      .then((response) => {
        const promotions = listResponseReader.results(response);
        const totalpromotions = listResponseReader.totalResults(response);
        setTotalPromotions(totalpromotions);
        dispatch(addPromotions(promotions));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addPromotions(EMPTY_ARRAY));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const { pageNumber, pageSize } = paginationDetails;
    getPromotionsData(pageNumber, pageSize, sortField, order);
  }, [paginationDetails]);

  const handleOpenCreatePromotionForm = (promotionDetails) => {
    setEditMode(false);
    setShowForm(true);
    setSelectedPromotionDetails(promotionDetails);
  };

  const handleCloseCreatePromotionForm = () => {
    setEditMode(false);
    setShowForm(false);
    setSelectedPromotionDetails({});
  };

  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const handleClosePromotionDetails = () => {
    isEditMode && setEditMode(false);
    setPromotionDetailsVisibility(false);
    setSelectedPromotionDetails(EMPTY_OBJECT);
  };

  const handleShowPromotionDetails = (promotionDetails) => {
    setSelectedPromotionDetails(promotionDetails);
    setPromotionDetailsVisibility(true);
  };

  const handleOpenEditPromotionForm = (promotionDetails) => {
    setEditMode(true);
    setShowForm(true);
    setSelectedPromotionDetails(promotionDetails);
  };

  const handleOnInActive = (_id, is_active) => {
    let newpromotions = [...promotions]
    var newArr = _.map(newpromotions, function (promotion) {
      return promotion._id === _id ? { ...promotion, is_active, } : promotion;
    })

    promotionService
      .updatePromotion({ promotion_id: _id, is_active })
      .then((response) => {
        dispatch(addPromotions(newArr));
      })
      .catch((error) => {
        console.log(error);
      })
  };

  const handleDeletePromotion = (promotion) => {
    setDeleteLoading(true);
    promotionService
      .deletePromotion(promotion.id)
      .then((response) => {
        let removeData = promotions.filter((val) => val._id !== response.data._id)
        dispatch(addPromotions(removeData));
        toast.success("Promotion is deleted");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const columns = createColumns({
    onInActive: handleOnInActive,
    onEdit: handleOpenEditPromotionForm,
    onDelete: handleDeletePromotion,
    deleteLoading: deleteLoading,
  });

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
  };

  function onChange(pagination, filters, sorter, extra) {
    const field = sorter.order ? sorter.field : undefined
    const order = sorter.order
    setorder(order)
    setsortField(field)

    getPromotionsData(
      pagination.current,
      DEFAULT_PAGE_SIZE,
      field,
      order
    );
  }

  return (
    <>
      <div className="container">
        <PageHeader
          title="Promotions"
          statistics={[
            {
              title: "Total Promotions",
              value: totalPromotions,
            },
          ]}
          actions={[
            {
              title: showForm ? "Close form" : "+ New Promotion",
              handler: showForm ? handleCloseCreatePromotionForm : handleOpenCreatePromotionForm,
            },
          ]}
        />
        {!showForm ?
          <div className="card">
            <Table
              data={promotions}
              columns={columns}
              searchProps={{
                placeholder: "Search Promotions",
              }}
              onPaginationChange={handlePaginationChange}
              onSearch={handleSearch}
              paginationOptions={{
                current: paginationDetails.pageNumber,
                total: totalPromotions,
              }}
              onChange={onChange}
              onRowClick={handleShowPromotionDetails}
              loading={loading}
              onReload={handleReload}
            />
          </div>
          :
          <CreatePromotionForm
            // visible={showForm}
            onCloseForm={handleCloseCreatePromotionForm}
            promotionDetails={selectedPromotionDetails}
            isEditMode={isEditMode}
            onReload={handleReload}
          />
        }
      </div>
      <PromotionDetails
        promotionDetails={selectedPromotionDetails}
        visible={promotionDetailsVisible}
        onClose={handleClosePromotionDetails}
      />
    </>
  );
}

export default Promotion;
