import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import moment from "moment";

// Components
import ModalShowbookingHistory from "./components/modalShowbookingHistory";
import ModalAddEvent from "./components/modalAddEvent";
import ScheduleInput from "../../molecules/inputSelectView";
import Button from "../../atoms/button/Button";
import { toast } from "../../atoms/toaster";

// Helper
import { convertToFormData } from "../../../utils/globle";

// Services
import CalendarService from "../../../services/calendarService";

//Redux
import { addCalendar } from "../../../pages/calendars/redux/calendarSlice";

// Styles
import styles from "./calendarView.module.scss";

function CalendarView({
  pitchName,
  name,
  selectedCalendarId = null,
  setCalendarId,
  pitchId,
}) {
  const dispatch = useDispatch();

  const [calendarName, setName] = useState("")
  const [loading, setLoading] = useState(false)
  const [viewOnlyTitle, setviewOnlyTitle] = useState(false)
  const [viewOnly, setviewOnly] = useState(false)
  const [actionLabel, setActionLabel] = useState("Create calendar");
  const [events, setEvents] = useState([
    {
      // title: "fill all",
      // start: '2022-07-29T12:30:00Z',
      // end: '2022-07-30',
      // allDay: false

      //   // Capacity: "11",
      //   // Description: "zxcv",
      //   // Price: "12",
      //   // Sport: "623f4e992559522ba012744e",
      // date: "2022-07-15",
      //   // endDate: "2022-07-15",
      //   // endTime: "16:24",
      //   // startTime: "15:24",/////if you put this its show in all of them 
    }
  ])
  const [isModalBooking, setIsModalBooking] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [eventData, seteventData] = useState(null);
  const [Repeatedly, setRepeatedly] = useState(false);


  useEffect(() => {
    setName(name)
    // setEvents(slots)
    if (selectedCalendarId) {
      setviewOnlyTitle(true)
      setActionLabel("Edit name")
      getTimeSlots()
    } else {
      setName(pitchName)
      setviewOnlyTitle(false)
      setActionLabel("Create calendar")
    }
  }, [selectedCalendarId]);



  const getTimeSlots = (date) => {
    let start = moment().startOf('month').format('YYYY-MM-DD');
    let end = moment().endOf('month').format('YYYY-MM-DD');
    if (date?.startStr && date?.endStr) {
      start = moment(date?.startStr).format('YYYY-MM-DD')
      end = moment(date?.endStr).format('YYYY-MM-DD')
    }

    setLoading(true);
    CalendarService
      .fetchAllSlots(selectedCalendarId, start, end)
      .then((response) => {
        response.map(item => {
          item.id = item._id
          if (item.taken >= item.capacity) {
            item.classNames = [styles.slotFullyBook, styles.slotBar]
            item.textColor = "#28c76f"
            item.editable = false
          }
          else if (item.taken > 0) {
            item.classNames = [styles.slotHalfBook, styles.slotBar]
            item.textColor = "#007bff"
            item.editable = false
          }
          else {
            item.classNames = [styles.slotNotBook, styles.slotBar]
            item.textColor = "#ff9f43"
          }

          if (!item.is_active) {
            item.classNames = [styles.slotReported, styles.slotBar]
            item.textColor = "#eb2026"
          }
        })

        setEvents(response)
      })
      .catch((error) => {
        console.log(error);
        // dispatch(addCalendars(EMPTY_ARRAY));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleNameChange = (value = false) => {
    setviewOnlyTitle(value)
    setActionLabel("Update name")
  };

  const handleActionButtonClick = () => {
    let payload = {
      calendar_id: selectedCalendarId,
      name: calendarName,
      pitch_id: pitchId,
    }
    if (selectedCalendarId) {
      handleNameChange()
      if (!viewOnlyTitle) {
        setLoading(true)
        CalendarService
          .updateCalendars(payload)
          .then((response) => {
            setviewOnlyTitle(true)
            setActionLabel("Edit")
            toast.success("Updated Successfully");
            setLoading(false)
          })
      }
    } else {
      setLoading(true)
      CalendarService
        .createCalendar(payload)
        .then((response) => {
          setCalendarId(response._id)
          setLoading(false)
          toast.success("Calendar Created Successfully");
          dispatch(addCalendar(response));
        })
    }
  }

  function renderEventContent(eventInfo) {
    // let { taken, capacity, is_active } = eventInfo?.event?.extendedProps

    return (
      <div className={styles.slot}>
        <div>
          {/* <i>{eventInfo.event.extendedProps}</i> */}
          <p>{eventInfo.event.title}</p>
        </div>
      </div>
    )
  }

  const showModal = (e, viewAble, repeatedly = false) => {
    setviewOnly(viewAble)
    seteventData(e)
    setIsModalVisible(true)
    setRepeatedly(repeatedly)
  }

  const onSaveEvent = (item) => {
    setLoading(true)
    // if (item?.sportList[0]?.sport === "") delete item.sportList

    item.calendar_id = selectedCalendarId
    if (pitchId) item.pitch_id = pitchId

    item.start = moment(item.date_start + " " + item.time_start).format()
    item.end = moment(item.date_end + " " + item.time_end).format()

    var data = convertToFormData(item)
    CalendarService
      .createSlot(data)
      .then((response) => {

        if (Repeatedly) {
          response.map(item => {
            item.id = item._id
            item.classNames = [styles.slotNotBook, styles.slotBar]
            item.textColor = "#ff9f43"
          })
          setEvents([...events, ...response])
        } else {
          response.classNames = [styles.slotNotBook, styles.slotBar]
          response.textColor = "#ff9f43"
          setEvents([...events, response])
        }
        toast.success("Added Successfully");

      })
      .catch((error) => {
        toast.error(error?.response?.data?.error || "Unsuccessfully try again");
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const onEditEvent = (item) => {
    setLoading(true)

    if (item?.sportList[0]?.sport === "") delete item.sportList
    if (typeof (item.photo) === "string") delete item.photo

    var data = convertToFormData(item)

    CalendarService
      .updateSlot(data)
      .then((response) => {
        toast.success("Update Successfully");
        let tempEvent = events.filter(event => event._id !== item.slot_id)
        response.classNames = [styles.slotNotBook, styles.slotBar]
        response.textColor = "#ff9f43"
        setEvents([...tempEvent, response])
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error || "Unsuccessfully try again");
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const handleDeleteEvent = (eventId) => {
    let temp = events.filter(e => e._id !== eventId)
    setEvents(temp)
  }

  const handleReservedEvent = (response) => {
    let tempEvent = events.filter(event => event._id !== response._id)

    if (response.taken >= response.capacity) {
      response.classNames = [styles.slotFullyBook, styles.slotBar]
      response.textColor = "#28c76f"
      response.editable = false
    }
    else if (response.taken > 0) {
      response.classNames = [styles.slotHalfBook, styles.slotBar]
      response.textColor = "#007bff"
      response.editable = false
    }
    else {
      response.classNames = [styles.slotNotBook, styles.slotBar]
      response.textColor = "#ff9f43"
    }

    if (!response.is_active) {
      response.classNames = [styles.slotReported, styles.slotBar]
      response.textColor = "#eb2026"
    }

    setEvents([...tempEvent, response])
  }

  const onEventDrageChange = (item) => {
    let values = {}

    values.slot_id = item.event.extendedProps._id
    values.date_start = moment(item.event.startStr).format("YYYY-MM-DD")
    values.date_end = item.event.endStr
      ? moment(item.event.endStr).format("YYYY-MM-DD")
      : moment(item.event.startStr).format("YYYY-MM-DD")

    values.start = item.event.startStr
    values.end = item.event.endStr ? item.event.endStr : item.event.startStr

    values.time_start = moment(item.event.startStr).format("hh:mm")
    if (item.event.endStr) values.time_end = moment(item.event.endStr).format("hh:mm")

    var data = convertToFormData(values)

    CalendarService
      .updateSlot(data)
      .then((response) => {
        toast.success("Update Successfully");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error || "Unsuccessfully try again");
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return (
    <div className={styles.container}>

      <div className={styles.calendarName}>
        <ScheduleInput
          title={calendarName}
          viewOnly={viewOnlyTitle}
          label="Calendar name"
          onChange={(e) => setName(e)}
          value={calendarName}
        // inputStatus={""}
        // hint="Ex: This event for basketball"
        />

        <Button
          onClick={showModal}
          className={styles.actionButton}
        // loading={loading}
        >
          + Activity
        </Button>
        <Button
          onClick={() => showModal({}, false, true)}
          className={styles.actionButton}
        // loading={loading}
        >
          + Repeated activity
        </Button>
        <Button
          onClick={handleActionButtonClick}
          className={styles.actionButton}
          loading={loading}
        >
          {actionLabel}
        </Button>
      </div>

      {selectedCalendarId ?
        <FullCalendar
          events={events}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          dateClick={(e) => showModal(e, false)}
          eventClick={(e) => showModal(e.event, true)}
          eventContent={renderEventContent}
          datesSet={getTimeSlots}
          // allDaySlot={true}
          // droppable={false}
          editable={true}
          eventDrop={onEventDrageChange}
          eventDisplay="block"
          eventResize={onEventDrageChange}
        // drop={this.drop}
        // eventDragStart={console.log("eventDragStart")}
        // eventDragStop={console.log("eventDragStop")}
        // weekends={false}
        />
        : null}

      <ModalAddEvent
        viewOnly={viewOnly}
        Repeatedly={Repeatedly}
        onAdd={onSaveEvent}
        onUpdate={onEditEvent}
        onCheckBooking={() => setIsModalBooking(true)}
        handleDeleteEvent={handleDeleteEvent}
        handleReservedEvent={handleReservedEvent}
        handleEditEvent={() => setviewOnly(false)}
        isModalVisible={isModalVisible}
        eventData={eventData}
        onClose={() => setIsModalVisible(false)}
      />

      <ModalShowbookingHistory
        isModalVisible={isModalBooking}
        onClose={() => setIsModalBooking(false)}
        slotId={eventData?.extendedProps?._id}
      />

    </div>
  );
}

export default CalendarView;
