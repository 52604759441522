import moment from "moment";

// Constants
import { EMPTY_TIME_SLOT } from "./timeSlots";

const DEFAULT_OVERRIDE = {
  date: moment().startOf("day").valueOf(),
  timeSlots: [EMPTY_TIME_SLOT],
};

export { DEFAULT_OVERRIDE };
