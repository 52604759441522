const LIST_VIEW = {
  label: "List View",
  value: "listView",
};

const CALENDAR_VIEW = {
  label: "Calendar View",
  value: "calendarView",
};

const VIEWS = [LIST_VIEW, CALENDAR_VIEW];

export default VIEWS;

export { LIST_VIEW, CALENDAR_VIEW };
