const PACKAGES = {
  id: "PACKAGES",
  name: "Packages",
};

const PITCHES = {
  id: "PITCHES",
  name: "Pitches",
};

const ACTIVITIES = {
  id: "ACTIVITIES",
  name: "Activities",
};

const ACTIVITY_DETAILS = {
  id: "ACTIVITY_DETAILS",
  name: "activity",
};

const PITCH_DETAILS = {
  id: "PITCH_DETAILS",
  name: "Pitch",
};

const LOGIN = {
  id: "LOGIN",
  name: "Login",
};

const PACKAGE_DETAILS = {
  id: "PACKAGE_DETAILS",
  name: "Package",
};

const SCHEDULES = {
  id: "SCHEDULES",
  name: "Schedules",
};

const CALENDARS = {
  id: "CALENDARS",
  name: "Calendars",
};

const SALES = {
  id: "SALES",
  name: "Sales",
};

const PROFILE = {
  id: "profile",
  name: "Profile",
};

const POSTS = {
  id: "posts",
  name: "Posts",
};

const PROMOTION = {
  id: "promotion",
  name: "Promotion",
};


const MODULES = {
  PACKAGES,
  PITCHES,
  PITCH_DETAILS,
  LOGIN,
  PACKAGE_DETAILS,
  SCHEDULES,
  CALENDARS,
  SALES,
  ACTIVITIES,
  ACTIVITY_DETAILS,
  PROFILE,
  POSTS,
  PROMOTION,
};

export default MODULES;
