import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// Lodash
import _noop from "lodash/noop";

// Components
import Input from "../../../../atoms/input";
import { DeleteOutlined } from "../../../../atoms/icon";
import { Label, Title } from "../../../../atoms/typography";
import { Select } from 'antd';

// Helpers
import { createSportOptions } from "../../../../../helpers";

// Constants
import { EMPTY_ARRAY } from "../../../../../constants/base.constants";

// Styles
import styles from "./scheduleSportPrice.module.scss";

function ScheduleSportPrice({
  className,
  onDelete,
  sportId,
  onChange,
  price,
  capacity,
  deleteDisabled,
  viewOnly,
  sportName,
}) {

  const sports = useSelector((state) => state?.sports?.venues);

  const sportOptions = createSportOptions(sports);
  const handleSelectSportChange = (selectedSportId) => {
    onChange({
      id: selectedSportId,
      price,
      capacity,
    });
  };

  const handlePriceChange = (event) => {
    onChange({
      id: sportId,
      price: event?.target?.value,
    });
  };

  const handleCapacityChange = (event) => {
    onChange({
      id: sportId,
      price: price,
      capacity: event?.target?.value,
    });
  };

  if (viewOnly) {
    return (
      <div className={`${styles.viewOnlycontainer} ${className}`}>
        <Label className={styles.label}>{sportName}</Label>
        <Title>AED {price}</Title>
        <Label>Capacity {capacity}</Label>
      </div>
    );
  }

  return (
    <div className={`${styles.container} ${className}`}>
      <Select
        className={styles.select}
        options={sportOptions}
        value={sportId}
        onChange={handleSelectSportChange}
      />
      <Input
        type="number"
        className={styles.input}
        onChange={handlePriceChange}
        value={price}
        placeholder='price'
      />
      <Input
        type="number"
        className={styles.input}
        onChange={handleCapacityChange}
        value={capacity}
        placeholder='capacity'
      />
      {!deleteDisabled && (
        <DeleteOutlined className={styles.actionIcon} onClick={onDelete} />
      )}
    </div>
  );
}

ScheduleSportPrice.propTypes = {
  sportId: PropTypes.string,
  price: PropTypes.number,
  capacity: PropTypes.number,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  deleteDisabled: PropTypes.bool,
  viewOnly: PropTypes.bool,
  sportName: PropTypes.string,
};

ScheduleSportPrice.defaultProps = {
  sportId: null,
  price: null,
  capacity: null,
  onDelete: _noop,
  onChange: _noop,
  deleteDisabled: false,
  viewOnly: false,
  sportName: null,
};

export default ScheduleSportPrice;
