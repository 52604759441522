import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// Components
import Routes from "./routes";
import { ToastContainer } from "./components/atoms/toaster";

// Store
import { store } from "./redux";

// Styles
import "./App.css";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer />
        <Routes />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
