const createPageHeaderProps = ({ title, count, actionHandler, actionDisabled }) => {
  return {
    title,
    statistics: [
      {
        title: "Total Packages",
        value: count,
      },
    ],
    actions: [
      {
        title: "+ New Package",
        handler: actionHandler,
        disabled: actionDisabled
      },
    ],
  };
};

export { createPageHeaderProps };
