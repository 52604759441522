import React from "react";

// Lodash
import _map from "lodash/map";
import _join from "lodash/join";

// Components
import { Row } from "antd";
import Tabs from "../../../../components/atoms/tabs";
import { Title, Label } from "../../../../components/atoms/typography";
import PackageLocation from "../activityLocation/ActivityLocation";
import PurchaseHistory from "../purchaseHistory/PurchaseHistory";
import Invitations from "../invitations";
import moment from "moment";

// Styles
import styles from "./activityDetails.module.scss";

const { TabPane } = Tabs;

function ActivityDetails({ activityId, payment, data, }) {
  const handleTabChange = () => { };

  const {
    address,
    createdAt,
    description,
    eventDate,
    event_time_slot,
    fees,
    gender,
    invitations,
    is_active,
    is_complete,
    name,
    no_of_players,
    no_of_team,
    sports_id,
    status,
    updatedAt,
    user_id,
  } = data


  return (
    <div className={styles.container}>
      <Tabs onChange={handleTabChange} type="card">
        <TabPane tab="About" key="about">
          <Row className={styles.aboutContainer}>
            <div className={styles.packageDetails}>
              {description ? <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Description</Title>
                <Label className={styles.detailValue}>{description}</Label>
              </div> : null}
              <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Players</Title>
                <Label className={styles.detailValue}>{no_of_players}</Label>
              </div>
              <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Team</Title>
                <Label className={styles.detailValue}>{no_of_team}</Label>
              </div>
              <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Date</Title>
                <Label className={styles.detailValue}>{moment(address?.custom_event_date).format("DD MMMM, YYYY")}</Label>
              </div>
              <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Address</Title>
                <Label className={styles.detailValue}>{address?.complete_address}</Label>
              </div>
              <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Fees</Title>
                <Label className={styles.detailValue}>AED {fees}</Label>
              </div>
              <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Gender</Title>
                <Label className={styles.detailValue}>{gender}</Label>
              </div>
              <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Status</Title>
                <Label className={styles.detailValue}>{status}</Label>
              </div>
              <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>User Name</Title>
                <Label className={styles.detailValue}>{user_id.firstName} {user_id.lastName}</Label>
              </div>
              <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Sports</Title>
                <Label className={styles.detailValue}>
                  {sports_id?.name}
                </Label>
              </div>
              {/* <div className={styles.packageDetail}>
                <Title className={styles.detailTitle}>Invitations</Title>
                {invitations?.map(v => (
                  <Label className={styles.detailValue}>{v.recevier_id?.firstName} {v.recevier_id?.lastName} - ({v.status})</Label>
                ))}
              </div> */}
            </div>
          </Row>
        </TabPane>
        <TabPane tab="Invitations" key="invitations">
          <Invitations data={invitations} />
        </TabPane>
        <TabPane tab="Purchase History" key="purchaseHistory">
          <PurchaseHistory data={payment} />
        </TabPane>
        <TabPane tab="Location" key="location">
          <PackageLocation address={address} />
        </TabPane>
        {/* <TabPane tab="Images" key="images">
          <Album images={photos} />
        </TabPane> */}
      </Tabs>
    </div>
  );
}

export default ActivityDetails;
