// Lodash
import _isEmpty from "lodash/isEmpty";


const getID = (response = [], isArray = true) => {
  if (isArray) {
    return response.map(item => {
      if (item.includes("@"))
        return item.substring(0, item.indexOf("@"))
      else return item
    })
  }
  else {
    if (response.includes("@"))
      return response.substring(0, response.indexOf("@"))
  }
}

const vat_calu = (val, vat = 5) => {
  const amount = Number(val);
  const per = vat
  let result = (amount * per) / 100
  return Number(result).toFixed(2);
}

const roundUp = (val) => {
  return Math.ceil(val * 100) / 100
}

const convertToFormData = (payload) => {
  const data = new FormData();

  Object.keys(payload).map(function (key) {
    let value = payload[key]
    if (key === "photo" && !_isEmpty(value)) {
      let photo = value[0]
      if (photo.originFileObj && photo.name) {
        data.append("photo", photo.originFileObj, photo.name);
      }
    }
    else if (key === "sportList") {
      value.forEach(item => data.append(`sportList[]`, JSON.stringify(item)))
    }
    else if (value) data.append(key, value);

  });

  return data
}

export { getID, vat_calu, roundUp, convertToFormData };

