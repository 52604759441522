import React, { useState, useEffect } from 'react';
import { Divider, Tag } from 'antd';
import Drawer from "../atoms/drawer";
import moment from "moment";

// Lodash
import _map from "lodash/map";

// Components
import Image from "../atoms/image";
import { Title, Label } from "../atoms/typography";
import DefaultFreelancerImage from "../../assets/images/user.png";
import Status, { STATUS_TYPES } from "../molecules/status";
import Loader from "../molecules/loader/Loader";

// Services
import UserService from "../../services/userService";

// Styles
import styles from "./popupUserDetail.module.scss";

const PopupUserDetail = ({ show, close, userId }) => {

    const [userData, setUserData] = useState({})
    const [Loadion, setLoadion] = useState(true)

    useEffect(() => {
        setLoadion(true)
        if (userId) {
            UserService
                .fetchUserProfile(userId)
                .then((response) => {
                    // console.log(response, 'xxx')
                    setUserData(response)
                    setLoadion(false)
                })
                .catch((error) => {
                    console.log(error, 'error')
                })
        }
    }, [userId]);

    const { profile_pic, firstName, lastName, email, dob, contact_no, role, favorite_sports, appVersion, is_active, is_delete } = userData

    const status = is_active
        ? STATUS_TYPES.ACTIVE
        : STATUS_TYPES.INACTIVE;

    return (
        <Drawer
            title="User Details"
            visible={show}
            onClose={close}
            width={420}
        >
            {Loadion
                ? <Loader />
                :
                <div className={styles.container}>
                    <div className={styles.profileImage}>
                        <Image
                            width={160}
                            height={160}
                            src={profile_pic}
                            fallback={DefaultFreelancerImage}
                        />
                    </div>
                    <div className={styles.profileName}>
                        <Title>{firstName + " " + lastName}</Title>
                        <Label>
                            <Status status={status} />
                        </Label>
                    </div>
                    <Divider>Details</Divider>
                    <div className={styles.profileDetails}>

                        {is_delete ?
                            <div className={styles.detail}>
                                <Tag className="mt-1" color="red">Deleted</Tag>
                            </div>
                            : null}

                        <div className={styles.detail}>
                            <Title className={styles.detailTitle}>Email</Title>
                            <Label>{email}</Label>
                        </div>
                        <div className={styles.detail}>
                            <Title className={styles.detailTitle}>Role</Title>
                            <Label>{role}</Label>
                        </div>
                        {dob ? <div className={styles.detail}>
                            <Title className={styles.detailTitle}>Date of Birth</Title>
                            <Label>{moment(dob).format("DD MMMM YYYY")}</Label>
                        </div> : null}
                        <div className={styles.detail}>
                            <Title className={styles.detailTitle}>Contact</Title>
                            <Label>{contact_no || "Not define"}</Label>
                        </div>

                        {favorite_sports?.length > 0 ? <div className={styles.detail}>
                            <Title className={styles.detailTitle}>Favorite sports</Title>
                            {_map(favorite_sports, (sport) =>
                                <Tag className="mt-1" color="volcano">{sport?.sport_id?.name}</Tag>
                            )}
                        </div> : null}

                        {appVersion?.version_android || appVersion?.version_ios ?
                            <div className={styles.detail}>
                                <Title className={styles.detailTitle}>App Version</Title>
                                {appVersion?.version_android ? <><Label>Android: {appVersion?.version_android}</Label><br></br></> : null}
                                {appVersion?.version_ios ? <Label>IOS: {appVersion?.version_ios}</Label> : null}
                            </div>
                            : null}

                    </div>
                </div>
            }
        </Drawer>
    )
}

export default PopupUserDetail;
