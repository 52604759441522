import React, { useState } from "react";
import PropTypes from "prop-types";

// Lodash
import _noop from "lodash/noop";

// Components
import Modal from "../../../../components/atoms/modal";

// Constants
import { EMPTY_OBJECT } from "../../../../constants";
import { MODAL_TITLE, OK_BUTTON_TEXT } from "./constants";

// Styles
import styles from "./salesfilters.module.scss";

function SalesFilters({ defaultFilters, visible, onOk, onCancel }) {
    const [filters, setFilters] = useState(defaultFilters);

  const handleApplyFilters = () => {
    onOk(filters);
  };

  return (
    <Modal
      title={MODAL_TITLE}
      okText={OK_BUTTON_TEXT}
      visible={visible}
      onOk={handleApplyFilters}
      onCancel={onCancel}
      centered
    ></Modal>
  );
}

SalesFilters.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  defaultFilters: PropTypes.object,
};

SalesFilters.defaultProps = {
  visible: false,
  onOk: _noop,
  onCancel: _noop,
  defaultFilters: EMPTY_OBJECT,
};

export default SalesFilters;
