import React from "react";
import PropTypes from "prop-types";

// Lodash
import _map from "lodash/map";

// Components
import Image from "../../../components/atoms/image";

// constants
import { EMPTY_ARRAY } from "../../../constants";

// Styles
import styles from "./album.module.scss";

function Album({ images }) {
  const renderImage = (url) => {
    return (
      <div className={styles.image}>
        <Image src={url} height={200} width={200}/>
      </div>
    );
  };
  return <div className={styles.container}>{_map(images, renderImage)}</div>;
}

Album.propTypes = {
  images: PropTypes.array,
};

Album.defaultProps = {
  images: EMPTY_ARRAY,
};

export default Album;
