import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

// Components
import { ConfigProvider, Row, Col } from "antd";
import Table from "../../../../components/organisms/table";
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../../constants/pagination";
import { Title, Label } from "../../../../components/atoms/typography";
import PopupUserDetail from "../../../../components/custom/PopupUserDetail";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../../constants";

// Helpers
import { createColumns } from "./helpers/columns";

// Readers
import listResponseReader from "../../../../readers/listResponse";

// Redux
import {
  setPurchaseHistory,
  setCalendarPurchaseHistoryLoading,
  setCalendarBookingsSummary,
} from "../../redux/pitchSlice";

// Services
import CalendarsService from "../../../../services/calendarService";

// Styles
import styles from "./bookingHistory.module.scss";

// Assets
import transactionImg from "../../../../assets/images/transaction.png";

function Empty({ description }) {
  return (
    <div className={styles.container}>
      <img src={transactionImg} alt={description} height={120} width={120} />
      <p>{description}</p>
    </div>
  );
}

function BookingHistory({ calendarId, slotId }) {
  const dispatch = useDispatch();
  const [totalBookings, setTotalBookings] = useState(0);
  const [paginationDetails, setPaginationDetails] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
  });
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user_id, setUser_id] = useState(null);

  const bookings = useSelector(
    (state) => state?.pitch?.purchaseHistory?.items
  );
  const loading = useSelector(
    (state) => state?.pitch?.purchaseHistory?.loading
  );
  const bookingsSummary = useSelector(
    (state) => state?.pitch?.bookingsSummary
  );
  useEffect(() => {
    dispatch(setCalendarPurchaseHistoryLoading(true));
    CalendarsService
      .fetchCalendarPurchaseHistory(calendarId, slotId, searchText)
      .then((response) => {
        const bookings = listResponseReader.results(response);
        const totalBookings = listResponseReader.totalResults(response);
        const bookingsSummary = listResponseReader.revenue(response);
        setTotalBookings(totalBookings);
        dispatch(setPurchaseHistory(bookings));
        dispatch(setCalendarBookingsSummary(bookingsSummary));
      })
      .catch((error) => {
        dispatch(setPurchaseHistory(EMPTY_ARRAY));
        dispatch(setCalendarBookingsSummary(EMPTY_OBJECT));
      });
  }, [paginationDetails]);

  const columns = createColumns({
    showUser: (data) => handleShowUser(data),
  });

  const handleShowUser = (data) => {
    setIsModalOpen(true)
    setUser_id(data._id)
  };


  const handlePaginationChange = (newPageNumber, newPageSize) => {
    setPaginationDetails({
      pageNumber: newPageNumber,
      pageSize: newPageSize,
    });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setPaginationDetails({
      pageNumber: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const handleReload = () => {
    setPaginationDetails({ ...paginationDetails });
  };

  return (
    <div className={styles.container}>
      <ConfigProvider
        renderEmpty={() => <Empty description="No transactions found" />}
      >
        <Row className={styles.purchaseAnalytics}>
          <Col className={styles.calendarDetail}>
            <Title className={styles.detailTitle}>Total Bookings</Title>
            <Label className={styles.detailValue}>{totalBookings || "0"}</Label>
          </Col>
          <Col className={styles.calendarDetail}>
            <Title className={styles.detailTitle}>Total Gross Sale</Title>
            <Label className={styles.detailValue}>
              {bookingsSummary?.total_sale || "0"}
            </Label>
          </Col>
          <Col className={styles.calendarDetail}>
            <Title className={styles.detailTitle}>Dudi Commission</Title>
            <Label className={styles.detailValue}>
              {bookingsSummary?.dudi_commission || "0"}
            </Label>
          </Col>

          <Col className={styles.calendarDetail}>
            <Title className={styles.detailTitle}>Net Profit</Title>
            <Label className={styles.detailValue}>
              {bookingsSummary?.total_profit || "0"}
            </Label>
          </Col>
        </Row>
        <Table
          data={bookings}
          columns={columns}
          searchProps={{
            placeholder: "Search Purchase History",
          }}
          onPaginationChange={handlePaginationChange}
          onSearch={handleSearch}
          paginationOptions={{
            current: paginationDetails.pageNumber,
            total: totalBookings,
          }}
          loading={loading}
          onReload={handleReload}
        />
      </ConfigProvider>
      <PopupUserDetail
        show={isModalOpen}
        close={() => setIsModalOpen(false)}
        userId={user_id}
      />
    </div>
  );
}

BookingHistory.propTypes = {
  calendarId: PropTypes.string.isRequired,
};

BookingHistory.defaultProps = {};

export default BookingHistory;
