import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _map from "lodash/map";

// Services
import CalendarService from "../../../../../services/calendarService";
import { createSportOptions } from "../../../../../helpers";

// Components
import Modal from "../../../../atoms/modal";
import ScheduleInput from "../../../../molecules/inputSelectView";
import Button from "../../../../atoms/button/Button";
import ViewSlotDetail from "../ViewSlotDetail";
import { DeleteOutlined, PlusOutlined, ClearOutlined, UploadOutlined, EditOutlined, CloseCircleOutlined } from "../../../../atoms/icon";
import { toast } from "../../../../atoms/toaster";
import { Upload, Checkbox } from "antd";
import { Label } from "../../../../atoms/typography";

// Styles
import styles from "./modalAddEvent.module.scss";
import moment from "moment";


const CheckboxGroup = Checkbox.Group;
const plainOptions = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const defaultCheckedList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri',];

function ScheduleWeekView({
  isModalVisible,
  onClose,
  eventData,
  viewOnly,
  Repeatedly = false,
  onAdd,
  onUpdate,
  handleDeleteEvent,
  handleEditEvent,
  onCheckBooking,
  handleReservedEvent,
}) {
  const defaultSportList = [{
    price: "",
    sport: null,
  }]

  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const [loading, setLoading] = useState(false)
  const [sportList, setSportList] = useState(defaultSportList)
  const [slot_id, setSlot_id] = useState(null)
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")

  const [timeStart, setTimeStart] = useState("")
  const [timeEnd, setTimeEnd] = useState("")
  const [VTimeEnd, setVTimeEnd] = useState("")

  const [dateStart, setDateStart] = useState("")
  const [dateEnd, setDateEnd] = useState("")

  const [VstartDate, setVstartDate] = useState("")
  const [VDateEnd, setVDateEnd] = useState("")

  const [allowModify, setAllowModify] = useState("")
  const [capacity, setCapacity] = useState("")
  const [VCapacity, setVCapacity] = useState("")
  const [VTitle, setVTitle] = useState("")
  const [VSport, setVSport] = useState("")
  const [VPrice, setVPrice] = useState("")

  const [photo, setPhoto] = useState([])

  const [btnEnable, setbtnEnable] = useState(true)

  const sports = useSelector((state) => state?.pitch?.pitch?.details?.sport);
  const allSports = useSelector((state) => state?.sports?.items);
  // const venuesSports = useSelector((state) => state?.sports?.venues);
  const sportOptions = createSportOptions(sports || allSports);

  useEffect(() => {
    if (viewOnly) {
      // console.log(eventData.extendedProps, 'eventData.extendedProps')
      const { capacity, description, sportList, time_start, time_end, date_start, date_end, photo, _id, taken } = eventData.extendedProps

      setSlot_id(_id)
      setTitle(eventData.title)
      setDescription(description)
      setTimeStart(time_start)
      setTimeEnd(time_end)
      setDateStart(date_start)
      setDateEnd(date_end)
      setSportList(sportList)
      setCapacity(capacity)
      setPhoto(photo)
      setAllowModify(taken > 0)

    } else {
      setSlot_id(null)
      // console.log(eventData?.dateStr, 'eventData?.dateStr')
      setDateStart(eventData?.dateStr)
      setDateEnd(eventData?.dateStr)
      let getHours = Number(moment().format("HH"))
      setTimeStart(`${getHours}:00`)
      setTimeEnd(`${getHours + 2}:00`)
      setTitle(null)
      setDescription(null)
      setSportList(defaultSportList)
      setCapacity(null)
      setPhoto(null)
    }

    if (Repeatedly) setCheckedList(defaultCheckedList)
    else setCheckedList([])

  }, [eventData])

  const handleStartDate = (value) => {
    setDateStart(value)
    setDateEnd(value)
    if (new Date(value) < new Date()) {
      setbtnEnable(false)
      setVstartDate('error')
    } else {
      setVstartDate('success')
      setVDateEnd("")
    }
  }

  const handleDateEnd = (value) => {
    setDateEnd(value)
    let newDate = new Date()
    let futureDate = newDate.setFullYear(newDate.getFullYear() + 1);

    if (new Date(value) < new Date(dateStart)) {
      setVDateEnd('error')
      setbtnEnable(false)
    }
    else if (new Date(value) > futureDate) {
      setVDateEnd('error')
      setbtnEnable(false)
    }
    else {
      setbtnEnable(true)
      setVDateEnd('success')
    }
  }

  const handleTimeStart = (value) => {
    setTimeStart(value)
    setTimeEnd(value)
    setVTimeEnd("")
  }

  const handleTimeEnd = (value) => {
    setTimeEnd(value)
    const s = new Date('2022-01-01 ' + timeStart);
    const e = new Date('2022-01-01 ' + value);
    if (s < e) {
      setVTimeEnd("success")
      setbtnEnable(true)
    }
    else {
      setVTimeEnd('error')
      setbtnEnable(false)
    }
  }

  const handleOk = () => {
    if (
      !title ||
      !dateStart ||
      !dateEnd ||
      !capacity ||
      !sportList[0]?.sport ||
      !sportList[0]?.price
    ) {
      !title && setVTitle("error")
      !capacity && setVCapacity("error")
      !sportList[0].sport && setVSport("error")
      !sportList[0].price && setVPrice("error")
      !dateStart && setVstartDate("error")
      !dateEnd && setVDateEnd("error")
    }
    else {
      let data = {
        slot_id,
        _id: slot_id,
        title,
        description,
        start: dateStart,
        end: dateEnd,
        time_start: timeStart,
        time_end: timeEnd,
        date_start: dateStart,
        date_end: dateEnd,
        sportList,
        capacity,
        photo,
        Repeatedly,
        week: checkedList,
      }
      slot_id
        ? onUpdate(data)
        : onAdd(data)
      onClose(false)
    }
  }

  const onDeleteEvent = () => {
    setLoading(true)
    CalendarService
      .deleteSlot({ slot_id: eventData?.extendedProps?._id })
      .then((response) => {
        onClose()
        handleDeleteEvent(eventData?.extendedProps?._id)
        toast.success("Deleted Successfully");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error || "Unsuccessfully try again");
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const handleAddNewSport = () => {
    let newField = {
      price: "",
      sport: null,
    }
    setSportList([newField, ...sportList]);
  }

  const handleRemoveSportList = (index) => {
    let temp = [...sportList]
    temp.splice(index, 1)
    setSportList(temp);
  }

  const handleUpdateList = (value, index, fieldName) => {
    let temp = [...sportList]
    temp[index][fieldName] = value
    setSportList(temp)
    if (fieldName === "sport") setVSport("")
    if (fieldName === "price") setVPrice("")
  }

  const renderSportList = ({ sport, price }, index) => {
    return (
      <div className={styles.container}>
        <ScheduleInput
          placeholder="Select sport"
          array={sports || allSports}
          title={sport?.name}
          select={true}
          option={sportOptions}
          viewOnly={viewOnly}
          label="Sports"
          onChange={(e) => handleUpdateList(e, index, "sport")}
          value={sport?._id || sport}
          inputStatus={VSport}
        />

        <ScheduleInput
          placeholder="Enter price"
          title={price}
          viewOnly={viewOnly}
          type="number"
          label="Price"
          onChange={(e) => handleUpdateList(e, index, "price")}
          value={price}
          inputStatus={VPrice}
        />

        {index >= 1 ?
          <div className={styles.closeBtn} onClick={() => handleRemoveSportList(index)}>
            <CloseCircleOutlined style={{ color: 'red', marginTop: 34 }} />
          </div>
          : null}
      </div>
    );
  };

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onReservedEvent = (type) => {
    setLoading(true)
    CalendarService
      .reservedSlot({ slot_id: eventData?.extendedProps?._id, type })
      .then((response) => {
        onClose()
        handleReservedEvent(response)
        if (type === "add") toast.success("Successfully booked");
        else toast.success("Successfully unbooked");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error || "Unsuccessfully try again");
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };


  return (
    <div className={styles.container}>

      <Modal
        title={title || "Create Activity"}
        centered
        className={styles.modal}
        destroyOnClose
        onCancel={onClose}
        width={700}
        // onOk={viewOnly ? onClose : handleOk}
        visible={isModalVisible}
        // okText={viewOnly ? "okay" : "Save"}
        // cancelText="取消"
        footer={[
          viewOnly && <Button type="primary" onClick={() => onReservedEvent("add")} >Add manually booking</Button>,
          viewOnly && <Button type="primary" onClick={() => onReservedEvent("remove")} >Remove manually booking</Button>,
          viewOnly && allowModify && <Button onClick={onCheckBooking}>Booking History</Button>,
          viewOnly && <Button type="danger" onClick={onDeleteEvent} loading={loading} disabled={allowModify}><DeleteOutlined /></Button>,
          viewOnly && <Button onClick={handleEditEvent} loading={loading} disabled={allowModify}><EditOutlined />Edit</Button>,
          // <Button type="secondry" onClick={onClose}>Cancel</Button>,
          !viewOnly && <Button type="primary" onClick={handleOk} disabled={!btnEnable}>{slot_id ? "Update" : "Save"}</Button>,
        ]}
      >

        {viewOnly ?
          <ViewSlotDetail data={eventData?.extendedProps} eventData={eventData} />
          : <>
            <div className={styles.container}>
              <ScheduleInput
                title={title}
                viewOnly={viewOnly}
                placeholder="Mention your title"
                label="Title"
                onChange={(e) => [setTitle(e), setVTitle("success")]}
                value={title}
                inputStatus={VTitle}
                hint="Ex: This event for basketball"
              />
              <ScheduleInput
                placeholder="Enter capacity"
                title={capacity}
                viewOnly={viewOnly}
                type="number"
                label="Capacity"
                onChange={(e) => [setCapacity(e), setVCapacity(e ? "success" : "error")]}
                value={capacity}
                inputStatus={VCapacity}
              />
            </div>

            <div className={styles.container}>
              <ScheduleInput
                title={moment(dateStart).format("DD MMMM YYYY")}
                viewOnly={viewOnly}
                type="date"
                label="Start Date"
                onChange={(e) => handleStartDate(e)}
                value={dateStart}
                inputStatus={VstartDate}
                hint="Only set future date"
              />
              <ScheduleInput
                title={moment(dateEnd).format("DD MMMM YYYY")}
                viewOnly={viewOnly}
                type="date"
                label="End Date"
                onChange={(e) => handleDateEnd(e)}
                value={dateEnd}
                inputStatus={VDateEnd}
              // hint="Future date will be less than 3 days"
              />
            </div>

            {Repeatedly &&
              <div>
                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                  All Days
                </Checkbox>
                <br />
                <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                <br />
                <br />
              </div>
            }

            <div className={styles.container}>
              <ScheduleInput
                title={timeStart}
                viewOnly={viewOnly}
                type="time"
                label="Start Time"
                onChange={(e) => handleTimeStart(e)}
                value={timeStart}
              />
              <ScheduleInput
                title={timeEnd}
                viewOnly={viewOnly}
                type="time"
                label="End Time"
                onChange={(e) => handleTimeEnd(e)}
                value={timeEnd}
                inputStatus={VTimeEnd}
              />
            </div>

            <div className={styles.container}>
              <ScheduleInput
                textArea
                title={description}
                viewOnly={viewOnly}
                placeholder="Enter description"
                label="Description"
                onChange={(e) => setDescription(e)}
                value={description}
                inputStatus={""}
              // // // // hint="Ex: "
              />

              {viewOnly
                ?
                <>
                  {!photo || typeof (photo) !== "string"
                    ? null
                    : <div>
                      <Label>Image</Label><br></br>
                      <img src={photo} className={styles.slotImage} />
                    </div>
                  }
                </>
                :
                <div className={styles.imgUpload}>
                  <Label>Upload</Label><br></br>
                  <Upload
                    beforeUpload={true}
                    listType="picture-card"
                    maxCount="1"
                    // fileList={image}
                    accept="image/png, .jpeg, .jpg, .webp"
                    onChange={(e) => [console.log(e), setPhoto(e.fileList)]}
                  >
                    <UploadOutlined />
                  </Upload>
                </div>
              }
            </div>


            {!viewOnly &&
              <div className={styles.listActions}>
                {sportList?.length < sports?.length &&
                  <PlusOutlined
                    className={styles.actionIcon}
                    onClick={handleAddNewSport}
                  />
                }
                <ClearOutlined
                  className={styles.actionIcon}
                  onClick={() => setSportList(defaultSportList)}
                />
              </div>
            }
            {_map(sportList, renderSportList)}

          </>
        }

      </Modal>

    </div>
  );
}


export default ScheduleWeekView;
