import { createSlice } from "@reduxjs/toolkit";

// Constants
import { EMPTY_ARRAY } from "../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
  venues: EMPTY_ARRAY,
  loading: false,
};

const sportsSlice = createSlice({
  name: "sports",
  initialState: INITIAL_STATE,
  reducers: {
    addSports: (state, action = {}) => {
      const { payload: sports = [] } = action;
      state.items = sports;
    },
    addVeneusSports: (state, action = {}) => {
      const { payload: sports = [] } = action;
      state.venues = sports;
    },
  },
});

const { addSports, addVeneusSports } = sportsSlice.actions;
const sportsReducer = sportsSlice.reducer;

export { addSports, addVeneusSports, sportsReducer };
export default sportsSlice;
