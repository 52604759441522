import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Lodash
import _map from "lodash/map";
import _findIndex from "lodash/findIndex";

// Components
import Calendar from "../../../../molecules/calendar";
import { Label } from "../../../../atoms/typography";
// import TimeSlotModal from "../../../../organisms/timeSlotsModal";
// Constants
import { EMPTY_ARRAY } from "../../../../../constants";

// Helpers
import { findTimeSlotsForTheDay } from "./helpers/timeSlots";

// Styles
import styles from "./scheduleCalendarView.module.scss";
import { Tag } from "antd";

function ScheduleCalendarView({
  schedule,
  scheduleOverrides,
  type = "DEFAULT",
}) {
  const [timeSlotModalVisibility, setTimeSlotModalVisibility] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const renderSelectedSport = (sport) => ({ id, capacity, price }) => {
    if (id && id.name && capacity && price) {
      return (
        <p className={styles.sportPara}>{`${id.name.substring(0, 8)}-${price}-${capacity}`}</p>
      );
    }
  }

  const renderTimeSlot = (dateMoment) => ({ start, end, sport }) => {
    // const dayStartTimeStamp = dateMoment.startOf("day").valueOf();
    const startTimeMoment = moment(start, "HH:mm");
    const endTimeMoment = moment(end, "HH:mm");
    if (start && end) {
      return (
        <span className={styles.calendarSlots}>
          <Label className={styles.timeSlot}>{`${startTimeMoment.format("HH:mm")} - ${endTimeMoment.format("HH:mm")}`}</Label>
          {_map(sport, renderSelectedSport(sport))}
        </span>
      );
    }
  };

  const handleAvailableSlots = () => {
    setTimeSlotModalVisibility(true);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const renderDateCell = (date) => {
    const timeSlots = findTimeSlotsForTheDay(date, schedule, scheduleOverrides);
    if (type !== "DEFAULT") {
      const index = _findIndex(timeSlots, renderTimeSlot(date));
      if (index >= 0) {
        return (
          <Tag color="success" onClick={() => handleAvailableSlots()}>
            Available Slots
          </Tag>
        );
      }
    }

    return (
      <div className={styles.timeSlots}>
        {_map(timeSlots, renderTimeSlot(date))}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Calendar dateCellRender={renderDateCell} onSelect={handleDateSelect} />
      {/* <TimeSlotModal
        visible={timeSlotModalVisibility}
        onCancel={() => setTimeSlotModalVisibility(false)}
        selectedDate={selectedDate}
        schedule={schedule}
        scheduleOverrides={scheduleOverrides}
      ></TimeSlotModal> */}
    </div>
  );
}

ScheduleCalendarView.propTypes = {
  schedule: PropTypes.array,
  scheduleOverrides: PropTypes.array,
};

ScheduleCalendarView.defaultProps = {
  schedule: EMPTY_ARRAY,
  scheduleOverrides: EMPTY_ARRAY,
};

export default ScheduleCalendarView;
