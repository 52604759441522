import { createSlice } from "@reduxjs/toolkit";

// Lodash
import _findIndex from "lodash/findIndex";

// Readers
import { activityReader } from "../../readers";

// Constants
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../constants";

const INITIAL_STATE = {
  items: EMPTY_ARRAY,
};

const activitiesSlice = createSlice({
  name: "activities",
  initialState: INITIAL_STATE,
  reducers: {
    addActivities: (state, action = {}) => {
      const { payload: activities = EMPTY_ARRAY } = action;
      state.items = activities;
    },
    addActivity: (state, action = {}) => {
      const { payload: newActivity = EMPTY_OBJECT } = action;
      state.items = [newActivity, ...state.items];
    },
    updateActivity: (state, action = {}) => {
      const { payload: updatedActivity = EMPTY_OBJECT } = action;
      const packageIndex = _findIndex(state.items, (item) => item._id === updatedActivity._id);
      state.items[packageIndex] = updatedActivity;
    },
  },
});

const { addActivities, addActivity, updateActivity } = activitiesSlice.actions;
const activitiesReducer = activitiesSlice.reducer;

export { addActivities, addActivity, updateActivity, activitiesReducer };
export default activitiesSlice;
