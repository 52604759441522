import axios from "axios";
import queryString from "query-string";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "../constants/pagination";

// Utils
import { toast } from "react-toastify";
import getDataFromResponse from "../utils/getDataFromResponse";


const header = {
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
  },
}


const fetchActivities = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  searchText = "",
  sortField,
  order,
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    search_text: searchText,
    sort_field: sortField,
    order_by: order === "decend" ? "desc" : order === "ascend" ? "asc" : "",
    self: true,
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/events/activities`,
    query: payload,
  });
  return axios
    .get(url, header)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      console.log(`Error: Failed to fetch Activity, ${error}`);
    });
};

const fetchActivityDetails = (id) => {
  const url = `${BASE_URL}/events/activity/${id}`;
  return axios
    .get(url, header)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error?.response?.data?.error || "Unsuccessfully reload again");
    });
};

const createActivity = (payload) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/events/create_events`,
  });
  return axios
    .post(url, payload, header)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error?.response?.data?.error || "Unsuccessfully try again");
    });
};

const updateActivity = (id, payload) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/events/create_events/${id}`,
  });
  return axios
    .post(url, payload, header)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error?.response?.data?.error || "Unsuccessfully try again");
    });
};

const deleteActivity = (id) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/events/delete_event/${id}`,
  });
  return axios
    .delete(url, header)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error?.response?.data?.error || "Unsuccessfully try again");
    });
};

export default {
  fetchActivities,
  fetchActivityDetails,
  createActivity,
  updateActivity,
  deleteActivity,
};
