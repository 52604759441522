import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

// Lodash
import _noop from "lodash/noop";

// Components
import ScheduleHeader from "./components/scheduleHeader";
import ScheduleListView from "./components/scheduleListView";
import ScheduleCalendarView from "./components/scheduleCalendarView";

// Readers
import { listResponseReader } from "../../../readers";

// Services
import { sportsService } from "../../../services";

// Redux
import { addSports, addVeneusSports } from "../../../redux/slices/sportsSlice";

// Constants
import { EMPTY_ARRAY } from "../../../constants";
import { LIST_VIEW, CALENDAR_VIEW } from "./constants/viewOptions";
import { DEFAULT_WEEKLY_SCHEDULE } from "./constants/weeklySchedule";

// Styles
import styles from "./schedule.module.scss";

function Schedule({
  onChange,
  schedule,
  scheduleOverrides,
  name,
  startTime,
  endTime,
  viewOnly,
  type = "DEFAULT",
  startDate,
  endDate,
}) {
  const dispatch = useDispatch();

  const [scheduleView, setScheduleView] = useState(LIST_VIEW.value);
  const [loading, setLoading] = useState(true);
  // const sports = useSelector((state) => state?.sports?.items);

  const handleViewChange = (view) => {
    setScheduleView(view);
  };

  const handleScheduleChange = (updatedSchedule) => {
    onChange({
      name,
      startTime,
      endTime,
      ...updatedSchedule,
    });
  };

  const handleScheduleNameChange = (updatedName) => {
    onChange({
      name: updatedName,
      schedule,
      scheduleOverrides,
      startTime,
      endTime,
    });
  };

  const handleStartTimeChange = (updateStartTime) => {
    onChange({
      name,
      schedule,
      scheduleOverrides,
      startTime: updateStartTime,
      endTime,
    });
  };

  const handleEndTimeChange = (updateEndTime) => {
    onChange({
      name,
      schedule,
      scheduleOverrides,
      startTime,
      endTime: updateEndTime,
    });
  };

  const getMinstes = (startTime, endTime, slots) => {
    const duration = moment.duration(endTime.diff(startTime));
    const hours = parseInt(duration.asHours());
    let minutes = parseInt(duration.asMinutes()) % 60;

    minutes = minutes + hours * 60
    // console.log(minutes, "minutes")
    minutes = minutes / slots
    // console.log(minutes, "minutes")
    return minutes
  }

  const handleOnAction = (startTimes, endTimes, slots, startTimes1, endTimes1, startDate, endDate, DefaultSports = []) => {

    let startTime = moment(startTimes, "HH:mm");
    let endTime = moment(endTimes, "HH:mm");
    let startTime1 = moment(startTimes1, "HH:mm");
    let endTime1 = moment(endTimes1, "HH:mm");

    let shift1 = getMinstes(startTime, endTime, slots)
    let shift2 = getMinstes(startTime1, endTime1, slots)

    let checkEndTime = moment(endTime).add(0, 'minutes').format('HH:mm')
    let checkEndTime1 = moment(endTime1).add(0, 'minutes').format('HH:mm')

    let newSchedule = []
    for (const day of schedule) {
      let temp = { ...day }
      temp.timeSlots = []

      for (let i = 0; i < shift1; i++) {

        let travelTimeStart = moment(startTime).add(slots * i, 'minutes').format('HH:mm');
        let travelTimeEnd = moment(startTime).add(slots * (i + 1), 'minutes').format('HH:mm');

        const s = new Date('2022-01-01 ' + travelTimeEnd);
        const e = new Date('2022-01-01 ' + checkEndTime);

        if (travelTimeStart != checkEndTime && s <= e) {
          temp.timeSlots.push(
            {
              start: travelTimeStart,
              end: travelTimeEnd,
              sport: DefaultSports
            }
          )
        }
      }

      if (endTimes1) for (let i = 0; i < shift2; i++) {

        let travelTimeStart = moment(startTime1).add(slots * i, 'minutes').format('HH:mm');
        let travelTimeEnd = moment(startTime1).add(slots * (i + 1), 'minutes').format('HH:mm');

        const s = new Date('2022-01-01 ' + travelTimeEnd);
        const e = new Date('2022-01-01 ' + checkEndTime1);

        if (travelTimeStart != checkEndTime1 && s <= e) {
          temp.timeSlots.push(
            {
              start: travelTimeStart,
              end: travelTimeEnd,
              sport: DefaultSports
            }
          )
        }
      }

      newSchedule.push(temp)
    }
    handleScheduleChange({
      schedule: newSchedule,
      startTime: startTimes,
      endTime: endTimes,
      startDate,
      endDate,
    })
  };

  useEffect(() => {
    sportsService
      .fetchVenesSports()
      .then((response) => {
        dispatch(addVeneusSports(response));
      })
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.scheduleHeader}>
        <ScheduleHeader
          onViewChange={handleViewChange}
          view={scheduleView}
          schedule={schedule}
          name={name}
          startTime={startTime}
          endTime={endTime}
          onNameChange={handleScheduleNameChange}
          onStartTimeChange={handleStartTimeChange}
          onEndTimeChange={handleEndTimeChange}
          onAction={handleOnAction}
          viewOnly={viewOnly}
        />
      </div>
      <div className={styles.scheduleDetails}>
        {scheduleView === LIST_VIEW.value && (
          <ScheduleListView
            onChange={handleScheduleChange}
            schedule={schedule}
            scheduleOverrides={scheduleOverrides}
            viewOnly={viewOnly}
          />
        )}
        {scheduleView === CALENDAR_VIEW.value && (
          <ScheduleCalendarView
            onChange={handleScheduleChange}
            schedule={schedule}
            startDate={startDate}
            endDate={endDate}
            scheduleOverrides={scheduleOverrides}
            type={type}
          />
        )}
      </div>
    </div>
  );
}

Schedule.propTypes = {
  onChange: PropTypes.func,
  schedule: PropTypes.array,
  scheduleOverrides: PropTypes.array,
  name: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  viewOnly: PropTypes.bool,
};

Schedule.defaultProps = {
  onChange: _noop,
  name: "",
  startTime: "",
  endTime: "",
  schedule: DEFAULT_WEEKLY_SCHEDULE,
  scheduleOverrides: EMPTY_ARRAY,
  viewOnly: false,
};

export default Schedule;
