import moment from "moment";

// Lodash
import _map from "lodash/map";

// Constants
import { DEFAULT_FORM_VALUES, FORM_FIELDS } from "../constants";

// Readers
import { packageReader, sportReader } from "../../../../../readers";

const getSportIds = (packageDetails) => {
  const sports = packageReader.sports(packageDetails);
  return _map(sports, (sport) => `${sportReader.id(sport)}@${sportReader.name(sport)}`);
};

const createInitialFormValues = (editMode = false, packageDetails) => {
  if (!editMode) {
    return DEFAULT_FORM_VALUES;
  }
  return {
    [FORM_FIELDS.ADDRESS]: packageReader.address(packageDetails),
    [FORM_FIELDS.DESCRIPTION]: packageReader.description(packageDetails),
    [FORM_FIELDS.HOT_DEAL]: packageReader.hotDeal(packageDetails),
    [FORM_FIELDS.LATITUDE]: packageReader.latitude(packageDetails),
    [FORM_FIELDS.LONGITUDE]: packageReader.longitude(packageDetails),
    [FORM_FIELDS.NAME]: packageReader.packageName(packageDetails),
    [FORM_FIELDS.PACKAGE_TYPE]: packageReader.packageType(packageDetails),
    [FORM_FIELDS.PHOTOS]: packageReader.photos(packageDetails),
    [FORM_FIELDS.PRICE]: packageReader.price(packageDetails),
    [FORM_FIELDS.OLD_PRICE]: packageReader.old_price(packageDetails),
    [FORM_FIELDS.QUANTITY]: packageReader.quantity(packageDetails),
    [FORM_FIELDS.SPORTS]: getSportIds(packageDetails),
    [FORM_FIELDS.STATUS]: packageReader.isActive(packageDetails),
    [FORM_FIELDS.EXPIRE_DATE]: moment(packageReader.expire_date(packageDetails)).format("YYYY-MM-DD"),
  };
};

export { createInitialFormValues };
