import React, { Component, useState, useCallback, useEffect } from "react";
// import ReactCrop from "react-image-crop";
// import 'react-image-crop/dist/ReactCrop.css';

// Components
import { Button, Modal } from 'antd';
import Cropper from 'react-easy-crop'
import cropImage from '../../helper'


export default function ImageResizeCropComponent({
  visible = false,
  onClose,
  submit,
  image = {},
}) {

  useEffect(() => {
    setcroppedImageUrl(null)
    setConfirm(true)
  }, [image])


  const [crop1, setCrop1] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const [crop, setCrop] = useState()
  const [croppedImageUrl, setcroppedImageUrl] = useState(null)
  const [confirm, setConfirm] = useState(false)

  const getUrl = (image) => {
    if (image) return URL.createObjectURL(image)
    else return ""
  }

  const onCropComplete = useCallback((croppedAreaPixels) => {
    // console.log(croppedAreaPixels)
    setCrop(croppedAreaPixels)
  }, [])

  const onUploadCrop = async () => {
    if (crop?.height && crop?.width) {
      image = await cropImage(
        getUrl(image),
        image,
        crop
      );
      setConfirm(!confirm)
      setcroppedImageUrl(image)
    }
  }

  return (
    <Modal
      title="Crop Image"
      visible={visible}
      // onOk={() => onUploadCrop()}
      onCancel={onClose}
      width={"70%"}
      bodyStyle={{ height: "500px" }}
      footer={[
        <Button onClick={onClose}>Close</Button>,
        !croppedImageUrl && <Button
          type="primary"
          onClick={onUploadCrop}
          disabled={!confirm}
        >
          Crop
        </Button>,
        croppedImageUrl && <Button
          type="primary"
          onClick={() => submit(croppedImageUrl)}
        >
          Confirm
        </Button>,
        <Button
          type="primary"
          disabled={confirm}
          onClick={() => [setcroppedImageUrl(null), setConfirm(!confirm)]}
        >
          Revert
        </Button>,
      ]}
    >

      {!croppedImageUrl &&
        <Cropper
          image={getUrl(image)}
          crop={crop1}
          zoom={zoom}
          aspect={4 / 4}
          onCropChange={setCrop1}
          onCropComplete={(e, a) => onCropComplete(a)}
          onZoomChange={setZoom}
        />
      }

      {croppedImageUrl &&
        <center>
          <img alt="Crop" style={{ maxWidth: '500px' }} src={getUrl(croppedImageUrl)} />
        </center>
      }

    </Modal>
  );
}
