// Lodash
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";

// Constants
import { EMPTY_ARRAY } from "../../../constants";

const isValidPhotoUrl = (photoUrl) =>
  !_isEmpty(photoUrl) && photoUrl !== "undefined";

const filterValidPhotos = (photos = EMPTY_ARRAY) =>
  _filter(photos, isValidPhotoUrl);

export { filterValidPhotos };
