import React, { useState } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

// Lodash
import _isEmpty from "lodash/isEmpty";

// Constants
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../../../constants/pagination";
import { EMPTY_ARRAY } from "../../../constants";

// Readers
import listResponseReader from "../../../readers/listResponse";

const { Option } = Select;

function AsyncSelect({
  asyncService,
  defaultOptions = [],
  type = "",
  createOptions,
  ...restProps
}) {
  const [selectData, setSelectData] = useState(EMPTY_ARRAY);
  const [searchValue, setSearchValue] = useState();

  const handleSearch = (searchValue) => {
    if (searchValue) {
      asyncService({
        pageNumber: DEFAULT_PAGE_NUMBER,
        pageSize: DEFAULT_PAGE_SIZE,
        searchText: searchValue,
      })
        .then((res) => {
          const users = listResponseReader.results(res);
          setSelectData(users);
        })
        .catch((error) => {
          console.log(error);
          setSelectData(EMPTY_ARRAY);
        });
    } else {
      setSelectData(EMPTY_ARRAY);
    }
  };

  const handleChange = (value) => {
    setSearchValue(value);
  };
  
  const options = _isEmpty(searchValue)
    ? defaultOptions.map((d) => createOptions(d))
    : selectData.map((d) => createOptions(d));

  return (
    <Select
      showSearch
      value={searchValue}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={"Record not found"}
      {...restProps}
    >
      {options}
    </Select>
  );
}

AsyncSelect.propTypes = {
  asyncService: PropTypes.func,
};

AsyncSelect.defaultProps = {};

export default AsyncSelect;
