// Lodash
import _property from "lodash/property";

const results = _property("docs");
const hasNextPage = _property("hasNextPage");
const hasPrevPage = _property("hasPrevPage");
const pageSize = _property("limit");
const nextPageNumber = _property("nextPage");
const pageNumber = _property("page");
const previousPageNumber = _property("prevPage");
const totalResults = _property("totalDocs");
const totalPages = _property("totalPages");
const pagingCounter = _property("pagingCounter");
const revenue = _property("revenue");

export default {
  results,
  hasNextPage,
  hasPrevPage,
  pageSize,
  nextPageNumber,
  pageNumber,
  previousPageNumber,
  totalResults,
  totalPages,
  pagingCounter,
  revenue
};
