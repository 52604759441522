import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
// Components
import CreateActivtyForm from "../createActivityForm";
import { toast } from "../../../../components/atoms/toaster";
import { getID } from "../../../../utils/globle";

// Styles
import { EMPTY_OBJECT } from "../../../../constants";
import activityService from "../../../../services/activitiesService";
import userService from "../../../../services/userService";

// Redux
import { addActivity, updateActivity } from "../../../../redux";

//lodash
import _isEmpty from "lodash/isEmpty";

// Redux
import { addFriendList } from "../../../../redux";

function ActivityFormWrapper({
  editMode,
  visible,
  onClose,
  details,
  sportsList,
  venuesOptions,
  onReload,
  // reloadPage,
}) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const friendList = useSelector((state) => state?.user?.friendList);

  const activity_id = details?._id

  // fetchFriendList
  useEffect(() => {
    if (friendList?.length === 0 || !friendList?.length)
      userService
        .fetchFriendList()
        .then((response) => {
          dispatch(addFriendList(response));
        })
  }, []);



  let title = "Create New Activity";
  const defaultValues = {
    name: null,
    description: null,
    sports_id: null,
    event_time_slot: [],
    paymentStatus: false,
    status: "public",
    no_of_players: 10,
    no_of_team: 1,
    fees: null,
    gender: "mixed",
    invitations: [],

    latitude: 0,
    longitude: 0,
    complete_address: null,
    custom_event_date: moment().format("YYYY-MM-DD"),
    custom_start_time: null,
    custom_end_time: null,
    repeated: false,
  }

  // defaultValues.venue_id = venuesOptions[0]?._id;

  if (editMode) {
    // console.log(details, 'details.sports_id')
    title = `Edit Activity`;
    defaultValues.name = details.name;
    defaultValues.description = details.description;
    defaultValues.sports_id = `${details.sports_id._id}@${details.sports_id.name}`;
    defaultValues.paymentStatus = details.fees > 0 ? true : false;
    defaultValues.no_of_players = details.no_of_players;
    defaultValues.no_of_team = details.no_of_team;
    defaultValues.fees = details.fees;
    defaultValues.gender = details.gender;
    defaultValues.invitations = details.invitations;
    defaultValues.status = details.status;

    defaultValues.latitude = details.address?.latitude;
    defaultValues.longitude = details.address?.longitude;
    defaultValues.complete_address = details.address?.complete_address;
    defaultValues.custom_event_date = moment(details.address?.custom_event_date).format("YYYY-MM-DD");
    defaultValues.custom_start_time = details.address?.custom_start_time;
    defaultValues.custom_end_time = details.address?.custom_end_time;
  }

  const onFinish = (values, form) => {
    setLoading(true);
    values.address = {}
    values.sports_id = getID(values.sports_id, false)
    values.invitations = getID(values.invitations, true)
    const finalValues = { ...values };

    if (values.location.address) {
      finalValues.address.complete_address = values.location.address
      finalValues.address.latitude = values.location.coordinates.lat
      finalValues.address.longitude = values.location.coordinates.lng
    }

    finalValues.address.custom_event_date = finalValues?.custom_event_date || finalValues?.custom_start_date
    finalValues.address.custom_start_time = finalValues.custom_start_time
    finalValues.address.custom_end_time = finalValues.custom_end_time

    delete finalValues.location

    if (values.invitations?.length > 0) {
      let temp = []
      values.invitations.forEach(user => temp.push({ recevier_id: user }))
      finalValues.invitations = temp
    }

    if (editMode) {
      delete finalValues.invitations
      if (!values.paymentStatus) finalValues.fees = 0

      activityService
        .updateActivity(activity_id, finalValues)
        .then((response) => {
          onClose();
          toast.success("Update Successful!!");
          dispatch(updateActivity(response));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      activityService
        .createActivity(finalValues)
        .then((response) => {
          if (values?.repeated) onReload()
          else dispatch(addActivity(response))
          toast.success("New Activity Added Successfully!!");
          onClose();
          form.resetFields();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <CreateActivtyForm
      title={title}
      editMode={editMode}
      formValues={defaultValues}
      visible={visible}
      onClose={onClose}
      // details={details}
      sportsList={sportsList}
      friendList={friendList}
      // venuesOptions={venuesOptions}
      onFinish={onFinish}
      loading={loading}
    // handleSelectedVenue={handleSelectedVenue}
    // photos={photos}
    />
  );
}

ActivityFormWrapper.propTypes = {
  editMode: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  details: PropTypes.object,
  sportsList: PropTypes.array,
  venuesOptions: PropTypes.array,
};

ActivityFormWrapper.defaultProps = {
  details: EMPTY_OBJECT,
  editMode: false,
};

export default ActivityFormWrapper;
