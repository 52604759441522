import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Services
import postService from "../../../../services/postsService";
import { getID } from "../../../../utils/globle";
import { fetchActivityList, fetchPackageList } from "../../../promotion/helpers/fetchDatalist";
//lodash
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _noop from "lodash/noop";

// Components
import Radio from "../../../../components/atoms/radio";
import { toast } from "../../../../components/atoms/toaster";
import { useForm } from "antd/lib/form/Form";
import Form from "../../../../components/atoms/form";
import Drawer from "../../../../components/atoms/drawer";
import Button from "../../../../components/atoms/button";
import { Input, TextArea } from "../../../../components/atoms/input";
import SelectFetch from "../../../../components/atoms/selectWithFetch";
import UploadImageModal from "../uploadImageModal";
import { Select, Checkbox } from "antd";

// Styles
import styles from "./createPostForm.module.scss";

const renderSportsOptions = (option) => {
  return (
    <Select.Option key={`${option._id}@${option.name}`} value={`${option._id}@${option.name}`}>
      {option.name}
    </Select.Option>
  );
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const defaultValues = {
  description: null,
  sports_id: null,
  file: null,
  activity: null,
  package: null,
};

function CreatePostForm({
  visible,
  onClose,
  sportsList,
  postDetails,
  editMode,
  addNew,
  updatePost,
}) {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [srcImg, setSrcImg] = useState(null);
  const [oldImage, setOldImage] = useState(null);
  const [linkTo, setLinkTo] = useState(null);
  const [unLinkTo, setunLinkTo] = useState(false);

  let title = "Create New Post";
  useEffect(() => {
    if (editMode) {
      title = `Edit Post`;
      defaultValues.description = postDetails?.description;
      defaultValues.activity = postDetails?.activity?.name;
      defaultValues.package = postDetails?.package?.package_name;
      defaultValues.sports_id = `${postDetails.sports_id._id}@${postDetails.sports_id.name}`;
      if (postDetails?.activity || postDetails?.package) setunLinkTo(true)
      else setunLinkTo(false)
      if (postDetails?.activity) setLinkTo("Activity")
      if (postDetails?.package) setLinkTo("Package")
      setOldImage(postDetails.file)
      setSrcImg(null)
    }
    else {
      setSrcImg(null)
      setOldImage(null)
      setLinkTo(null)
      setunLinkTo(false)
      defaultValues.description = null;
      defaultValues.sports_id = null;
      defaultValues.activity = null;
      defaultValues.package = null;
      defaultValues.file = null;
    }
    form.setFieldsValue(defaultValues);
  }, [form, postDetails]);

  const setImage = async (image) => {
    setOpenModal(false)
    setSrcImg(image)
    form.setFieldsValue({
      file: image
    })
  };

  const handleImage = async (event) => {
    // console.log(event.target.files[0], 'xxxxsrc')
    // setSrcImg(URL.createObjectURL(event.target.files[0]));
    setSrcImg(event.target.files[0]);
    setOpenModal(true)
  };

  const getUrl = (image) => {
    if (image) return URL.createObjectURL(image)
    else return ""
  }

  const onFinish = (values, form) => {
    values.sports_id = getID(values.sports_id, false)

    if (linkTo === "Activity") {
      values.package = null
    }
    if (linkTo === "Package") {
      values.activity = null
    }
    if (!unLinkTo) {
      values.activity = null
      values.package = null
    }

    const finalValues = { ...values };

    const formData = new FormData();
    for (let key in finalValues) {
      if (key === "file" && finalValues[key]) {
        formData.append("file", finalValues[key], finalValues[key]?.name);
      } else {
        if (finalValues[key]) formData.append(key, finalValues[key]);
      }
    }

    setLoading(true);
    if (editMode) {
      postService
        .updatePost(postDetails?._id, values)
        .then((response) => {
          if (response.success) {
            toast.success("Update Successful!!");
            updatePost(response.data, 'response')
            onClose();
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    else {
      postService
        .createPost(formData)
        .then((response) => {
          if (response.file) addNew(response)
          toast.success("New Post Added Successfully!!");
        })
        .catch((error) => {
          toast.error("Unsuccessfully!!");
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
          setOpenModal(false)
          onClose()
        });
    }
  };

  return (
    <Drawer title={title} visible={visible} onClose={onClose} width={400}>
      <div className={styles.container}>
        <Form
          form={form}
          initialValues={defaultValues}
          scrollToFirstError
          onFinish={(values) => onFinish(values, form)}
          layout="vertical"
        >
          <Form.Item label="Description" name={"description"}>
            <TextArea placeholder="Enter description" />
          </Form.Item>
          <Form.Item
            name="sports_id"
            label="Sports Played"
            rules={[
              { required: true, message: "Please select sports played!" },
            ]}
          >
            <Select
              showSearch
              // mode="multiple"
              placeholder="Select sports played"
              className={styles.select}
            >
              {_map(sportsList, renderSportsOptions)}
            </Select>
          </Form.Item>
          {!editMode &&
            <Form.Item
              name="file"
              label="Upload Photos"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            // rules={[{ required: true, message: "Please select image!" }]}
            >
              <Input
                id="fileImage"
                type="file"
                accept="image/png, .jpeg, .jpg, .webp"
                onChange={handleImage}
                value={""}
              />
            </Form.Item>}

          {srcImg ? <img
            style={{ marginBottom: 20 }}
            src={getUrl(srcImg)}
            width="200px"
            height="200px"
          /> : null}

          {oldImage ? <img
            style={{ marginBottom: 20 }}
            src={oldImage}
            width="200px"
            height="200px"
          /> : null}

          <Form.Item>
            <Checkbox checked={unLinkTo} onChange={(e) => setunLinkTo(e.target.checked)}>Link to package & activity</Checkbox>
          </Form.Item>

          {unLinkTo ? <>
            <Form.Item>
              <Radio
                label="Link To"
                defaultValue={linkTo}
                value={linkTo}
                onChange={(e) => setLinkTo(e.target.value)}
                items={[
                  {
                    name: "Activity",
                    value: "Activity"
                  },
                  {
                    name: "Package",
                    value: "Package",
                  },
                ]}
              />
            </Form.Item>

            {linkTo === "Package" ?
              <Form.Item
                name="package"
                label="Packages"
              >
                <SelectFetch
                  showSearch
                  placeholder="Select package"
                  className={styles.select}
                  fetchOptions={(e) => fetchPackageList(e)}
                />
              </Form.Item>
              : null}

            {linkTo === "Activity" ?
              <Form.Item
                name="activity"
                label="Activities"
              >
                <SelectFetch
                  showSearch
                  placeholder="Select activity"
                  className={styles.select}
                  fetchOptions={(e) => fetchActivityList(e)}
                />
              </Form.Item>
              : null}

          </> : null}

          <Form.Item>
            <Button
              label={"Save"}
              type="primary"
              htmlType="submit"
              loading={loading}
              className={styles.saveButton}
            />
          </Form.Item>

        </Form>


        <UploadImageModal
          image={srcImg}
          visible={openModal}
          onClose={() => setOpenModal(false)}
          submit={(image) => setImage(image)}
        />

      </div>
    </Drawer>
  );
}

CreatePostForm.propTypes = {
  editMode: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  postDetails: PropTypes.object,
  sportsList: PropTypes.array,
};

CreatePostForm.defaultProps = {
  visible: false,
  onClose: _noop,
  postDetails: {},
  editMode: false,
};

export default CreatePostForm;
